.bank-single {
    .bank-single__header {
        background-color: #fff;
        padding-top: 45px;

        h1 {
            color: #0a0057;
            line-height: 1.2;
            margin-bottom: 41px;
        }

        .bank-single__header-inner {
            p {
                line-height: 1.2;
            }
            .title {
                line-height: 1.2;
                color: #000000;
                font-size: 24px;
                font-weight: 300;
            }
        }
    }

    .wrp-bank-single__body {
        background-color: #fff;
    }

    .bank-single__body {
        background-color: #fff;
        position: relative;
        padding: 0;
        margin-bottom: 0;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
        height: auto;

        .bank-single__presentation {
            .bank-single__presentation-img {
                position: relative;
                &:hover {
                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        pointer-events: none;
                    }

                    &::before {
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        background-color: #000000;
                        opacity: 0.12;
                    }

                    &::after {
                        background-color: #fff;
                        width: 33px;
                        height: 33px;
                        bottom: 0;
                        right: 0;
                        background-image: url('../img/sprite.svg#zoom');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 50%;
                    }
                }
            }

            img {
                width: 100%;
            }
        }

        .bank-single__body-content {
            padding: 35px 45px;

            p {
                color: $greyB;
                font-size: 16px;
                font-weight: 300;

                .title {
                    margin-bottom: 25px;
                }
            }

            ul {
                color: $greyB;
            }

            p,
            li,
            td {
                color: $greyB;

                &.date {
                    color: $greyB2;
                }

                span {
                    line-height: 1.6;
                }
            }
        }

        .main-content,
        .sidebar {
            padding: 15px 0;
            width: 100%;
        }

        /*.sidebar {
            padding-left: 25px;
        }*/

        .main-content {
            padding-left: 30px;
        }

        .sidebar {
            padding-right: 30px;
        }

        .bank-single__body-inner {
            &::before {
                content: '';
                display: inline-block;
                width: 100%;
                position: absolute;
                bottom: 100%;
                left: 0;
                height: 8px;
                @include linear-gradient();
            }
        }
    }

    .bank-single__rating {
        .bank-widget {
            background-color: #fff;
            padding: 0;

            > * {
                text-align: right;
            }

            .bank-widget__rating {
                color: #00419f;
                font-size: 73px;
                font-weight: 500;

                &::before {
                    right: 150px;
                    top: 4%;
                }
            }
        }
    }

    .bank-single__share {
        display: flex;
        flex-wrap: nowrap;

        p {
            line-height: 1.2;
            margin-right: 10px;
            margin-bottom: 0;
        }

        .soc-icon {
            margin: 0 3px;
        }
    }

    .bank-single__tags {
        .bank-single__tags-list {
            display: flex;
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        .bank-single__elem {
            //margin: 0 25px;
            margin-right: 50px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            max-width: 125px;
            align-items: center;
            align-content: center;
            margin-bottom: 15px;

            &:last-child {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }

        .bank-single__tag {
            color: #000000;
            font-size: 16px;
            font-weight: 300;
            text-align: center;
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: inline-block;
            }

            &.tag-icon {
                width: 105px;
                height: 105px;
                border-radius: 5px;
                border: 1px solid #d8d8d8;
                background-color: #f8f8f8;
                position: relative;
                margin-top: 0;
            }
        }
    }

    .block-widget {
        .block-widget__title {
            padding-bottom: 32px;
        }
    }
}
