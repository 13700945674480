.wrp-landing-form {
}

.landing-form {
    min-height: 330px;

    .landing-form__content {
        text-align: center;
        margin-bottom: 2.75rem;

        p {
            color: #dedede;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;

            &.title {
                font-size: 42px;
                font-weight: 300;
                margin-bottom: 5px;
            }
        }
    }

    .landing-form__inner {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 45px 0 0;

        form {
            .form-group {
                .form-group__inner {
                    margin: 0 7px;
                    flex-basis: initial;
                }
            }
        }
    }

    .btn,
    .mg-btn {
        padding: 0.375rem 2rem;
        text-transform: none;
    }

    .landing-form__form {
        &.form {
            .form-group {
                .form-group__inner {
                    margin-left: 7px;
                    margin-right: 7px;

                    &.form-group__checkbox {
                        [type='checkbox'] {
                            + label {
                                &::before {
                                    border: 1px solid #bfbfbf;
                                    background-color: #ffffff;
                                }
                            }

                            &:checked {
                                + label {
                                    &::before {
                                        background-color: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
