.partner-list {
    img {
        margin: 7px 15px;
    }

    .partner-list__content {
    }
}

.block-widget {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.22);
    background-color: #ffffff;
    margin-bottom: 25px;

    /*&:last-child {
        margin-bottom: 0;
    }*/

    .block-widget__title {
        color: #000000;
        font-size: 24px;
        font-weight: 300;
        text-align: center;
        padding: 20px 0;
        margin-top: 0;

        p {
            margin-bottom: 0;

            &.title {
                flex-basis: 100%;
                text-align: center;
            }
        }
    }

    .block-widget__body {
        padding: 0 8%;

        button,
        input {
            &[type='submit'] {
                width: 100%;
                padding: 12px;
            }
        }
    }

    &.block-widget-subscribe {
        position: relative;

        .block-widget__title {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            background-color: #00204e;
            padding: 68px 10px 10px;
            position: relative;
            margin-bottom: 36px;

            &::before {
                content: '';
                background-image: url('../img/sprite.svg#man');
                background-position: center;
                height: 45px;
                width: 45px;
                display: block;
                position: absolute;
                top: 9px;
                left: 50%;
                transform: translateX(-50%);
                background-repeat: no-repeat;
            }

            &::after {
                content: '';
                /*background: linear-gradient(to right top, transparent 50%, #00204e 50%) left / 50% 100% no-repeat,
                    linear-gradient(to left top, transparent 50%, #00204e 50%) right / 50% 100% no-repeat;*/
                clip-path: polygon(100% 0, 50% 100%, 0 0);
                filter: drop-shadow(0 0 5px #000);
                background-color: #00204e;
                display: block;
                position: absolute;
                bottom: -30px;
                height: 30px;
                width: 100%;
            }

            p {
                color: #fff;
                text-align: center;
                line-height: 1.15;

                &.title {
                    color: #a1c8ff;
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 3px;
                    line-height: 1.4;
                }
            }
        }

        .select2-container--default {
            min-height: 40px;
        }

        form {
            &.form-type2 {
                .form-group {
                    margin-bottom: 18px;

                    .form-group__inner {
                        .form-control {
                            min-height: 38px;
                        }
                    }
                }
            }
        }
    }

    &.block-widget-video {
        padding: 25px 30px 30px;

        .block-widget__title {
            padding: 0;
            margin-bottom: 15px;
            position: relative;

            .mg-icon {
                position: absolute;
                left: -4px;
                top: 50%;
                transform: translateY(-50%);

                &.mg-icon__youtube {
                    &::before {
                        width: 48px;
                        height: 36px;
                    }
                }
            }

            p {
                &.title {
                    padding-left: 55px;
                    text-align: left;
                    font-size: 24px;
                    font-weight: 300;
                }
            }
        }

        .block-widget__body {
            padding: 0;
        }

        .block-widget-video__youtube-preview {
            padding-bottom: 60%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 15px;
        }

        .block-widget-video__youtube-iframe {
            iframe {
                max-width: 100%;
                height: auto;
                min-height: 195px;
            }
        }

        .block-widget__body-item {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .block-widget__body-link {
            margin-top: 15px;
            display: block;
        }
    }

    &.block-widget-single-form {
        background-image: url('../img/form-slider.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        padding: 30px 0 75px;

        &::before {
            content: '';
            width: 100%;
            display: block;
            height: 100%;
            background-color: #0a0057;
            opacity: 0.7;
            position: absolute;
            top: 0;
            z-index: 0;
            pointer-events: none;
        }

        .block-widget__title {
            padding-top: 7px;
            z-index: 1;
            position: relative;
            margin-bottom: 15px;

            p {
                color: #fff;
            }
        }

        .block-widget__body {
            position: relative;
            z-index: 1;
        }
    }

    &.block-widget-subscribe_min {
        padding: 20px 30px;
        transform: translateY(-50%);

        .block-widget__youtube-count {
            margin-right: 90px;
            p {
                display: flex;
                align-items: center;
            }
            .mg-icon {
                margin-right: 15px;
            }
            .mg-icon__views {
                height: 40px;
                width: 45px;
                &::before {
                    background-size: cover;
                }
            }
            .mg-icon__subscribes {
                height: 25px;
                width: 25px;
                &::before {
                    background-size: cover;
                }
            }
        }

        .block-widget__youtube {
            p {
                color: #4087ec;
                font-size: 30px;
                font-weight: 300;
                margin-bottom: 0;
            }
        }
    }

    &.block-widget-list {
        .block-widget__title {
        }

        .block-widget__body {
            padding-bottom: 45px;

            ul {
                margin: 0;
                padding-left: 0;
                list-style: none;

                &.block-widget__body-list {
                    li {
                        padding: 10px 0 10px 25px;
                        border-bottom: 1px solid #eaeaea;
                        margin: 3px 0;
                    }

                    a, .block-widget__link {
                        position: relative;
                        color: #0a0057;
                        font-size: 16px;
                        font-weight: 300;
                        padding: 5px 0;
                        line-height: 1;

                        &::before {
                            content: '';
                            background-image: url('../img/sprite.svg#arrow-list');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 70%;
                            border: 1px solid $blueL;
                            border-radius: 50%;
                            left: -27px;
                            top: 5px;
                            height: 18px;
                            width: 18px;
                            display: block;
                            position: absolute;
                        }
                    }
                }

                &.block-widget__body-list_icons {
                    li {
                        padding: 17px 0 17px 56px;
                        border-top: 1px solid #eaeaea;
                        border-bottom: none;
                        margin: 3px 0;
                    }

                    a {
                        position: relative;
                        color: #0a0057;
                        font-size: 16px;
                        font-weight: 300;
                        padding: 5px 0;
                        line-height: 1;

                        &::before {
                        }
                    }
                }
            }
        }
    }

    &.block-widget-image {
        padding: 0;
        position: relative;
        max-width: 403px;
        margin: 0 auto;

        .block-widget-image__inner {
        }

        .block-widget__body {
            padding: 0 0 100% 0;
            position: relative;
            background-size: cover;
            width: 100%;

            /*&::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                clip-path: polygon(0 100%, 0 25%, 100% 100%);
                background-color: $blueD;
                height: 100%;
                width: 100%;
                padding: 0;
                z-index: 1;
            }*/

            p {
                color: #4087ec;
                font-size: 28px;
                font-weight: 300;
                position: absolute;
                left: 7%;
                bottom: 8%;
                line-height: 1.2;
                text-transform: uppercase;
                height: 20%;
                width: 100%;
                z-index: 3;
                pointer-events: none;

                &.title {
                    margin: 0;
                }

                strong {
                    display: block;
                    font-weight: 700;
                }
            }

            a {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: 2;
            }
        }

        .swiper-pagination {
            position: absolute;
            bottom: 0;
            margin: 0 auto;
            left: 0;
            right: 0;
            z-index: 2;

            .swiper-pagination-bullet {
                margin: 0 2px;
            }
        }
    }

    &.block-widget-frame {
        //height: calc(100% - 23px);
        height: auto;

        .block-widget__body {
            height: 0;
            padding-bottom: 100%;
        }
    }

    &.block-widget-filter {
        background-color: transparent;
        box-shadow: none;

        .block-widget__body {
        }
    }

    .partner-list {
        margin-top: 8px;
        padding: 0 7% 7px;
    }
}

.block-widget {
    &.h100 {
        height: 100%;
        .block-widget__body {
            height: 100%;
        }
    }
}

.bank-single__body {
    &.h100 {
        height: calc(100% - 25px);
    }
}
