.wrp-swift-check {
    position: relative;
    z-index: 2;
}

.wrapper_swiftChecker {
    position: relative;
    display: none;
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);

    &.wrapper_swiftChecker-mobile {
        .dataTables_length {
            label {
                color: transparent;
            }
        }
    }

    &.wrapper_swiftChecker-active {
        animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
        display: block !important;
    }

    &.wrapper_swiftChecker-hide {
        animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
        //display: none !important;
    }

    .dataTables_wrapper {
        position: absolute;
        width: 100%;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
        padding-bottom: 10px;

        .dataTables_close {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 38px;
            color: #fff;
            line-height: 32px;
            cursor: pointer;
        }
    }
}

.active_swift-checker + .page-block .row-page-block {
    &::before {
        content: '';
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }
}

.swift-check {
    min-height: 450px;
    background-repeat: no-repeat;
    background-size: cover;

    .swift-check__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 100%;

        .news-list {
            position: relative;
            margin-bottom: 8.75rem;
        }
    }

    .swift-check__content {
        text-align: center;
        margin-bottom: 0.75rem;

        p {
            color: #fff;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;

            &.title {
                font-size: 42px;
                font-weight: 700;
                margin-bottom: 5px;
            }
        }
    }

    .swift-check__form {
        padding-top: 20px;
        margin-bottom: 15px;

        .btn,
        .mg-btn {
            padding: 0.375rem 2rem;
            text-transform: none;
        }

        &.form {
            .form-group {
                .form-group__inner {
                    &::after {
                        content: 'Live';
                        position: absolute;
                        background-image: url('../img/sprite.svg#green-fire');
                        background-position: center right;
                        background-repeat: no-repeat;
                        height: 18px;
                        width: 44px;
                        line-height: 1.85;
                        display: inline-block;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);

                        opacity: 0.59;
                        color: #000000;
                        font-size: 10px;
                        font-weight: 400;
                        text-transform: uppercase;
                    }

                    .form-control {
                        padding-left: 35px;
                        color: #000000;
                        font-size: 18px;
                        font-weight: 300;
                    }

                    &.form-group__checkbox {
                        [type='checkbox'] {
                            + label {
                                &::before {
                                    border: 1px solid #bfbfbf;
                                    background-color: #ffffff;
                                }
                            }

                            &:checked {
                                + label {
                                    &::before {
                                        background-color: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /*.mg-loader {
        &::before {
            top: 50%;
            transform: translateX(-100%);
        }
    }*/
}
