.wrp-bank-list {
}

.bank-list {
    margin-bottom: 25px;

    .bank-list__item {
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
        border-left: 6px solid #4087ec;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin-bottom: 20px;
        position: relative;

        .btn,
        .mg-btn {
            white-space: nowrap;
        }
    }

    .bank-list__item-wrp-img {
        background-color: #fff;
        padding-top: 20%;
        position: relative;
        display: block;
        overflow: hidden;
        max-width: 250px;
        flex-shrink: 1;
        flex-grow: 0;
        width: 100%;
    }

    .bank-list__item-img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-height: 183px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 75%;
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;

        a {
            height: 100%;
            width: 100%;
            display: block;
            padding-bottom: 75%;
            position: absolute;
            top: 0;
        }

        img {
            object-fit: fill;
            object-position: center;
        }
    }

    .bank-list__item-content {
        flex-shrink: 1;
        flex-grow: 1;
        margin: 0 40px 0 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .bank-list__item-logo {
            display: flex;
            align-items: center;
            align-content: center;

            p {
                margin-bottom: 0;

                &.title {
                    margin-bottom: 0;
                    font-size: 16px;
                }
            }
        }

        .bank-list__item-text {
            max-height: 45px;
            overflow: hidden;
            color: #959595;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.3;
        }

        .bank-list__item-tags {
            margin-bottom: 8px;

            .item-tag {
                margin: 8px 7px 0 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .bank-list__item-rating {
        flex-shrink: 1;
        flex-grow: 0;
        max-width: 177px;
        width: 100%;

        .wrp-bank-widget__rating {
            display: none;
        }
    }
}

.list-tools {
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    .list-tools__read-more {
        font-size: 16px;
        font-weight: 300;
    }

    .list-tools__total {
        font-size: 16px;
        font-weight: 300;

        p {
            color: $greyB;
        }
        span {
            color: #000;
        }
    }
}

.bank-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 13px 9px;
    background-color: #f6f6f6;

    > * {
        flex-basis: 100%;
        margin-bottom: 0;
        text-align: center;
    }

    .bank-widget__rating {
        color: #00419f;
        font-size: 40px;
        font-weight: 500;
        line-height: 0.9;
        position: relative;
        padding-bottom: 0;

        &::before {
            content: '';
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 9px;
            height: 20px;
            display: inline-block;
            position: absolute;
            right: 50%;
            transform: translate(45px, 15%);
        }

        &.bank-widget__rating-top {
            &::before {
                background-image: url('../img/sprite.svg#rating-top');
            }
        }

        &.bank-widget__rating-down {
            &::before {
                background-image: url('../img/sprite.svg#rating-down');
                //transform: rotate(180deg);
                transform-origin: 70% 33%;
                background-position: top center;
            }
        }
    }

    .bank-widget__desc {
        color: #000000;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 1.33px;
        text-transform: uppercase;
    }
}

.item-tag {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 4px 12px;
    text-align: center;
    color: #414141;
    font-size: 12px;
    font-weight: 300;
    margin: 0 7px;

    &::before {
        content: '';
        border-radius: 30px;
        background-color: #00419f;
        opacity: 0.1;
        display: inline-block;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
    }
}
