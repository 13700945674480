.mg-icon {
    position: relative;
    height: 21px;
    width: 21px;
    display: inline-block;
    &::before {
        content: '';
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &.mg-icon__search {
        &::before {
            background-image: url('../img/sprite.svg#search');
        }

        &:hover {
            &::before {
                background-image: url('../img/sprite.svg#search-hover');
            }
        }
    }

    &.mg-icon__profile {
        &::before {
            background-image: url('../img/sprite.svg#profile');
        }
    }

    &.mg-icon__youtube {
        &::before {
            background-image: url('../img/sprite.svg#youtube-border');
        }
    }

    &.mg-icon__medal {
        &::before {
            background-image: url('../img/sprite.svg#medal');
        }
    }

    &.mg-icon__views {
        &::before {
            background-image: url('../img/sprite.svg#views');
        }
    }

    &.mg-icon__subscribes {
        &::before {
            background-image: url('../img/sprite.svg#subscribes');
        }
    }

    &.mg-icon__calendar {
        &::before {
            background-image: url('../img/sprite.svg#calendar');
        }
    }

    &.mg-icon__folder {
        &::before {
            background-image: url('../img/sprite.svg#folder');
        }
    }

    &.mg-icon__skype {
        width: 34px;
        height: 34px;
        &::before {
            background-image: url('../img/sprite.svg#skype-logo');
        }
    }

    &.mg-icon__whatsapp {
        width: 34px;
        height: 34px;
        &::before {
            background-image: url('../img/sprite.svg#whatsapp-logo');
        }
    }

    &.mg-icon__eng_phone {
        &::before {
            background-image: url('../img/sprite.svg#eng_flag');
        }
    }

    &.mg-icon__he_phone {
        &::before {
            background-image: url('../img/sprite.svg#he_flag');
        }
    }

    &.mg-icon__email {
        &::before {
            background-image: url('../img/sprite.svg#envelop');
        }
    }

    &.mg-icon__address {
        &::before {
            background-image: url('../img/sprite.svg#mark');
        }
    }
}

.soc-icon {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    margin: 0 5px;

    &::before {
        content: '';
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &.soc-icon__instagram {
        &::before {
            background-image: url('../img/sprite.svg#instagram');
        }

        &:hover {
            &::before {
                background-image: url('../img/sprite.svg#instagram-hover');
            }
        }

        &.soc-icon-dark {
            &::before {
                background-image: url('../img/sprite.svg#instagram-dark');
            }

            &:hover {
                &::before {
                    background-image: url('../img/sprite.svg#instagram-hover');
                }
            }
        }
    }

    &.soc-icon__youtube {
        &::before {
            background-image: url('../img/sprite.svg#youtube');
        }

        &:hover {
            &::before {
                background-image: url('../img/sprite.svg#youtube-hover');
            }
        }

        &.soc-icon-dark {
            &::before {
                background-image: url('../img/sprite.svg#youtube-dark');
            }

            &:hover {
                &::before {
                    background-image: url('../img/sprite.svg#youtube-hover');
                }
            }
        }
    }

    &.soc-icon__facebook {
        &::before {
            background-image: url('../img/sprite.svg#facebook');
        }

        &.soc-icon-dark {
            &::before {
                background-image: url('../img/sprite.svg#facebook-dark');
            }
        }
    }

    &.soc-icon__twitter-logo {
        &::before {
            background-image: url('data:image/svg+xml,%3C%3Fxml version=\'1.0\' %3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.0//EN\' \'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd\'%3E%3Csvg enable-background=\'new 0 0 32 32\' height=\'32px\' id=\'Layer_1\' version=\'1.0\' viewBox=\'0 0 32 32\' width=\'32px\' xml:space=\'preserve\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'%3E%3Cg%3E%3Cpath d=\'M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z\' fill=\'%2355ACEE\'/%3E%3Cpath d=\'M25.987,9.894c-0.736,0.322-1.525,0.537-2.357,0.635c0.85-0.498,1.5-1.289,1.806-2.231 c-0.792,0.461-1.67,0.797-2.605,0.978C22.083,8.491,21.017,8,19.838,8c-2.266,0-4.1,1.807-4.1,4.038 c0,0.314,0.036,0.625,0.104,0.922c-3.407-0.172-6.429-1.779-8.452-4.221c-0.352,0.597-0.556,1.29-0.556,2.032 c0,1.399,0.726,2.635,1.824,3.36c-0.671-0.022-1.304-0.203-1.856-0.506c-0.001,0.017-0.001,0.034-0.001,0.052 c0,1.955,1.414,3.589,3.29,3.96c-0.343,0.09-0.705,0.142-1.081,0.142c-0.264,0-0.52-0.024-0.77-0.072 c0.52,1.604,2.034,2.771,3.828,2.805C10.67,21.594,8.9,22.24,6.979,22.24c-0.33,0-0.658-0.018-0.979-0.056 c1.814,1.145,3.971,1.813,6.287,1.813c7.541,0,11.666-6.154,11.666-11.491c0-0.173-0.005-0.35-0.012-0.521 C24.741,11.414,25.438,10.703,25.987,9.894z\' fill=\'%23FFFFFF\'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E');
        }
    }

    &.soc-icon__youtube-logo {
        &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M31.67,9.179c0,0-0.312-2.353-1.271-3.389c-1.217-1.358-2.58-1.366-3.205-1.443C22.717,4,16.002,4,16.002,4 h-0.015c0,0-6.715,0-11.191,0.347C4.171,4.424,2.809,4.432,1.591,5.79C0.633,6.826,0.32,9.179,0.32,9.179S0,11.94,0,14.701v2.588 c0,2.763,0.32,5.523,0.32,5.523s0.312,2.352,1.271,3.386c1.218,1.358,2.815,1.317,3.527,1.459C7.677,27.919,15.995,28,15.995,28 s6.722-0.012,11.199-0.355c0.625-0.08,1.988-0.088,3.205-1.446c0.958-1.034,1.271-3.386,1.271-3.386s0.32-2.761,0.32-5.523v-2.588 C31.99,11.94,31.67,9.179,31.67,9.179z' fill='%23E02F2F'/%3E%3Cpolygon fill='%23FFFFFF' points='12,10 12,22 22,16 '/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E");
        }
    }

    &.soc-icon__instagram-logo {
        &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M28,0H4C1.741,0,0,1.741,0,4v24c0,2.258,1.741,4,4,4h24c2.258,0,4-1.742,4-4V4C32,1.741,30.258,0,28,0z M16.023,9.714c3.549,0,6.425,2.787,6.425,6.226c0,3.437-2.876,6.226-6.425,6.226c-3.546,0-6.423-2.789-6.423-6.226 C9.601,12.501,12.478,9.714,16.023,9.714z M28,27c0,0.701-0.3,1-1,1H5c-0.699,0-1-0.299-1-1V13l2.493,0.542 c-0.267,0.87-0.413,1.79-0.413,2.745c0,5.318,4.454,9.634,9.943,9.634c5.492,0,9.944-4.315,9.944-9.634 c0-0.955-0.145-1.875-0.412-2.745L28,13V27z M28,9c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V5c0-0.553,0.447-1,1-1h4 c0.553,0,1,0.447,1,1V9z' fill='%23316699'/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E");
        }
    }

    &.soc-icon__facebook-logo {
        &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z' fill='%233B5998'/%3E%3Cpath d='M22,32V20h4l1-5h-5v-2c0-2,1.002-3,3-3h2V5c-1,0-2.24,0-4,0c-3.675,0-6,2.881-6,7v3h-4v5h4v12H22z' fill='%23FFFFFF' id='f'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E");
        }
    }
}

.bank-icon {
    position: relative;
    padding-left: 24px;

    &::before {
        content: '';
        position: absolute;
        padding-right: 5px;
        left: -17px;
        height: 24px;
        width: 24px;
        background-size: cover;
        display: inline-block;
        line-height: inherit;
    }

    &.bank-icon__home {
        &::before {
            background-image: url('../img/sprite.svg#icon-home');
        }
    }

    &.bank-icon__mark {
        &::before {
            background-image: url('../img/sprite.svg#icon-mark');
        }
    }

    &.bank-icon__country {
        &::before {
            background-image: url('../img/sprite.svg#icon-country');
        }
    }

    &.bank-icon__swift {
        &::before {
            background-image: url('../img/sprite.svg#icon-swift');
        }
    }

    &.bank-icon__link {
        &::before {
            left: -41px;
            background-image: url('../img/sprite.svg#icon-link');
        }
    }

    &.bank-icon__hummer,
    &.bank-icon__regulator {
        &::before {
            left: -41px;
            background-image: url('../img/sprite.svg#icon-hummer');
        }
    }

    &.bank-icon__wiki,
    &.bank-icon__wikipedia {
        &::before {
            left: -41px;
            background-image: url('../img/sprite.svg#icon-wiki');
        }
    }

    &.bank-icon__linkedin {
        &::before {
            left: -41px;
            background-image: url('../img/sprite.svg#linkedin');
        }
    }

    &.bank-icon__twitter {
        &::before {
            left: -41px;
            background-image: url('data:image/svg+xml,%3C%3Fxml version=\'1.0\' %3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.0//EN\' \'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd\'%3E%3Csvg enable-background=\'new 0 0 32 32\' height=\'32px\' id=\'Layer_1\' version=\'1.0\' viewBox=\'0 0 32 32\' width=\'32px\' xml:space=\'preserve\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'%3E%3Cg%3E%3Cpath d=\'M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z\' fill=\'%233D01A1\'/%3E%3Cpath d=\'M25.987,9.894c-0.736,0.322-1.525,0.537-2.357,0.635c0.85-0.498,1.5-1.289,1.806-2.231 c-0.792,0.461-1.67,0.797-2.605,0.978C22.083,8.491,21.017,8,19.838,8c-2.266,0-4.1,1.807-4.1,4.038 c0,0.314,0.036,0.625,0.104,0.922c-3.407-0.172-6.429-1.779-8.452-4.221c-0.352,0.597-0.556,1.29-0.556,2.032 c0,1.399,0.726,2.635,1.824,3.36c-0.671-0.022-1.304-0.203-1.856-0.506c-0.001,0.017-0.001,0.034-0.001,0.052 c0,1.955,1.414,3.589,3.29,3.96c-0.343,0.09-0.705,0.142-1.081,0.142c-0.264,0-0.52-0.024-0.77-0.072 c0.52,1.604,2.034,2.771,3.828,2.805C10.67,21.594,8.9,22.24,6.979,22.24c-0.33,0-0.658-0.018-0.979-0.056 c1.814,1.145,3.971,1.813,6.287,1.813c7.541,0,11.666-6.154,11.666-11.491c0-0.173-0.005-0.35-0.012-0.521 C24.741,11.414,25.438,10.703,25.987,9.894z\' fill=\'%23FFFFFF\'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E');
        }
    }

    &.bank-icon__youtube {
        &::before {
            left: -41px;
            top: 2px;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M31.67,9.179c0,0-0.312-2.353-1.271-3.389c-1.217-1.358-2.58-1.366-3.205-1.443C22.717,4,16.002,4,16.002,4 h-0.015c0,0-6.715,0-11.191,0.347C4.171,4.424,2.809,4.432,1.591,5.79C0.633,6.826,0.32,9.179,0.32,9.179S0,11.94,0,14.701v2.588 c0,2.763,0.32,5.523,0.32,5.523s0.312,2.352,1.271,3.386c1.218,1.358,2.815,1.317,3.527,1.459C7.677,27.919,15.995,28,15.995,28 s6.722-0.012,11.199-0.355c0.625-0.08,1.988-0.088,3.205-1.446c0.958-1.034,1.271-3.386,1.271-3.386s0.32-2.761,0.32-5.523v-2.588 C31.99,11.94,31.67,9.179,31.67,9.179z' fill='%233D01A1'/%3E%3Cpolygon fill='%23FFFFFF' points='12,10 12,22 22,16 '/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E");
        }
    }

    &.bank-icon__instagram {
        &::before {
            left: -41px;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M28,0H4C1.741,0,0,1.741,0,4v24c0,2.258,1.741,4,4,4h24c2.258,0,4-1.742,4-4V4C32,1.741,30.258,0,28,0z M16.023,9.714c3.549,0,6.425,2.787,6.425,6.226c0,3.437-2.876,6.226-6.425,6.226c-3.546,0-6.423-2.789-6.423-6.226 C9.601,12.501,12.478,9.714,16.023,9.714z M28,27c0,0.701-0.3,1-1,1H5c-0.699,0-1-0.299-1-1V13l2.493,0.542 c-0.267,0.87-0.413,1.79-0.413,2.745c0,5.318,4.454,9.634,9.943,9.634c5.492,0,9.944-4.315,9.944-9.634 c0-0.955-0.145-1.875-0.412-2.745L28,13V27z M28,9c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V5c0-0.553,0.447-1,1-1h4 c0.553,0,1,0.447,1,1V9z' fill='%233D01A1'/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E");
        }
    }

    &.bank-icon__facebook {
        &::before {
            left: -41px;
            background-size: cover;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z' fill='%233D01A1'/%3E%3Cpath d='M22,32V20h4l1-5h-5v-2c0-2,1.002-3,3-3h2V5c-1,0-2.24,0-4,0c-3.675,0-6,2.881-6,7v3h-4v5h4v12H22z' fill='%23FFFFFF' id='f'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E");
        }
    }
}

.title {
    &.title-icon {
        padding-left: 75px;
        position: relative;

        &.title-icon__youtube {
            &::before {
                content: '';
            }
        }
    }
}
