.wrp-sign-up-block {
}

.sign-up-block {
    min-height: 640px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 150px;

    .sign-up-block__inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .news-list {
            position: relative;
            margin-bottom: 8.75rem;
        }
    }

    form {
        .form-group {
            .form-group__inner {
                margin: 0 7px;
            }
        }
    }

    .sign-up-block__content {
        text-align: center;
        margin-bottom: 2.75rem;

        p {
            color: #dedede;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;

            &.title {
                font-size: 42px;
                font-weight: 300;
                margin-bottom: 5px;
            }
        }
    }

    .sign-up-block__form {
        .btn,
        .mg-btn {
            padding: 0.375rem 2rem;
            text-transform: none;
        }

        &.form {
            .form-group {
                .form-group__inner {
                    &.form-group__checkbox {
                        [type='checkbox'] {
                            + label {
                                &::before {
                                    border: 1px solid #bfbfbf;
                                    background-color: #ffffff;
                                }
                            }

                            &:checked {
                                + label {
                                    &::before {
                                        background-color: $blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
