.brand-logo {
    height: 115px;
    width: 115px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    background-position: center;

    background-repeat: no-repeat;
    background-origin: border-box;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    background-image: linear-gradient(-45deg, #0452c2 0%, #3d01a1 100%);

    &::before {
        content: '';
        background-image: url('../img/sprite.svg#brand');
        display: inline-block;
        height: 45px;
        width: 45px;
        position: absolute;
        top: 5px;
        right: 5px;
    }
    p {
        opacity: 0.44;
        color: #ffffff;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 5.73px;
        text-transform: uppercase;
        white-space: nowrap;
        margin-bottom: 0;
        transform: rotate(45deg);
        transform-origin: 25px center;
        width: 150%;
        text-align: center;
    }
}

.bg-white {
    background-color: #fff !important;
}

.btn,
.mg-btn {
    display: inline-block;
    border: none;
    color: #4087ec;
    font-size: 16px;
    font-weight: 300;
    background-color: #ffffff;
    //white-space: nowrap;
    vertical-align: middle;
    border-radius: 3px;
    text-align: center;
    user-select: none;
    padding: 0.375rem 1.4rem;
    outline: none;
    transition: all 0.15s ease-in;

    &:hover {
        text-decoration: none;
    }

    &.btn-big {
        padding: 1.275rem 3.3rem;
    }

    &.btn-w100 {
        width: 100%;
    }

    &.btn-default {
        border: 1px solid $blue;

        &:hover {
            background-color: darken($blue, 10%);
            color: #fff;
        }

        &.btn-default_red {
            border-color: $red;
            color: $red;

            &:hover {
                background-color: $red;
                color: #fff;
            }
        }
    }

    &.btn-primary {
        background-color: $blue;
        color: #fff;

        &.btn-strict {
            border-radius: 0;
        }

        &:hover {
            text-decoration: none;
            background-color: darken($blue, 10%);
        }
    }

    &.btn-darkness {
        background-color: $blueD2;
        color: #fff;

        &.btn-strict {
            border-radius: 0;
        }

        &:hover {
            text-decoration: none;
            background-color: darken($blueD2, 10%);
        }
    }

    &.btn-danger {
        border-radius: 0;
        color: #ffffff;
        font-size: 18px;
        font-weight: 300;
        background-color: $red;

        &:hover {
            background-color: darken($red, 10%);
        }
    }

    &.btn-search {
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            height: 23px;
            width: 23px;
            background-image: url('../img/sprite.svg#search');
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            line-height: 1;
        }

        &:hover {
            &::before {
                background-image: url('../img/sprite.svg#search-hover');
            }
        }
    }

    &.btn-filter {
        background: #f6f6f6 0 0 no-repeat padding-box;
        border: 1px solid $blue;
        border-radius: 3px;
        opacity: 1;
        font-size: 18px;
        line-height: 22px;
        font-weight: 300;
        letter-spacing: 0;
        color: #000000;
        padding: 0.565rem 1.15rem;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            height: 22px;
            width: 24px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.334' height='22.711' viewBox='0 0 24.334 22.711'%3E%3Cg id='Group_344' data-name='Group 344' transform='translate(-540 -354)'%3E%3Cpath id='Path_466' data-name='Path 466' d='M543.238,363.359c0-.011.006-.021.006-.032v-8.922a.406.406,0,1,0-.811,0v8.922c0,.011.006.021.006.032a2.835,2.835,0,0,0,0,5.615c0,.011-.006.021-.006.032v7.3a.406.406,0,1,0,.811,0v-7.3c0-.011-.006-.021-.006-.032a2.835,2.835,0,0,0,0-5.615Zm-.4,4.835a2.028,2.028,0,1,1,2.028-2.028A2.03,2.03,0,0,1,542.839,368.195Z' fill='%23424242'/%3E%3Cpath id='Path_467' data-name='Path 467' d='M566.238,368.632c0-.011.006-.021.006-.032V354.406a.406.406,0,1,0-.811,0V368.6c0,.011.006.021.006.032a2.835,2.835,0,0,0,0,5.615c0,.011-.006.021-.006.032v2.028a.406.406,0,1,0,.811,0v-2.028c0-.011-.006-.021-.006-.032a2.835,2.835,0,0,0,0-5.615Zm-.4,4.835a2.028,2.028,0,1,1,2.028-2.028A2.03,2.03,0,0,1,565.839,373.467Z' transform='translate(-13.672)' fill='%23424242'/%3E%3Cpath id='Path_468' data-name='Path 468' d='M591.678,360.083a2.841,2.841,0,0,0-2.44-2.807c0-.011.007-.021.007-.032v-2.839a.406.406,0,0,0-.811,0v2.839c0,.011.006.021.006.032a2.835,2.835,0,0,0,0,5.615c0,.011-.006.021-.006.032v13.383a.406.406,0,0,0,.811,0V362.922c0-.011-.006-.021-.007-.032A2.841,2.841,0,0,0,591.678,360.083Zm-2.839,2.028a2.028,2.028,0,1,1,2.028-2.028A2.03,2.03,0,0,1,588.839,362.111Z' transform='translate(-27.344)' fill='%23424242'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: cover;
            line-height: 1;
            margin-right: 9px;
        }

        &:hover {
            &::before {
            }
        }
    }

    &.btn-button {
        height: 62px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 38px;
        background-color: #ffffff;
        color: #000000;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: relative;

        &:hover {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
        }

        &.btn-button_icon {
            padding-left: 65px;
        }

        .mg-icon {
            position: absolute;
            left: 25px;
        }
    }

    &.btn-w1oo {
        width: 100%;
    }

    &.btn-w0 {
        width: auto;
    }

    &[disabled] {
        opacity: 0.4;
    }
}

.row-page-block {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    margin-bottom: 30px;

    .page-block__body {
        width: 100%;
        margin-bottom: 0;
        display: flex;
    }

    .contact-links__item {
    }

    .main-content {
        position: relative;
        flex-basis: 100%;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
    }

    .bank-list {
        .mg-loader {
            &::before {
                top: 20%;
            }
        }
    }

    .sidebar {
        flex-basis: 43%;
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: 2%;
        //padding-top: 40px;

        &:before {
            display: none;
        }
    }
}

.flex-row-reverse {
    .sidebar {
        margin-left: 0;
        //padding-left: 0;
        //margin-right: 0;
        padding-right: 1.5%;
        border-right: 1px solid #c7c7c7;
        position: relative;
        padding-left: 0;

        &::after {
            content: '';
            display: block;
            z-index: 0;
            position: absolute;
            width: 98px;
            height: 100%;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
            opacity: 0.8;
            right: 0;
            top: 0;
            pointer-events: none;
        }

        .ms-spoiler-trigger {
            max-width: 400px;
            margin: 0 auto;
        }
    }

    .bank-list {
        padding-right: 0;
        padding-left: 1.5%;
    }
}

.main-content {
    &.main-content__additional {
        &.text-center {
            .title {
                text-align: center;
            }
        }

        p {
            color: $greyB;

            &.title {
                color: #000;
                margin-bottom: 10px;
            }
        }
    }

    .pagination {
        .screen-reader-text {
            display: none;
        }

        .nav-links {
            display: flex;
            justify-content: center;
        }
    }
}

.page-block {
    &.page-404 {
        //min-height: 50vh;

        .page-404__content {
            justify-content: center;

            > * {
                flex-basis: 100%;
                text-align: center;
            }
        }
    }

    .page-block__header {
        padding: 0 0 10px 0;
        text-align: center;
        flex-basis: 100%;
    }

    p {
        margin-bottom: 0;

        &.title {
            margin-bottom: 18px;
        }
    }

    .page-block__body {
        //display: flex;
        //flex-wrap: nowrap;
        width: 100%;
        margin: 0 auto;
    }
}

.bg-gradient {
    position: relative;
    z-index: 0;

    &::before {
        content: '';
        @include linear-gradient();
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        z-index: 2;
        opacity: 0.73;
    }

    .bg-gradient__content {
        position: relative;
        z-index: 3;
    }
}

.parallax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.breadcrumbs {
    width: 100%;

    &.breadcrumbs_block {
        padding: 15px 0;

        .breadcrumbs__item,
        .breadcrumbs__link {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .breadcrumbs__list {
        padding: 0;
        margin: 0;
        list-style: none;
        display: block;
    }

    .breadcrumbs__item {
        font-size: 16px;
        font-weight: 300;
        margin-right: 5px;
        padding: 2px 0;
        display: inline;

        &::after {
            content: '>';
            display: inline;
            color: #bebebe;
            position: relative;
            left: 5px;
        }

        &:last-child {
            margin-right: 0;

            &::after {
                content: '';
            }
        }
    }

    .breadcrumbs__link {
        font-size: 16px;
        font-weight: 300;
        color: #bebebe;
    }

    span {
        &.breadcrumbs__link {
            color: #4087ec;
        }
    }
}

.table-details {
    background-color: #fff;
    border: 1px solid #cfcfcf;
    width: 100%;

    tr {
        min-height: 60px;

        &:nth-child(even) {
            background-color: #f2f2f2;
        }

        th {
            color: #0a0057;
            font-size: 16px;
            font-weight: 500;
            border-right: 1px solid #cfcfcf;
            background-color: #f2f2f2;
            padding: 20px 40px;
            vertical-align: middle;

            &:first-child {
                white-space: nowrap;
            }

            &:last-child {
                border-right: none;
            }
        }

        td {
            vertical-align: middle;
            padding: 5px 40px;
            color: #000000;
            font-size: 16px;
            font-weight: 300;
            border-right: 1px solid #cfcfcf;

            &:first-child {
                padding: 20px 40px;
            }

            &:last-child {
                border-right: none;
            }
        }
    }
}

.mCSB_scrollTools {
    width: 7px;
    background-color: #dadada;

    .mCSB_dragger {
        &:hover {
            .mCSB_dragger_bar {
                background-color: #0099ff;
            }
        }

        &.mCSB_dragger_onDrag {
            .mCSB_dragger_bar {
                background-color: #0099ff;
                width: 100%;
                border-radius: 0;
            }
        }

        .mCSB_dragger_bar {
            background-color: #0099ff;
            width: 100%;
            border-radius: 0;
        }
    }

    .mCSB_draggerRail {
        background-color: transparent;
    }
}

.mCSB_inside {
    > .mCSB_container {
        margin-right: 16px;
    }
}

.text-center {
    text-align: center;
}

.w100 {
    width: 100%;
}

.news-tag {
    background-color: #4087ec;
    padding: 7px 15px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;

    &:hover {
        color: #fff;
    }
}

.posts-navigate {
    display: flex;
    flex-wrap: nowrap;

    .posts-navigate__item {
        width: 42px;
        height: 42px;
        background-color: #e7e6ee;
        position: relative;
        display: inline-block;
        color: transparent;
        line-height: 42px;
        text-align: center;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-size: 50%;
            width: 100%;
            display: inline-block;
            background-image: url('../img/sprite.svg#arrow-navigate');
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 0.2s;
        }

        &:hover {
            &::before {
                transform: scale(1.2);
            }
        }
    }

    .posts-navigate__prev {
        margin-right: 7px;

        &::before {
            background-image: url('../img/sprite.svg#arrow-navigate-prev');
        }
    }

    .posts-navigate__next {
    }
}

.lg-visible,
.xl-visible {
    display: none;
}

.wrp-br-header__hamburger {
    height: 100%;
}

.br-header__hamburger {
    //height: 1.55555556rem;
    height: 30px;
    margin-right: 0;
    min-width: 25px;
    width: 1.55555556rem;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    outline: none;
    position: relative;
}

.br-header__hamburger__bars {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.br-header__hamburger.--active .br-header__hamburger__bars {
    background: transparent;
}

.br-header__hamburger__bars,
.br-header__hamburger__bars::after,
.br-header__hamburger__bars::before {
    height: 0.11111111rem;
    background-color: #151515;
    position: relative;
    transition: top 0.1s 0.1s, transform 0.1s, background 0s 0.1s;
}

.br-header__hamburger.--active .br-header__hamburger__bars::after,
.br-header__hamburger.--active .br-header__hamburger__bars::before {
    width: 1rem;
    top: 0;
    transition: top 0.1s, transform 0.1s 0.1s;
}

.br-header__hamburger.--active .br-header__hamburger__bars::before {
    transform: rotate(45deg);
}

.br-header__hamburger.--active .br-header__hamburger__bars::after {
    transform: rotate(-45deg);
}

.br-header__hamburger__bars::after,
.br-header__hamburger__bars::before {
    content: '';
    display: block;
    position: absolute;
}

.br-header__hamburger__bars::before {
    width: 1rem;
    top: -0.33333333rem;
}

.br-header__hamburger__bars::after {
    width: 0.66666667rem;
    top: 0.33333333rem;
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.mg-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999;

    &::before {
        content: '';
        display: inline-block;
        width: 50px;
        height: 50px;
        //background-image: url('../img/sprite.svg#loader');
        top: 50%;

        border: 8px solid rgba(255, 255, 255, 0);
        border-top: 8px solid #003f91;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        position: absolute;
        left: calc(50% - 25px);
        transform: translateX(-50%);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.nav-links {
    .page-numbers {
        box-sizing: border-box;
        display: inline-block;
        min-width: 1.5em;
        padding: 0.5em 1em;
        margin-left: 2px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        *cursor: hand;
        color: #333;
        border: 1px solid transparent;
        border-radius: 2px;

        &:hover {
            color: white;
            border: 1px solid #111;
            background-color: #585858;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, #585858),
                color-stop(100%, #111)
            );
            background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
            background: -moz-linear-gradient(top, #585858 0%, #111 100%);
            background: -ms-linear-gradient(top, #585858 0%, #111 100%);
            background: -o-linear-gradient(top, #585858 0%, #111 100%);
            background: linear-gradient(to bottom, #585858 0%, #111 100%);
        }
    }

    .current {
        color: #333;
        border: 1px solid #979797;
        background-color: white;
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
        background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
        background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
        background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
        background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
        background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);

        &:hover {
            cursor: default;
            color: #333;
            border: 1px solid #979797;
            background-color: white;
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, white),
                color-stop(100%, #dcdcdc)
            );
            background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
            background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
            background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
            background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
            background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
        }
    }

    .dots {
    }

    .next,
    .prev {
    }
}

.h100 {
    height: 100%;
}

.inner-w-100 {
    &::before {
        content: '';
        //width: 100vw;
        //position: relative;
        //left: 50%;
        //right: 50%;
        //margin-left: -50vw;
        //margin-right: -50vw;
        //position: absolute;
        //width: 100vw;
        //margin-left: -50vw;
        //background-color: #fff;
        //height: 10px;
        //display: inline-block;
    }
}

.mm-wrapper__blocker {
    &.mm-slideout {
        .mm-sronly {
            height: 50px !important;
            width: 50px !important;
            left: 15px !important;
            top: 10px !important;
            z-index: 9;
            clip-path: none !important;
            clip: initial !important;
            line-height: 1;
            text-align: center;

            &::before {
                content: '×';
                font-style: inherit;
                color: #fff;
                display: block;
                line-height: 1;
                font-size: 50px;
                font-weight: 400;
                transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
            }

            &:hover {
                &::before {
                    transform: scale(1.2);
                }
            }
        }
    }
}

.grecaptcha-badge {
    z-index: 3;
}

.vc_toggle_title h4 {
    font-size: 16px;
    font-weight: 400;
}

.dataTables_inner .mCSB_horizontal.mCSB_inside > .mCSB_container {
    margin-bottom: 6px;
}

.mg-inline-block {
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.22);
    margin: 10px 0;
    background-color: #f3f3f3;

    form {
        .form-group {
            .form-group__inner {
                flex-basis: initial;
                margin: 0 5px;

                .form-control {
                    border-bottom: 1px solid #d5d5d5;

                    &.wpcf7-checkbox {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
