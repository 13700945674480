.top-bank {
    .top-bank__inner {
    }

    .top-bank__list {
    }

    .top-bank__item {
        .top-bank__item-content {
            padding: 0 8% 1px;
            margin-top: -6px;

            .top-bank__item-tags {
                list-style: none;
                padding-left: 0;
            }

            .top-bank__tag {
                border-bottom: 1px solid #f0f0f0;

                &:last-child {
                    border-bottom: none;
                }
            }

            .top-bank__link {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                padding: 6px 0;
                align-items: center;

                color: #000000;
                font-size: 18px;
                font-weight: 400;

                &:hover {
                    text-decoration: none;

                    span {
                        text-decoration: underline;
                    }

                    .top-bank__count {
                        text-decoration: none;
                    }
                }
            }

            .top-bank__count {
                display: inline-block;
                padding: 4px 18px;
                border-radius: 20px;
                background-color: #f0f0f0;
                color: #000;
                text-decoration: none;
            }
        }
    }
}

.item-title {
    height: 161px;
    position: relative;
    text-align: center;
    margin-bottom: 0;
    background-color: #fff;

    &::before {
        content: '';
        display: block;
        height: 41%;
        left: 0;
        top: 0;
        background-image: linear-gradient(98deg, #0a0057 0%, #3d01a1 46%, #4087ec 100%);
    }

    &::after {
        content: '';
        display: block;
        width: 97px;
        height: 97px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 10%;
        padding: 5%;
    }

    p,
    a {
        margin-bottom: 0;

        &.title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            color: #0a0057;
            font-size: 24px;
            font-weight: 700;
        }
    }
}

.mega-icon {
    background-size: 0;
    &::after {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 53%;
    }

    &.mega-icon-parent {
        &::after {
            background-image: inherit;
        }
    }

    &.mega-icon-industry {
        &::after {
            background-image: url('../img/sprite.svg#top-icon1');
        }
    }

    &.mega-icon-area {
        &::after {
            background-image: url('../img/sprite.svg#top-icon2');
        }
    }

    &.mega-icon-service {
        &::after {
            background-image: url('../img/sprite.svg#top-icon3');
        }
    }

    &.mega-icon-calculate {
        &::after {
            background-image: url('../img/sprite.svg#calculator');
        }
    }

    &.mega-icon-checker {
        &::after {
            background-image: url('../img/sprite.svg#checker');
        }
    }

    &.mega-icon-news {
        &::after {
            background-image: url('../img/sprite.svg#news');
        }
    }
}
