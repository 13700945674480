.wrp-main-search {
    .main-search {
        min-height: 470px;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 55px 0 5px;

        p {
            width: 100%;
            margin-bottom: 10px;

            color: #a2bae2;
            font-size: 12px;
            font-weight: 300;

            &.title {
                color: #ffffff;
                font-size: 46px;
                font-weight: 700;
                letter-spacing: 0.1px;
            }

            &.sub-title {
                color: #ffffff;
                font-size: 24px;
                letter-spacing: 0.2px;
            }
        }

        .form {
            width: 1080px;
            padding: 25px 30px;
            position: relative;
            margin: 20px 0 20px;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #fff;
                opacity: 0.25;
                left: 0;
                top: 0;
            }

            .form-group {
                //max-height: 60px;

                .form-group__inner {
                    margin: 0 5px;
                }
            }
        }

        .main-search_min-desc {
            p,
            li,
            span,
            ul,
            a {
                font-size: 12px;
            }
        }

        .btn-danger {
            text-transform: none;
        }

        .btn,
        .mg-btn {
            white-space: nowrap;
        }
    }
}
