.container {
    max-width: 1400px;
}

.mm-navbar {
    .soc-icon {
        &::before {
            max-height: 25px;
        }
    }
}

@media screen and (max-width: 1500px) {
    .page-post {
        &.page-contact {
            .page-post__header {
                position: static;
                transform: initial;
                padding-top: 5px;

                h1 {
                    font-size: 42px;
                    line-height: 1.1;
                }

                p {
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 1370px) {
    .news-widget {
        &.news-widget__video {
            .news-widget__list {
                justify-content: space-between;
            }

            .news-widget__item {
                margin-right: 0;
            }
        }
    }

    .sign-up-block {
        margin-top: 115px;
    }

    .wrp-page-contact__content {
        transform: translateY(-106px);
        background-color: #fff;
    }
}

/**
  Планшет горизонтальный
 */
@media screen and (max-width: 1200px) {
    .xl-visible {
        display: block;
    }

    .container {
        max-width: 100%;
    }

    .header-block {
        .header-block__row-1 {
            height: auto;
        }
    }

    .news-widget {
        max-height: initial;

        &.news-widget__type2 {
            .news-widget__img {
            }

            .news-widget__preview {
                .news-widget__preview-content {
                    padding: 15px 10px;

                    .title {
                        font-size: 24px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .news-widget__desc {
            padding: 15px;
        }

        .news-widget__list {
            //max-height: 278px;
        }
    }

    .page-post {
        &.page-archive {
            .wrp-page-post__slider {
                height: auto;
                transform: initial;
            }

            .page-post__header-bg {
                min-height: initial;
                height: auto;
                padding-bottom: 0;
                margin-bottom: 30px;

                &::before {
                    display: none;
                }

                .page-post__header-sub {
                    &::before {
                        content: '';
                        width: 100%;
                        display: block;
                        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                        opacity: 0.19;
                        z-index: 0;
                        position: absolute;
                        height: 100%;
                        bottom: 0;
                        left: 0;
                    }

                    .page-post__header {
                        max-height: initial;
                        overflow: visible;
                    }
                }
            }
        }

        .page-post__header {
            h1 {
                font-size: 40px;
                line-height: 43px;
            }
        }
        &.page-contact {
            .page-post__header-bg {
                height: initial;
                padding-bottom: 30px;
            }
        }

        .page-post__header {
            //position: static;

            div,
            p {
                font-size: 18px;
            }
        }

        .page-post__slider {
            .page-post__slider-content {
                p {
                    &.title {
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .wrp-page-contact__content {
        transform: translateY(-105px);
    }

    .page-video-center {
        h1 {
            padding-left: 70px;

            &::after {
                height: 43px;
                //right: -70px;
            }
        }
    }

    .sign-up-block {
        margin-top: 100px;
    }

    .news-item {
        .news-item__content {
            padding-top: 3%;
        }
    }
}

@media screen and (max-width: 991px) {
    .lg-visible {
        display: block;
    }

    .lg-hidden {
        display: none;
    }

    p {
        &.title {
            font-size: 20px;
        }
    }

    .header-block {
        .header-block__row-1 {
            height: auto;
        }

        .header-block__row-2 {
            display: none;
        }

        .header-block__row-3 {
            display: block;
        }
    }

    .row-page-block {
        .page-block__body {
            flex-wrap: wrap;
        }
    }
    .row-page-block {
        .main-content {
        }

        .sidebar {
            flex-basis: 100%;
            margin: 0 auto;
        }
    }

    form {
        .form-group {
            &.form-group__inline {
                flex-wrap: wrap;
                flex-basis: 100%;
                margin-bottom: 15px;

                .form-group__inner {
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                > .btn,
                .mg-btn {
                    margin-left: auto;
                }
            }

            .form-group__inner {
                margin: 0 0 15px;
                flex-basis: 100%;

                &:last-child {
                    margin-bottom: 0;
                }

                &.form-group__btn {
                    width: 100%;
                    max-width: initial;
                }
            }
        }
    }

    .sign-up-block {
        margin-top: 85px;

        form {
            .form-group {
                .form-group__inner {
                    //margin: 0 0 15px;
                }
            }
        }
    }

    .landing-form {
        .landing-form__inner {
            padding: 15px;
        }
        .landing-form__content {
            p {
                font-size: 18px;

                &.title {
                    font-size: 26px;
                }
            }
        }
    }

    .wrp-main-search {
        .main-search {
            .form {
                .form-group {
                    .form-group__inner {
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }

    .news-widget {
        max-height: initial;

        &.news-widget__type2 {
            .news-widget__img {
            }

            .news-widget__preview {
                .news-widget__preview-content {
                    .title {
                    }
                }
            }
        }

        .news-widget__list {
            //max-height: 278px;
        }

        .news-widget__desc {
            min-height: initial;
        }
    }

    .widget-block {
        .widget-block__content {
            &.news-widget {
                flex-wrap: wrap;
            }
        }

        .widget-block__cell {
            margin-bottom: 15px;
            flex-wrap: wrap;

            .read-more {
                margin-left: auto;
                margin-top: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .bank-single {
        .bank-single__header {
            h1 {
                line-height: 1;
                margin-bottom: 15px;
            }
        }

        .bank-single__body {
            .main-content {
                padding: 15px;
            }

            .sidebar {
                padding: 15px;
            }

            .bank-single__body-content {
                padding: 15px;
            }
        }
    }

    .row-page-block {
        flex-wrap: wrap;

        .flex-row-reverse {
            .main-content,
            .sidebar {
                padding: 30px;
            }

            .main-content {
                order: 2;
            }

            .sidebar {
                order: 1;
                border-right: 0;

                &::after {
                    display: none;
                }
            }
        }

        .main-content,
        .sidebar {
            margin-bottom: 30px;
        }
    }

    .page-single {
        .page-single__header {
            padding-bottom: 10px;
            h1 {
                font-size: 32px;
            }
            .news-tag {
                top: -30px;
            }
        }

        .block-widget {
            &.block-widget-subscribe {
                padding-top: 0;

                .block-widget__title {
                    position: relative;
                    margin-bottom: 36px;
                }
            }
        }
    }

    .footer-block {
        padding-bottom: 15px;

        .footer-block__menu {
            padding: 0 15%;
        }
    }

    .ms-tabs-content {
        padding: 0 15px;
        margin-bottom: 30px;
    }

    .wrp-main-content {
        padding-top: 68px;
    }

    .page-post {
        .page-post__image,
        .page-post__content {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .page-post__header {
            .page-post__info-list {
                display: flex;
                flex-wrap: nowrap;

                &::before {
                    width: calc(96% + 10px);
                }
            }
        }
    }

    .category-list {
        &.category-list_video {
            .category-list__menu {
                flex-wrap: wrap;
            }
        }
    }

    .ms-tabs-content > div {
        display: block;
    }

    .ms-visible {
        display: block;
    }

    .ms-hidden {
        display: none;
    }

    .ms-visible.spoiler-trigger + .spoiler-block {
        display: none;
    }

    .ms-visible.spoiler-trigger + .spoiler-block.active {
        display: block;
    }

    .ms-spoiler-block {
        display: none;
        padding-top: 15px;
    }

    .ms-spoiler-block.active {
        display: block;
    }

    .ms-spoiler-block .ms-hidden {
        display: block;
    }

    .wrp-page-contact__content {
        transform: translateY(-70px);
    }

    .page-post {
        &.page-contact {
            .page-post__header {
                position: static;
                transform: initial;
                padding-top: 5px;

                h1 {
                    //font-size: 32px;
                    //line-height: 1;
                    margin-bottom: 7px;
                }
            }

            .page-contact__content {
                padding-top: 35px;
            }
        }

        .block-widget {
            &.block-widget-subscribe {
                &.block-widget-absolute {
                    padding-top: 0;

                    .block-widget__title {
                        position: relative;
                    }
                }
            }
        }

        /*.page-post__header {
            .page-post__info-list {
                flex-wrap: wrap;

                .page-post__info-item {
                    flex-basis: 100%;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }*/
    }

    .block-widget {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        .block-widget__body {
            padding-top: 10%;
        }

        &.block-widget-image {
            margin-bottom: 45px;
        }

        &.block-widget-subscribe_min {
            transform: initial;
            max-width: initial;

            .block-widget__youtube-count {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &.block-widget-list {
            .block-widget__body {
                padding: 15px;
            }
        }

        &.h100 {
            .block-widget__body {
                height: initial;
            }
        }
    }

    .category-list {
        .category-list__items {
            display: block;
        }

        .category-list__item {
            display: inline-block;
            width: auto;
        }
    }

    .page-post {
        &.page-post_single {
            .page-post_border {
                padding-right: 0;
            }

            /*.page-post__info-list {
                .page-post__info-item {
                    border-left: none;
                    margin-bottom: 5px;
                    display: block;
                    margin-left: 0;
                    padding: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }*/
        }

        .page-post__slider {
            .page-post__slider-text {
                max-height: initial;
            }
        }
    }
}

@media screen and (max-width: 901px) {
    .page-post {
        &.page-contact {
            .page-contact__content {
                .page-contact__content-links {
                    flex-wrap: wrap;
                    justify-content: center;

                    > * {
                        flex-basis: 100%;
                    }

                    .partner-list {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

/**
  Планшет вертикальный
 */
@media screen and (max-width: 767px) {
    body {
        line-height: 1.2;
    }

    h1 {
        font-size: 32px;
    }

    p {
        &.title {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .sidebar {
        padding: 0 15%;
        max-width: 100%;
    }

    .top-bank {
        .top-bank__item {
            margin-bottom: 15px;
        }
    }

    .row-page-block {
        .flex-row-reverse {
            .sidebar {
                margin-bottom: 0;
            }

            .block-widget {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .main-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }

    .bank-list {
        .bank-list__item {
            flex-wrap: wrap;
            max-width: 400px;
            margin: 0 auto 30px;
            border-left: none;
            padding-bottom: 10px;
        }

        .bank-list__item-wrp-img {
            display: none;
        }

        .bank-list__item-img {
            display: none;
        }

        .bank-list__item-content {
            margin: 0 15px;
            padding: 0 15px;

            .bank-list__item-text {
                max-height: initial;
            }

            .bank-list__item-tags {
            }
        }

        .bank-list__item-rating {
            max-width: initial;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            background-color: #fff;
            padding: 10px 0 0 30px;

            .wrp-bank-widget__rating {
                margin-bottom: 0;
                order: 2;
                background-color: #f6f6f6;
                max-width: 133px;
                align-self: flex-end;
                padding: 10px 0;
            }

            .bank-widget__read-more {
                order: 1;
                max-width: 160px;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .bank-widget {
            > * {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .bank-widget__rating {
                font-size: 25px;
                line-height: 30px;
            }

            .bank-widget__desc {
                font-size: 10px;
                line-height: 13px;
            }
        }

        .bank-list__read-more {
            margin: 0 auto;
            flex-basis: 100%;
        }
    }

    .item-tag {
        font-size: 10px;
    }

    .list-tools {
        flex-basis: 100%;

        .list-tools__read-more {
            margin: 0;
        }
    }

    .page-block {
        p {
            &.title {
                margin-bottom: 10px;
            }
        }

        .page-block__header {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .sign-up-block {
        .sign-up-block__content {
            p {
                &.title {
                    font-size: 24px;
                    font-weight: 700;
                }
            }
        }
    }

    .block-widget {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        &:last-child {
            margin-bottom: 45px;
        }

        &.block-widget-list {
            padding-top: 10px;
        }

        &.block-widget-subscribe_min {
            max-width: 100%;
        }
    }

    .sign-up-block {
        .news-item {
            .news-item__img {
                position: relative;
            }
        }
    }

    .bank-single {
        .bank-single__rating {
            .bank-widget {
                margin-top: 0;
            }
        }

        .bank-single__body {
            .bank-single__body-content {
                p {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .table-details {
        tr {
            th {
                padding: 10px 5px;
            }
        }
    }

    .sign-up-block {
        margin-top: 0;

        .sign-up-block__inner {
            padding-top: 30px;
            .news-list {
                margin-bottom: 0;
            }
            .news-item {
                margin-bottom: 30px;
                box-shadow: none;
            }
        }
        .sign-up-block__content {
            p {
                font-size: 18px;
                &.title {
                    font-size: 34px;
                }
            }
        }
    }

    .footer-block {
        .footer-block__item {
            //margin-top: 5px;
        }

        .footer-block__menu {
            padding: 0 15px;
        }
    }

    .table-details {
        tr {
            td {
                padding: 5px 15px;

                &:first-child {
                    padding: 15px 30px;
                }
            }
        }
    }

    .page-single {
        .page-single__header-bg {
            padding-bottom: 40%;
        }
    }

    .bank-list {
        .bank-list__item-content {
            .bank-list__item-logo {
                margin: 7px 0;
            }
        }
    }

    .bank-single {
        .bank-single__tags {
            .bank-single__tags-list {
                justify-content: space-between;

                .bank-single__elem {
                    margin: 0 0 10px 0;
                }
            }

            .bank-single__tag {
                margin-top: 5px;
            }
        }
    }

    .page-post {
        .page-post__image,
        .page-post__content {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }

    .page-post {
        .page-post__header {
            h1 {
                //font-size: 28px;
            }
        }
        &.page-contact {
            .page-contact__content {
                flex-wrap: wrap;
                padding-top: 5px;

                .page-contact__content-base {
                    padding: 15px;
                }

                .page-contact__content-data {
                    margin-left: 0;
                    border-left: 0;
                }
            }
        }
    }

    .category-list {
        padding: 15px;
    }

    .wrp-page-contact__content {
        transform: translateY(-58px);
    }

    .news-widget {
        .news-widget__img {
            width: 100%;
            height: 0;
            padding-bottom: 80%;
            background-size: cover;
        }
        &.news-widget__type2 {
            .news-widget__img {
                width: 100%;
            }

            .news-widget__list {
                //padding: 0 15%;
            }

            .news-widget__content {
                padding: 15px 5px 20px 5px;

                .title {
                    height: auto;
                    margin-bottom: 10px;
                    flex-basis: 100%;
                }

                .desc {
                    max-height: initial;
                    margin-bottom: 10px;
                    padding-bottom: 3px;
                }
            }

            .news-widget__preview {
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 66%;
            }

            .widget-block__cell {
                order: 2;
                margin-right: 0;
            }
            .widget-block__col {
                order: 1;
                margin-bottom: 30px;
            }
        }
        .news-widget__item {
            flex-wrap: wrap;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;

            &.active {
            }
        }

        .news-widget__list {
            .mCSB_container {
                padding: 0;
            }
        }

        .news-widget__content {
            .date {
                position: static;
                text-align: right;
                margin-top: 5px;
            }
        }
    }

    .widget-block {
        .news-widget {
            &.news-widget__type2 {
                .news-widget__content {
                    padding-bottom: 20px;
                }
            }
        }
    }

    .news-widget {
        &.news-widget__video {
            .news-widget__item {
                max-width: 46%;
                //width: 100%;
                //margin: 0 auto;
            }

            .news-widget__img {
                //height: 80%;
            }

            .news-widget__content {
                height: auto;
            }
        }
    }

    .wrp-main-search {
        .main-search {
            .form {
                padding: 15px;
            }

            p {
                &.title {
                    font-size: 28px;
                }

                &.sub-title {
                    font-size: 18px;
                }
            }
        }
    }

    .page-post {
        .page-post__slide {
            flex-wrap: wrap;
            padding: 10px;
            margin-bottom: 10px;

            &.page-post__slide-video {
                .page-post__image,
                .page-post__content {
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        .page-post__content {
            padding: 10px 0 0 10px;
        }

        .page-post__slider {
            &::before {
                display: none;
            }

            .page-post__slider-content {
                p {
                    &.title {
                        font-size: 22px;
                    }
                }
            }

            .page-post__slider-tools {
                margin-bottom: 10px;
            }

            .page-post__slider-text {
                margin-bottom: 15px;
            }

            .page-post__slider-img {
                padding-bottom: 60%;
            }
        }

        .page-post__slider-btn {
            bottom: 10px;
            right: 10px;
        }
    }

    .bank-list {
        .bank-list__read-more {
            max-width: 400px;
        }
    }

    .list-tools {
        max-width: 400px;
    }

    .news-item {
        width: 380px;
        margin: 0 auto;
    }

    .bank-single {
        .bank-single__header {
            .bank-single__header-inner {
                h1 {
                    margin-bottom: 7px;
                }

                .title {
                    font-size: 16px;
                    line-height: 1;
                }
            }
        }
    }

    .breadcrumbs {
        margin-bottom: 10px;

        .breadcrumbs__link {
            font-size: 14px;
        }
    }

    .page-video-center {
        h1 {
            &::after {
                height: 36px;
            }
        }
    }
}

/**
  Мобильные устройства
 */
@media screen and (max-width: 576px) {
    .news-widget {
        &.news-widget__type2 {
            .news-widget__item {
                flex-wrap: wrap;
            }

            .news-widget__img {
            }

            .news-widget__content {
                .desc {
                    display: none;
                }

                .date {
                    display: none;
                }
            }
        }
    }

    .sidebar {
        padding: 0;
    }

    .news-widget {
        &.news-widget__type2 {
            .news-widget__list {
                padding: 0;
            }
        }
    }

    .block-widget {
        &.block-widget-subscribe_min {
            .block-widget__content {
                p {
                    text-align: center;
                    &.title {
                        font-size: 20px;
                    }
                }
            }

            .block-widget__youtube {
                flex-wrap: wrap;
                justify-content: center;

                .block-widget__youtube-count {
                    margin-right: 0;
                    flex-basis: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    &:last-child {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        &.block-widget-video {
            .block-widget__title {
                p {
                    &.title {
                        font-size: 18px;
                    }
                }
            }
        }

        .block-widget__title {
            padding: 0 5%;
        }
    }

    .page-single {
        .page-single__header-bg {
            padding-bottom: 60%;
        }
    }

    .page-post {
        .page-post__header {
            h1 {
                font-size: 32px;
                line-height: 1;
            }
        }

        .page-post__header-bg {
            &::before {
                opacity: 0.75;
                height: 75%;
            }
        }
    }

    .row-page-block {
        .flex-row-reverse {
            .main-content,
            .sidebar {
                padding: 15px 0;
            }
        }
    }

    .btn-big,
    .bank-single__share {
        width: 100%;
    }

    h1 {
        font-size: 28px;
    }

    .bank-single {
        .bank-single__header {
            h1 {
                margin-bottom: 10px;
            }
        }
    }

    .bank-single {
        .bank-single__tags {
            .bank-single__tags-list {
                justify-content: center;
            }
        }
    }

    .wpdt-c {
        .wpDataTablesWrapper {
            .dataTables_paginate {
                > span {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .page-single {
        .page-single__header-bg {
            padding-bottom: 80%;
        }
        .page-single__header {
            h1 {
                max-height: 130px;
                overflow: hidden;
            }
        }
    }

    .table-details {
        tr {
            th {
                padding: 15px;

                &:first-child {
                    white-space: normal;
                }
            }

            td {
                padding: 5px 15px;

                &:first-child {
                    padding: 15px;
                }
            }
        }
    }

    .bank-icon {
        &::before {
            left: -8px;
        }
    }
}

@media screen and (max-width: 430px) {
    .bank-list {
        .bank-list__item {
            max-width: 100%;
        }
    }

    .news-widget {
        &.news-widget__video {
            .news-widget__item {
                width: 100%;
                flex-basis: 100%;
                margin: 0 auto 35px auto;
                max-width: 100%;
                height: 100%;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            }

            .news-widget__img {
                height: 100%;

                iframe {
                    min-height: 225px;
                }
            }
        }

        .news-widget__preview {
            iframe {
                height: 211px;
            }
        }

        .news-widget__desc {
            p {
                font-size: 18px;
            }
        }

        .news-widget__img {
            padding-bottom: 78%;
        }
    }
}

@media screen and (max-width: 390px) {
    .wpdt-c {
        .wpDataTables {
            &.wpDataTablesWrapper {
                overflow-y: hidden;
                overflow-x: auto;

                .paging_full_numbers {
                    height: initial;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .news-item {
        width: auto;
    }

    .header-block {
        .header-block__row-1 {
            display: flex;
            flex-wrap: nowrap;
        }
    }
}

@media screen and (min-width: 1200px) {
    .btn,
    .mg-btn {
        &.btn-xl-w0 {
            width: auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .footer-block {
        .footer-block__menu {
            .ms-spoiler-trigger {
                background: none;
            }
        }
    }

    .btn,
    .mg-btn {
        &.btn-lg-w0 {
            width: auto;
        }
    }
}

@media screen and (min-width: 768px) {
    .btn,
    .mg-btn {
        &.btn-md-w0 {
            width: auto;
        }
    }
}

@media screen and (min-width: 576px) {
    .btn,
    .mg-btn {
        &.btn-sm-w0 {
            width: auto;
        }
    }
}
