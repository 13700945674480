.rating-star__wrp {
    flex-direction: column;
    height: 75px;
}

.star-rating__container {
    display: inline-block;
    position: relative;
}

.star-rating__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.star-rating {
    display: inline-block;
    position: relative;
    user-select: none;
}

.star-rating__bg {
    color: #e0e0e0;
    display: flex;
}

.star-rating__live {
    display: flex;
    color: #ffb74d;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
}

.star-rating_active:hover .star-rating__live {
    overflow: auto;
    width: 100% !important;
    color: #e0e0e0;
}

.star-rating__item_active {
    color: #fb8c00;
    cursor: pointer;
    transition: color 0.1s ease-in-out;
}

.star-rating__item {
    width: 19px;
    height: 19px;
    flex: 0 0 19px;
}

.star-rating__votes {
    display: flex;
    font-size: 0.875em;
}

.rating-star__label,
.star-rating__votes_count {
    color: #7b8faa;
    line-height: 1.5;
}
.star-rating__votes_count {
    display: inline-block;
    width: 100%;
    text-align: center;
}
