.wrp-widget-block {
}

.widget-block {
    .widget-block__content {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        flex-basis: 100%;

        p {
            &.title {
                width: 100%;
                color: #000000;
                font-size: 24px;
                line-height: 1;
                font-weight: 300;
                margin-bottom: 0;
                position: relative;

                .mg-icon {
                    left: 10px;
                    position: absolute;
                    background-size: contain;
                    transform: translateX(-50%);

                    &.mg-icon__youtube {
                        &::before {
                            width: 68px;
                            height: 60px;
                        }
                    }

                    &.mg-icon__medal {
                        &::before {
                            width: 68px;
                            height: 60px;
                        }
                    }
                }
            }
        }
    }

    .widget-block__cell {
        display: flex;
        width: 100%;
        margin-right: 10px;
        height: 100%;
        position: relative;

        &.widget-block__col {
            flex-wrap: nowrap;
            flex-direction: column;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
