/**
  Переменные для отступов
 */

$offset-1: 8px;
$offset-2: 16px;
$offset-3: 24px;
$offset-4: 32px;
$offset-5: 40px;
$offset-6: 48px;

/**
  Переменные для цветов
 */
$blueL: #4087ec;
$blue: #00419f;
$blueD: #00204e;
$blueD2: #0a0057;
$purpleL: #3d01a1;
$purpleTheme: #0a0057;
$greyL: #343537;
$grey: #212224;
$greyB: #7a7a7a;
$greyB2: #4d4d4d;
$red: #d20b0b;

/**
  Переменные для шрифтов
 */

$font-size-1: 12px;
$font-size-2: 16px;
$font-size-3: 18px;
$font-size-4: 24px;
$font-size-5: 36px;
