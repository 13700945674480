.wrp-header-block__menu {
    position: relative;

    > [class*='col'] {
        position: static;
    }
}

.wrp-main-content {
    padding-top: 116px;
}

.wrp-header-block {
    position: absolute;
    top: 0;
    z-index: 3;
    width: 100%;

    &.affix-menu {
        position: fixed;

        .header-block {
            .header-block__row-1 {
                min-height: 42px;
            }

            .header-block__row-2 {
                min-height: 38px;
            }
        }
    }
}

.header-block {
    background-color: #f1f1f1;

    .header-block__row-1 {
        min-height: 68px;
        transition: all 0.4s;
    }

    .header-block__row-2 {
        min-height: 48px;
    }

    .header-block__row-3 {
        display: none;
    }

    .header-block__item {
        display: flex;
        align-content: center;
        height: 100%;
        align-items: center;
    }

    .header-block__tools {
        display: flex;
        justify-content: flex-end;
    }

    .header-block__tools-item {
        color: #000000;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        flex-shrink: 0;
    }

    .header-block__menu-m {
        ul {
            display: flex;
            flex-wrap: nowrap;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            justify-content: flex-start;
            height: 100%;

            li {
                margin: 0 20px;
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
                align-content: center;

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                a {
                    color: #818181;
                    font-size: 14px;
                    font-weight: 300;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                    text-align: center;

                    &::before {
                    }

                    &:hover {
                        color: $blue;

                        &::before {
                        }
                    }
                }
            }
        }
    }
    .header-block__menu {
        max-height: 48px;
        height: 100%;

        .menu {
            display: flex;
            flex-wrap: nowrap;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
            justify-content: flex-start;
            height: 100%;

            > .menu-item {
                > .sub-menu {
                    > .menu-item {
                        &.wrp-menu-link_image {
                            &:hover {
                                background-color: initial;

                                &::before {
                                    display: none;
                                }
                            }
                        }

                        .menu-link_image {
                            height: 0;
                            padding-bottom: 49%;
                            display: block;

                            position: relative;
                            overflow: hidden;
                            flex-shrink: 1;
                            flex-grow: 0;
                            //width: 100%;
                            background-size: cover;
                            background-position: center;
                            border: none;
                            width: 85%;
                            margin-left: auto;

                            &::before {
                                content: '';
                            }
                        }

                        > .wrp-menu-link_body {
                            //background-color: #f1f1f1;
                            padding: 10px 15px;
                            width: 85%;
                            margin-left: auto;

                            span {
                                color: #636363;
                                font-size: 16px;
                                font-weight: 300;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: normal;
                                text-align: left;
                                margin-bottom: 15px;
                                display: inline-block;
                                cursor: default;
                            }

                            a {
                                color: #00419f;
                                font-size: 16px;
                                font-weight: 300;
                                font-style: normal;
                                letter-spacing: normal;
                                line-height: normal;
                                text-align: left;
                                margin-left: 0;
                                display: inline-block;
                                width: auto;
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .menu-item {
                margin: 0 20px;
                height: 100%;
                display: flex;
                align-items: center;
                align-content: center;

                &.menu-item_relative {
                    .sub-menu {
                    }
                }

                &:hover {
                    .sub-menu {
                        display: flex;
                    }
                }

                &:first-of-type {
                    margin-left: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                a {
                    color: #1f3042;
                    font-size: 18px;
                    font-weight: 300;
                    font-style: normal;
                    letter-spacing: 0.4px;
                    text-align: center;
                    text-decoration: none;
                    position: relative;

                    &::before {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background-color: transparent;
                        position: absolute;
                        bottom: 2px;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &:hover {
                        color: #00419f;

                        &::before {
                            background-image: linear-gradient(to right, #1f79fb 0%, #0a0057 100%);
                        }
                    }
                }

                .sub-menu {
                    position: absolute;
                    display: none;
                    left: 0;
                    top: 100%;
                    width: auto;
                    padding: 23px 30px 10px 15px;
                    list-style: none;
                    background-color: #fff;
                    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
                    order: 1;
                    flex-wrap: nowrap;

                    .menu-item {
                        margin: 0 45px 0 0;
                        width: 100%;
                        max-width: 185px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            color: #0a0057;
                            font-size: 16px;
                            font-weight: 500;
                            padding: 0 0 7px 0;
                            border-bottom: 1px solid #0d66c2;
                            letter-spacing: 0.4px;
                            width: 100%;
                            text-align: left;
                            margin-left: 25px;

                            &::before {
                                content: '';
                                position: static;
                            }

                            &:hover {
                                color: #00419f;

                                &::before {
                                    background-image: none;
                                }
                            }

                            + .sub-menu {
                                display: block;
                                position: static;
                                box-shadow: none;
                                padding: 10px 0 0 0;
                                margin-left: 10px;

                                .menu-item {
                                    display: block;
                                    margin: 0 0 15px;
                                    position: relative;
                                    padding: 5px 0 5px 16px;

                                    &:hover {
                                        background-color: #e8eef6;

                                        &::before {
                                            content: '';
                                            position: absolute;
                                            left: -3px;
                                            opacity: 1;
                                            top: 0;
                                            height: 100%;
                                            width: 3px;
                                            background-color: #00419f;
                                        }
                                    }

                                    &.wrp-menu-link_image {
                                        &:hover {
                                            background-color: initial;

                                            &::before {
                                                display: none;
                                            }
                                        }
                                    }

                                    .menu-link_image {
                                        height: 0;
                                        padding-bottom: 48%;
                                        display: block;

                                        position: relative;
                                        overflow: hidden;
                                        flex-shrink: 1;
                                        flex-grow: 0;
                                        width: 100%;
                                        background-size: cover;
                                        background-position: center;

                                        &::before {
                                            content: '';
                                        }
                                    }

                                    .wrp-menu-link_body {
                                        //background-color: #f1f1f1;
                                        padding: 10px 15px;

                                        span {
                                            color: #636363;
                                            font-size: 16px;
                                            font-weight: 300;
                                            font-style: normal;
                                            letter-spacing: normal;
                                            line-height: normal;
                                            text-align: left;
                                            margin-bottom: 15px;
                                            display: inline-block;
                                            cursor: default;
                                        }

                                        a {
                                            display: block;

                                            color: #00419f;
                                            font-size: 16px;
                                            font-weight: 300;
                                            font-style: normal;
                                            letter-spacing: normal;
                                            line-height: normal;
                                            text-align: left;
                                        }
                                    }

                                    a {
                                        color: #1f3042;
                                        font-size: 16px;
                                        font-weight: 300;
                                        border-bottom: none;
                                        padding: 0;
                                        line-height: 0;
                                        display: inline;
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .header-block__menu-child {
                position: absolute;
                width: 100%;
            }
        }
    }
}
