// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
    $src: null;

    $extmods: (
        eot: '?',
        svg: '#' + str-replace($name, ' ', '_'),
    );

    $formats: (
        otf: 'opentype',
        ttf: 'truetype',
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        font-display: swap;
        src: $src;
    }
}

@mixin object-fit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;
    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
}

@mixin linear-gradient {
    background-image: linear-gradient(253deg, #0a0057 0%, #00419f 43%, #3d01a1 100%);
}

@mixin font-size-block($fSize: 16px, $lHeight: inherit, $fWeight: 400) {
    font-size: $fSize;
    line-height: $lHeight;
    font-weight: $fWeight;
}
