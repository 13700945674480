html,
body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;

    &.modal-is-open {
        overflow: hidden;

        #wpadminbar {
            display: none;
        }
    }
}

.container {
    //max-width: 1380px;
}

a {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.4px;
    margin-bottom: 0;

    &:hover {
        color: $blue;
    }
}

h1 {
    color: #000000;
    font-size: 46px;
    line-height: 48px;
    letter-spacing: 0.4px;
    font-weight: 700;
}

h2 {
    color: #000000;
    font-size: 42px;
    font-weight: 300;

    &.wpdt-c {
        font-size: 24px;
        font-weight: 400;
    }
}

h3,
h4,
h5,
h6 {
    color: #000000;
    font-size: 24px;
    font-weight: 400;
}

p,
li,
td {
    color: $greyB2;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.4px;
    margin-bottom: 0;

    &.title {
        font-size: 24px;
    }

    &.small {
        color: #9a9a9a;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
    }

    &.date {
        color: #4d4d4d;
        font-size: 12px;
        font-weight: 400;
    }
}

ul {
    padding-left: 20px;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: $blue;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}

table {
    width: 100%;
    tr {
        max-width: 100%;
    }
}

.hidden {
    display: none !important;
}

.shadow-block {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
    z-index: 1;
}

.text-red {
    color: $red;
}

.read-more {
    white-space: nowrap;
}

.section-bg-white {
    background-color: #fff;
}

.section-bg-grey {
    background-color: #f8f8f8;
}

.ms-col {
    width: 100%;
}

#m-header {
    display: none;
}
@media screen and (max-width: 991px) {
    #m-header {
        display: block;
    }
}

.wpDataTablesWrapper {
    table {
        &.wpDataTable {
            &.nowrap {
                td {
                    white-space: normal;
                }
            }
        }
    }
}

.swal2-popup {
    width: auto !important;
}
