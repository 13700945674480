.footer-block {
    background-color: $grey;
    padding: 60px 0;

    .footer-block__item {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        > * {
            flex-basis: 100%;
            width: 100%;
        }

        .logo {
            text-align: center;
            margin-bottom: 20px;
        }

        .logo-desc {
            p {
                text-align: center;
                color: #8e8e8e;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 30px;
                line-height: 1.15;
            }
        }

        .partner-list {
            padding: 0 5%;
            .partner-list__content {
                border-top: 1px solid #3b3b3b;
                padding-top: 30px;
            }
        }
    }

    .footer-block__menu {
        padding-left: 14%;
        margin-top: -9px;

        .ms-visible {
            display: block;
        }

        p {
            &.title {
                color: #ffffff;
                font-size: 20px;
                font-weight: 300;
                margin-bottom: 2px;
                letter-spacing: 1.33px;
                padding: 2px 0 2px 10px;
                position: relative;

                &::before {
                    content: '';
                    width: 6px;
                    height: 100%;
                    background-color: $blueL;
                    position: absolute;
                    left: -10px;
                    top: 0;
                }
            }
        }

        ul {
            padding-left: 0;
            margin: 0;
            list-style: none;

            li {
                border-bottom: 1px solid $greyL;
                padding: 14px 0;

                &:last-child {
                    border-bottom: none;
                }
            }

            a {
                display: block;
                color: #b2b2b2;
                padding: 2px 0;
                font-size: 16px;
                font-weight: 300;
            }
        }
    }
}

.footer-block-disclaimer {
    background-color: $greyL;
    padding: 20px 0;

    p {
        color: #888888;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 0;
    }
}
