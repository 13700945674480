.ms-tabs-link {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 30px;
}

.ms-tabs-link a {
    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 20px;
    text-decoration: none;
    outline: none;
}

.ms-tabs-link a.active {
    position: relative;

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -5px;
        height: 5px;
        left: 0;
        background-color: #0099ff;
        width: 100%;
    }
}

.ms-tabs-content {
}

.ms-tabs-content > div {
    display: none;
    padding: 0;
}

.ms-tabs-content > div.active {
    display: block;
}

.ms-tabs-content p.ms-tabs-content_text {
    color: #0e0d12;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 35px;
}

.ms-tabs-content p.title {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}

.ms-tabs-content .ms-tabs-block form {
    max-width: 700px;
    margin: 0 auto;
}

.ms-visible.spoiler-trigger + div {
    display: block;
}

.ms-spoiler-trigger {
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    text-decoration: none;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAMAAABFyS02AAAAAXNSR0IB2cksfwAAADNQTFRFAAAAYGBgZWVlaGhoZWVlZWVlZmZmZmZmZWVlZ2dnZ2dnZmZmZmZmZmZmZmZmZmZmZmZmdm5wIgAAABF0Uk5TABA/IDCw/89gkJ/woO/QUK9g+KnVAAAAT0lEQVR4nFXNSRKAMAhE0cZgG00c7n9aIVYhYdX1Fh/IgnxFgZVbkkoqdvIIUbIJ0H/Tk019hIV4blgSyOVmwjuqbo9JTb/dZvlsFrMenRftQgJMTlIEIQAAAABJRU5ErkJggg==')
        no-repeat 100% 50%;

    &:hover {
        text-decoration: none;
    }
}

.page-block {
    .ms-spoiler-trigger {
        p {
            &.title {
                margin-bottom: 0;
            }
        }
    }
}

.ms-spoiler-trigger.active {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAAkElEQVQoU43SwQ2DMAwF0PwswCrtBozACPTgLOUDHYERGIFRYIH8iopUNBAHX209fVuGM0pEegADyZeqvkujKDUSkPoWdInkQA06IRmweu+7GOPonGs27CrRH5IDJFtVnUXkAWAqQT+kBKRVLOiL1IAahLuAAT0RQlj2Xdd0A+t39uTHGy1bkhFAexfIE5GcPiqYgGooQGNVAAAAAElFTkSuQmCC');
}

.ms-spoiler-trigger > span {
    padding: 0 3px;
}

.ms-spoiler-trigger:hover > span {
    border-bottom-style: solid;
}

.ms-lots .ms-lot:hover .ms-lot__tool .ms-lot-view {
    display: block;
}

.ms-lots .ms-lot .ms-lot__content {
    min-height: initial;
    padding-bottom: 10px;
}

.ms-lots .ms-lot .ms-lot__tool {
    width: 100%;
    position: relative;
}

.ms-lots .ms-lot .ms-lot__tool .ms-lot-view {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #b7b7b7;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    display: none;
}

.ms-lots .ms-lot .ms-lot__tool .ms-lot-view::after {
    content: '';
    display: inline-block;
    height: 14px;
    /*background-image: url("../img/sprite.svg#eye");*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 25px;
    margin-left: 6px;
    position: relative;
    top: 3px;
}

.ms-visible.spoiler-trigger + div {
    display: block;
}

.ms-spoiler-trigger {
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    text-decoration: none;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAMAAABFyS02AAAAAXNSR0IB2cksfwAAADNQTFRFAAAAYGBgZWVlaGhoZWVlZWVlZmZmZmZmZWVlZ2dnZ2dnZmZmZmZmZmZmZmZmZmZmZmZmdm5wIgAAABF0Uk5TABA/IDCw/89gkJ/woO/QUK9g+KnVAAAAT0lEQVR4nFXNSRKAMAhE0cZgG00c7n9aIVYhYdX1Fh/IgnxFgZVbkkoqdvIIUbIJ0H/Tk019hIV4blgSyOVmwjuqbo9JTb/dZvlsFrMenRftQgJMTlIEIQAAAABJRU5ErkJggg==')
        no-repeat 100% 50%;
}

.ms-spoiler-trigger {
    &.active {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAAkElEQVQoU43SwQ2DMAwF0PwswCrtBozACPTgLOUDHYERGIFRYIH8iopUNBAHX209fVuGM0pEegADyZeqvkujKDUSkPoWdInkQA06IRmweu+7GOPonGs27CrRH5IDJFtVnUXkAWAqQT+kBKRVLOiL1IAahLuAAT0RQlj2Xdd0A+t39uTHGy1bkhFAexfIE5GcPiqYgGooQGNVAAAAAElFTkSuQmCC');

        > span {
            border-bottom-style: solid;
        }
    }
}

.ms-spoiler-trigger > span {
    padding: 0 3px;
}

.ms-spoiler-trigger:hover > span {
    border-bottom-style: solid;
}

.ms-visible {
    display: none;
}

.selection_type {
    padding: 10px;
    border: 2px solid #dcdee2;
    color: #1d2945;
    display: table-cell;
    width: 50%;
    margin: 0 15px;
    font-size: 0.9em;
    cursor: pointer;
}
