.page-post {
    position: relative;
    z-index: 1;

    &.page-contact {
        .page-post__header-bg {
            padding-bottom: 30%;

            &::before {
                height: 100%;
                background-image: linear-gradient(252deg, #0a0057 0%, #00419f 73%, #3d01a1 100%);
                opacity: 0.41;
            }
        }

        .page-post__header {
            top: 45%;
            transform: translateY(-50%);
            width: 100%;
        }

        .page-contact__body {
        }

        .page-contact__content {
            position: relative;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
            padding: 66px 0 47px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;

            &::before {
                content: '';
                position: absolute;
                top: -8px;
                width: 100%;
                z-index: 2;
                height: 8px;
                background-image: linear-gradient(270deg, #4087ec 0%, #0a0057 100%);
                left: 0;
            }

            .page-contact__content-base {
                flex-basis: 100%;
                flex-grow: 1;
                flex-shrink: 1;
                width: 100%;
                padding: 0 25px 0 76px;
            }

            .page-contact__content-data {
                padding: 0 35px 35px;
                flex-basis: 56%;
                flex-grow: 1;
                flex-shrink: 1;
                margin-left: 2%;
                border-left: 1px solid #888888;

                .title {
                    color: #0a0057;
                    font-size: 24px;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: normal;
                    line-height: normal;
                }
            }

            .page-contact__content-links {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-content: center;

                .partner-list {
                    max-width: 275px;
                }

                .social-list {
                    text-align: center;
                    .soc-icon {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
    }
}

.contact-links {
    padding-left: 10px;
    .contact-links__list {
        padding-left: 0;
        list-style: none;
    }

    .contact-links__item {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .contact-links__link {
        line-height: 1;
        padding: 3px 0;
        display: flex;
        align-content: center;
        align-items: center;

        color: #0a0057;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;

        .mg-icon {
            margin-right: 15px;
        }
    }
}

.wrp-page-contact__content {
    transform: translateY(-183px);
    background-color: #fff;
}

.social-list {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
