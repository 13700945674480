table {
    &.table_style1 {
        thead {
            tr {
            }

            th {
                background-color: $purpleTheme;
                color: #fff;
                border-right: 1px solid #cccccc;
                font-size: 16px;
                padding: 13px 10px 13px 25px;

                &:last-child {
                    border-right: none;
                }
            }
        }

        tfoot {
            tr {
                &:first-child {
                    display: none;
                }
            }

            th {
                background-color: #fff;
                border-right: 1px solid #cccccc;
                border-top: none;
                font-size: 16px;
                padding: 13px 10px 13px 25px;

                &:last-child {
                    border-right: none;
                }
            }

            input {
                border: none;
                border-bottom: 1px solid #bfbfbf;

                &:focus-visible {
                    outline: none;
                }
            }
        }

        tbody {
            .even {
                td {
                    background-color: #f3f3f3;
                }
            }

            .odd {
            }

            tr {
                td {
                    &:first-child {
                        font-weight: 500;
                        color: #000;
                    }
                }
            }

            td {
                border-right: 1px solid #cccccc;
                font-size: 16px;
                padding: 13px 10px 13px 25px;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }
}

table {
    &.dataTable {
        thead {
            .sorting_asc {
                background-image: url('../img/sort_asc.png');
                background-position: 95% center;
            }

            .sorting {
                background-image: url('../img/sort_both.png');
                background-position: 95% center;
            }

            .sorting_desc {
                background-image: url('../img/sort_desc.png');
                background-position: 95% center;
            }
        }

        a {
            color: #000;
            font-weight: 500;
            text-decoration: underline;
        }
    }
}

.dataTables_wrapper {
    margin-bottom: 15px;

    .dataTable {
        margin-bottom: 10px;
        width: 100% !important;
    }

    .dataTables_inner {
        overflow-x: auto;
        width: 100%;
        position: relative;
    }

    .dataTables_filter {
        color: #fff;
        display: none;
        input {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #d5d5d5;
            color: #000000;
            font-size: 16px;
            background-color: transparent;
            font-weight: 300;
            padding: 6px 0;

            &:focus-visible {
                outline: none;
            }
        }
    }

    .dataTables_info {
        color: #fff;
    }

    .dataTables_length {
        color: #fff !important;

        .select2-container--default {
            width: inherit;
        }

        .select2-container {
            margin: 0 5px;
        }
    }

    .dataTables_paginate {
        .paginate_button {
            color: #fff !important;
            padding: 0.1em 1em;

            &.disabled {
                color: #fff !important;
            }
        }
    }
}
