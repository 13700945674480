.page-video-center {
    h1 {
        display: inline;
        position: relative;
        padding-left: 75px;
        &::after {
            content: '';
            background-image: url('../img/sprite.svg#youtube-border-white');
            background-position: center;
            background-repeat: no-repeat;
            display: block;
            width: 68px;
            height: 60px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
