@charset "UTF-8";
/*!
 * Bootstrap Grid v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.25rem !important; }

.m-n3 {
  margin: -0.25rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.25rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.25rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.25rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.25rem !important; }

.m-n4 {
  margin: -0.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -0.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -0.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -0.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -0.25rem !important; }

.m-n5 {
  margin: -0.25rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -0.25rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -0.25rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -0.25rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -0.25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.25rem !important; }
  .m-sm-n3 {
    margin: -0.25rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.25rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.25rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.25rem !important; }
  .m-sm-n4 {
    margin: -0.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.25rem !important; }
  .m-sm-n5 {
    margin: -0.25rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -0.25rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -0.25rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -0.25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.25rem !important; }
  .m-md-n3 {
    margin: -0.25rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.25rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.25rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.25rem !important; }
  .m-md-n4 {
    margin: -0.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.25rem !important; }
  .m-md-n5 {
    margin: -0.25rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -0.25rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -0.25rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -0.25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.25rem !important; }
  .m-lg-n3 {
    margin: -0.25rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.25rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.25rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.25rem !important; }
  .m-lg-n4 {
    margin: -0.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.25rem !important; }
  .m-lg-n5 {
    margin: -0.25rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -0.25rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -0.25rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -0.25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.25rem !important; }
  .m-xl-n3 {
    margin: -0.25rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.25rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.25rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.25rem !important; }
  .m-xl-n4 {
    margin: -0.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.25rem !important; }
  .m-xl-n5 {
    margin: -0.25rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -0.25rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -0.25rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -0.25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

/**
  Переменные для отступов
 */
/**
  Переменные для цветов
 */
/**
  Переменные для шрифтов
 */
html,
body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden; }
  html.modal-is-open,
  body.modal-is-open {
    overflow: hidden; }
    html.modal-is-open #wpadminbar,
    body.modal-is-open #wpadminbar {
      display: none; }

a {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.4px;
  margin-bottom: 0; }
  a:hover {
    color: #00419f; }

h1 {
  color: #000000;
  font-size: 46px;
  line-height: 48px;
  letter-spacing: 0.4px;
  font-weight: 700; }

h2 {
  color: #000000;
  font-size: 42px;
  font-weight: 300; }
  h2.wpdt-c {
    font-size: 24px;
    font-weight: 400; }

h3,
h4,
h5,
h6 {
  color: #000000;
  font-size: 24px;
  font-weight: 400; }

p,
li,
td {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.4px;
  margin-bottom: 0; }
  p.title,
  li.title,
  td.title {
    font-size: 24px; }
  p.small,
  li.small,
  td.small {
    color: #9a9a9a;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal; }
  p.date,
  li.date,
  td.date {
    color: #4d4d4d;
    font-size: 12px;
    font-weight: 400; }

ul {
  padding-left: 20px; }

img {
  max-width: 100%;
  height: auto; }

a {
  color: #00419f;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal; }

table {
  width: 100%; }
  table tr {
    max-width: 100%; }

.hidden {
  display: none !important; }

.shadow-block {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 1; }

.text-red {
  color: #d20b0b; }

.read-more {
  white-space: nowrap; }

.section-bg-white {
  background-color: #fff; }

.section-bg-grey {
  background-color: #f8f8f8; }

.ms-col {
  width: 100%; }

#m-header {
  display: none; }

@media screen and (max-width: 991px) {
  #m-header {
    display: block; } }

.wpDataTablesWrapper table.wpDataTable.nowrap td {
  white-space: normal; }

.swal2-popup {
  width: auto !important; }

.mg-icon {
  position: relative;
  height: 21px;
  width: 21px;
  display: inline-block; }
  .mg-icon::before {
    content: '';
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  .mg-icon.mg-icon__search::before {
    background-image: url("../img/sprite.svg#search"); }
  .mg-icon.mg-icon__search:hover::before {
    background-image: url("../img/sprite.svg#search-hover"); }
  .mg-icon.mg-icon__profile::before {
    background-image: url("../img/sprite.svg#profile"); }
  .mg-icon.mg-icon__youtube::before {
    background-image: url("../img/sprite.svg#youtube-border"); }
  .mg-icon.mg-icon__medal::before {
    background-image: url("../img/sprite.svg#medal"); }
  .mg-icon.mg-icon__views::before {
    background-image: url("../img/sprite.svg#views"); }
  .mg-icon.mg-icon__subscribes::before {
    background-image: url("../img/sprite.svg#subscribes"); }
  .mg-icon.mg-icon__calendar::before {
    background-image: url("../img/sprite.svg#calendar"); }
  .mg-icon.mg-icon__folder::before {
    background-image: url("../img/sprite.svg#folder"); }
  .mg-icon.mg-icon__skype {
    width: 34px;
    height: 34px; }
    .mg-icon.mg-icon__skype::before {
      background-image: url("../img/sprite.svg#skype-logo"); }
  .mg-icon.mg-icon__whatsapp {
    width: 34px;
    height: 34px; }
    .mg-icon.mg-icon__whatsapp::before {
      background-image: url("../img/sprite.svg#whatsapp-logo"); }
  .mg-icon.mg-icon__eng_phone::before {
    background-image: url("../img/sprite.svg#eng_flag"); }
  .mg-icon.mg-icon__he_phone::before {
    background-image: url("../img/sprite.svg#he_flag"); }
  .mg-icon.mg-icon__email::before {
    background-image: url("../img/sprite.svg#envelop"); }
  .mg-icon.mg-icon__address::before {
    background-image: url("../img/sprite.svg#mark"); }

.soc-icon {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  margin: 0 5px; }
  .soc-icon::before {
    content: '';
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  .soc-icon.soc-icon__instagram::before {
    background-image: url("../img/sprite.svg#instagram"); }
  .soc-icon.soc-icon__instagram:hover::before {
    background-image: url("../img/sprite.svg#instagram-hover"); }
  .soc-icon.soc-icon__instagram.soc-icon-dark::before {
    background-image: url("../img/sprite.svg#instagram-dark"); }
  .soc-icon.soc-icon__instagram.soc-icon-dark:hover::before {
    background-image: url("../img/sprite.svg#instagram-hover"); }
  .soc-icon.soc-icon__youtube::before {
    background-image: url("../img/sprite.svg#youtube"); }
  .soc-icon.soc-icon__youtube:hover::before {
    background-image: url("../img/sprite.svg#youtube-hover"); }
  .soc-icon.soc-icon__youtube.soc-icon-dark::before {
    background-image: url("../img/sprite.svg#youtube-dark"); }
  .soc-icon.soc-icon__youtube.soc-icon-dark:hover::before {
    background-image: url("../img/sprite.svg#youtube-hover"); }
  .soc-icon.soc-icon__facebook::before {
    background-image: url("../img/sprite.svg#facebook"); }
  .soc-icon.soc-icon__facebook.soc-icon-dark::before {
    background-image: url("../img/sprite.svg#facebook-dark"); }
  .soc-icon.soc-icon__twitter-logo::before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z' fill='%2355ACEE'/%3E%3Cpath d='M25.987,9.894c-0.736,0.322-1.525,0.537-2.357,0.635c0.85-0.498,1.5-1.289,1.806-2.231 c-0.792,0.461-1.67,0.797-2.605,0.978C22.083,8.491,21.017,8,19.838,8c-2.266,0-4.1,1.807-4.1,4.038 c0,0.314,0.036,0.625,0.104,0.922c-3.407-0.172-6.429-1.779-8.452-4.221c-0.352,0.597-0.556,1.29-0.556,2.032 c0,1.399,0.726,2.635,1.824,3.36c-0.671-0.022-1.304-0.203-1.856-0.506c-0.001,0.017-0.001,0.034-0.001,0.052 c0,1.955,1.414,3.589,3.29,3.96c-0.343,0.09-0.705,0.142-1.081,0.142c-0.264,0-0.52-0.024-0.77-0.072 c0.52,1.604,2.034,2.771,3.828,2.805C10.67,21.594,8.9,22.24,6.979,22.24c-0.33,0-0.658-0.018-0.979-0.056 c1.814,1.145,3.971,1.813,6.287,1.813c7.541,0,11.666-6.154,11.666-11.491c0-0.173-0.005-0.35-0.012-0.521 C24.741,11.414,25.438,10.703,25.987,9.894z' fill='%23FFFFFF'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }
  .soc-icon.soc-icon__youtube-logo::before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M31.67,9.179c0,0-0.312-2.353-1.271-3.389c-1.217-1.358-2.58-1.366-3.205-1.443C22.717,4,16.002,4,16.002,4 h-0.015c0,0-6.715,0-11.191,0.347C4.171,4.424,2.809,4.432,1.591,5.79C0.633,6.826,0.32,9.179,0.32,9.179S0,11.94,0,14.701v2.588 c0,2.763,0.32,5.523,0.32,5.523s0.312,2.352,1.271,3.386c1.218,1.358,2.815,1.317,3.527,1.459C7.677,27.919,15.995,28,15.995,28 s6.722-0.012,11.199-0.355c0.625-0.08,1.988-0.088,3.205-1.446c0.958-1.034,1.271-3.386,1.271-3.386s0.32-2.761,0.32-5.523v-2.588 C31.99,11.94,31.67,9.179,31.67,9.179z' fill='%23E02F2F'/%3E%3Cpolygon fill='%23FFFFFF' points='12,10 12,22 22,16 '/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }
  .soc-icon.soc-icon__instagram-logo::before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M28,0H4C1.741,0,0,1.741,0,4v24c0,2.258,1.741,4,4,4h24c2.258,0,4-1.742,4-4V4C32,1.741,30.258,0,28,0z M16.023,9.714c3.549,0,6.425,2.787,6.425,6.226c0,3.437-2.876,6.226-6.425,6.226c-3.546,0-6.423-2.789-6.423-6.226 C9.601,12.501,12.478,9.714,16.023,9.714z M28,27c0,0.701-0.3,1-1,1H5c-0.699,0-1-0.299-1-1V13l2.493,0.542 c-0.267,0.87-0.413,1.79-0.413,2.745c0,5.318,4.454,9.634,9.943,9.634c5.492,0,9.944-4.315,9.944-9.634 c0-0.955-0.145-1.875-0.412-2.745L28,13V27z M28,9c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V5c0-0.553,0.447-1,1-1h4 c0.553,0,1,0.447,1,1V9z' fill='%23316699'/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }
  .soc-icon.soc-icon__facebook-logo::before {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z' fill='%233B5998'/%3E%3Cpath d='M22,32V20h4l1-5h-5v-2c0-2,1.002-3,3-3h2V5c-1,0-2.24,0-4,0c-3.675,0-6,2.881-6,7v3h-4v5h4v12H22z' fill='%23FFFFFF' id='f'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }

.bank-icon {
  position: relative;
  padding-left: 24px; }
  .bank-icon::before {
    content: '';
    position: absolute;
    padding-right: 5px;
    left: -17px;
    height: 24px;
    width: 24px;
    background-size: cover;
    display: inline-block;
    line-height: inherit; }
  .bank-icon.bank-icon__home::before {
    background-image: url("../img/sprite.svg#icon-home"); }
  .bank-icon.bank-icon__mark::before {
    background-image: url("../img/sprite.svg#icon-mark"); }
  .bank-icon.bank-icon__country::before {
    background-image: url("../img/sprite.svg#icon-country"); }
  .bank-icon.bank-icon__swift::before {
    background-image: url("../img/sprite.svg#icon-swift"); }
  .bank-icon.bank-icon__link::before {
    left: -41px;
    background-image: url("../img/sprite.svg#icon-link"); }
  .bank-icon.bank-icon__hummer::before, .bank-icon.bank-icon__regulator::before {
    left: -41px;
    background-image: url("../img/sprite.svg#icon-hummer"); }
  .bank-icon.bank-icon__wiki::before, .bank-icon.bank-icon__wikipedia::before {
    left: -41px;
    background-image: url("../img/sprite.svg#icon-wiki"); }
  .bank-icon.bank-icon__linkedin::before {
    left: -41px;
    background-image: url("../img/sprite.svg#linkedin"); }
  .bank-icon.bank-icon__twitter::before {
    left: -41px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z' fill='%233D01A1'/%3E%3Cpath d='M25.987,9.894c-0.736,0.322-1.525,0.537-2.357,0.635c0.85-0.498,1.5-1.289,1.806-2.231 c-0.792,0.461-1.67,0.797-2.605,0.978C22.083,8.491,21.017,8,19.838,8c-2.266,0-4.1,1.807-4.1,4.038 c0,0.314,0.036,0.625,0.104,0.922c-3.407-0.172-6.429-1.779-8.452-4.221c-0.352,0.597-0.556,1.29-0.556,2.032 c0,1.399,0.726,2.635,1.824,3.36c-0.671-0.022-1.304-0.203-1.856-0.506c-0.001,0.017-0.001,0.034-0.001,0.052 c0,1.955,1.414,3.589,3.29,3.96c-0.343,0.09-0.705,0.142-1.081,0.142c-0.264,0-0.52-0.024-0.77-0.072 c0.52,1.604,2.034,2.771,3.828,2.805C10.67,21.594,8.9,22.24,6.979,22.24c-0.33,0-0.658-0.018-0.979-0.056 c1.814,1.145,3.971,1.813,6.287,1.813c7.541,0,11.666-6.154,11.666-11.491c0-0.173-0.005-0.35-0.012-0.521 C24.741,11.414,25.438,10.703,25.987,9.894z' fill='%23FFFFFF'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }
  .bank-icon.bank-icon__youtube::before {
    left: -41px;
    top: 2px;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M31.67,9.179c0,0-0.312-2.353-1.271-3.389c-1.217-1.358-2.58-1.366-3.205-1.443C22.717,4,16.002,4,16.002,4 h-0.015c0,0-6.715,0-11.191,0.347C4.171,4.424,2.809,4.432,1.591,5.79C0.633,6.826,0.32,9.179,0.32,9.179S0,11.94,0,14.701v2.588 c0,2.763,0.32,5.523,0.32,5.523s0.312,2.352,1.271,3.386c1.218,1.358,2.815,1.317,3.527,1.459C7.677,27.919,15.995,28,15.995,28 s6.722-0.012,11.199-0.355c0.625-0.08,1.988-0.088,3.205-1.446c0.958-1.034,1.271-3.386,1.271-3.386s0.32-2.761,0.32-5.523v-2.588 C31.99,11.94,31.67,9.179,31.67,9.179z' fill='%233D01A1'/%3E%3Cpolygon fill='%23FFFFFF' points='12,10 12,22 22,16 '/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }
  .bank-icon.bank-icon__instagram::before {
    left: -41px;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M28,0H4C1.741,0,0,1.741,0,4v24c0,2.258,1.741,4,4,4h24c2.258,0,4-1.742,4-4V4C32,1.741,30.258,0,28,0z M16.023,9.714c3.549,0,6.425,2.787,6.425,6.226c0,3.437-2.876,6.226-6.425,6.226c-3.546,0-6.423-2.789-6.423-6.226 C9.601,12.501,12.478,9.714,16.023,9.714z M28,27c0,0.701-0.3,1-1,1H5c-0.699,0-1-0.299-1-1V13l2.493,0.542 c-0.267,0.87-0.413,1.79-0.413,2.745c0,5.318,4.454,9.634,9.943,9.634c5.492,0,9.944-4.315,9.944-9.634 c0-0.955-0.145-1.875-0.412-2.745L28,13V27z M28,9c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V5c0-0.553,0.447-1,1-1h4 c0.553,0,1,0.447,1,1V9z' fill='%233D01A1'/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }
  .bank-icon.bank-icon__facebook::before {
    left: -41px;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg enable-background='new 0 0 32 32' height='32px' id='Layer_1' version='1.0' viewBox='0 0 32 32' width='32px' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cpath d='M32,30c0,1.104-0.896,2-2,2H2c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2h28c1.104,0,2,0.896,2,2V30z' fill='%233D01A1'/%3E%3Cpath d='M22,32V20h4l1-5h-5v-2c0-2,1.002-3,3-3h2V5c-1,0-2.24,0-4,0c-3.675,0-6,2.881-6,7v3h-4v5h4v12H22z' fill='%23FFFFFF' id='f'/%3E%3C/g%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3Cg/%3E%3C/svg%3E"); }

.title.title-icon {
  padding-left: 75px;
  position: relative; }
  .title.title-icon.title-icon__youtube::before {
    content: ''; }

.brand-logo {
  height: 115px;
  width: 115px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: border-box;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-image: linear-gradient(-45deg, #0452c2 0%, #3d01a1 100%); }
  .brand-logo::before {
    content: '';
    background-image: url("../img/sprite.svg#brand");
    display: inline-block;
    height: 45px;
    width: 45px;
    position: absolute;
    top: 5px;
    right: 5px; }
  .brand-logo p {
    opacity: 0.44;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 5.73px;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 0;
    transform: rotate(45deg);
    transform-origin: 25px center;
    width: 150%;
    text-align: center; }

.bg-white {
  background-color: #fff !important; }

.btn,
.mg-btn {
  display: inline-block;
  border: none;
  color: #4087ec;
  font-size: 16px;
  font-weight: 300;
  background-color: #ffffff;
  vertical-align: middle;
  border-radius: 3px;
  text-align: center;
  user-select: none;
  padding: 0.375rem 1.4rem;
  outline: none;
  transition: all 0.15s ease-in; }
  .btn:hover,
  .mg-btn:hover {
    text-decoration: none; }
  .btn.btn-big,
  .mg-btn.btn-big {
    padding: 1.275rem 3.3rem; }
  .btn.btn-w100,
  .mg-btn.btn-w100 {
    width: 100%; }
  .btn.btn-default,
  .mg-btn.btn-default {
    border: 1px solid #00419f; }
    .btn.btn-default:hover,
    .mg-btn.btn-default:hover {
      background-color: #002c6c;
      color: #fff; }
    .btn.btn-default.btn-default_red,
    .mg-btn.btn-default.btn-default_red {
      border-color: #d20b0b;
      color: #d20b0b; }
      .btn.btn-default.btn-default_red:hover,
      .mg-btn.btn-default.btn-default_red:hover {
        background-color: #d20b0b;
        color: #fff; }
  .btn.btn-primary,
  .mg-btn.btn-primary {
    background-color: #00419f;
    color: #fff; }
    .btn.btn-primary.btn-strict,
    .mg-btn.btn-primary.btn-strict {
      border-radius: 0; }
    .btn.btn-primary:hover,
    .mg-btn.btn-primary:hover {
      text-decoration: none;
      background-color: #002c6c; }
  .btn.btn-darkness,
  .mg-btn.btn-darkness {
    background-color: #0a0057;
    color: #fff; }
    .btn.btn-darkness.btn-strict,
    .mg-btn.btn-darkness.btn-strict {
      border-radius: 0; }
    .btn.btn-darkness:hover,
    .mg-btn.btn-darkness:hover {
      text-decoration: none;
      background-color: #040024; }
  .btn.btn-danger,
  .mg-btn.btn-danger {
    border-radius: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 300;
    background-color: #d20b0b; }
    .btn.btn-danger:hover,
    .mg-btn.btn-danger:hover {
      background-color: #a20808; }
  .btn.btn-search,
  .mg-btn.btn-search {
    position: relative; }
    .btn.btn-search::before,
    .mg-btn.btn-search::before {
      content: '';
      display: inline-block;
      height: 23px;
      width: 23px;
      background-image: url("../img/sprite.svg#search");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      line-height: 1; }
    .btn.btn-search:hover::before,
    .mg-btn.btn-search:hover::before {
      background-image: url("../img/sprite.svg#search-hover"); }
  .btn.btn-filter,
  .mg-btn.btn-filter {
    background: #f6f6f6 0 0 no-repeat padding-box;
    border: 1px solid #00419f;
    border-radius: 3px;
    opacity: 1;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0;
    color: #000000;
    padding: 0.565rem 1.15rem;
    display: flex;
    align-items: center; }
    .btn.btn-filter::before,
    .mg-btn.btn-filter::before {
      content: '';
      display: inline-block;
      height: 22px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.334' height='22.711' viewBox='0 0 24.334 22.711'%3E%3Cg id='Group_344' data-name='Group 344' transform='translate(-540 -354)'%3E%3Cpath id='Path_466' data-name='Path 466' d='M543.238,363.359c0-.011.006-.021.006-.032v-8.922a.406.406,0,1,0-.811,0v8.922c0,.011.006.021.006.032a2.835,2.835,0,0,0,0,5.615c0,.011-.006.021-.006.032v7.3a.406.406,0,1,0,.811,0v-7.3c0-.011-.006-.021-.006-.032a2.835,2.835,0,0,0,0-5.615Zm-.4,4.835a2.028,2.028,0,1,1,2.028-2.028A2.03,2.03,0,0,1,542.839,368.195Z' fill='%23424242'/%3E%3Cpath id='Path_467' data-name='Path 467' d='M566.238,368.632c0-.011.006-.021.006-.032V354.406a.406.406,0,1,0-.811,0V368.6c0,.011.006.021.006.032a2.835,2.835,0,0,0,0,5.615c0,.011-.006.021-.006.032v2.028a.406.406,0,1,0,.811,0v-2.028c0-.011-.006-.021-.006-.032a2.835,2.835,0,0,0,0-5.615Zm-.4,4.835a2.028,2.028,0,1,1,2.028-2.028A2.03,2.03,0,0,1,565.839,373.467Z' transform='translate(-13.672)' fill='%23424242'/%3E%3Cpath id='Path_468' data-name='Path 468' d='M591.678,360.083a2.841,2.841,0,0,0-2.44-2.807c0-.011.007-.021.007-.032v-2.839a.406.406,0,0,0-.811,0v2.839c0,.011.006.021.006.032a2.835,2.835,0,0,0,0,5.615c0,.011-.006.021-.006.032v13.383a.406.406,0,0,0,.811,0V362.922c0-.011-.006-.021-.007-.032A2.841,2.841,0,0,0,591.678,360.083Zm-2.839,2.028a2.028,2.028,0,1,1,2.028-2.028A2.03,2.03,0,0,1,588.839,362.111Z' transform='translate(-27.344)' fill='%23424242'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      line-height: 1;
      margin-right: 9px; }
  .btn.btn-button,
  .mg-btn.btn-button {
    height: 62px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 38px;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative; }
    .btn.btn-button:hover,
    .mg-btn.btn-button:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16); }
    .btn.btn-button.btn-button_icon,
    .mg-btn.btn-button.btn-button_icon {
      padding-left: 65px; }
    .btn.btn-button .mg-icon,
    .mg-btn.btn-button .mg-icon {
      position: absolute;
      left: 25px; }
  .btn.btn-w1oo,
  .mg-btn.btn-w1oo {
    width: 100%; }
  .btn.btn-w0,
  .mg-btn.btn-w0 {
    width: auto; }
  .btn[disabled],
  .mg-btn[disabled] {
    opacity: 0.4; }

.row-page-block {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 0;
  margin-bottom: 30px; }
  .row-page-block .page-block__body {
    width: 100%;
    margin-bottom: 0;
    display: flex; }
  .row-page-block .main-content {
    position: relative;
    flex-basis: 100%;
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1; }
  .row-page-block .bank-list .mg-loader::before {
    top: 20%; }
  .row-page-block .sidebar {
    flex-basis: 43%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 2%; }
    .row-page-block .sidebar:before {
      display: none; }

.flex-row-reverse .sidebar {
  margin-left: 0;
  padding-right: 1.5%;
  border-right: 1px solid #c7c7c7;
  position: relative;
  padding-left: 0; }
  .flex-row-reverse .sidebar::after {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    width: 98px;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    opacity: 0.8;
    right: 0;
    top: 0;
    pointer-events: none; }
  .flex-row-reverse .sidebar .ms-spoiler-trigger {
    max-width: 400px;
    margin: 0 auto; }

.flex-row-reverse .bank-list {
  padding-right: 0;
  padding-left: 1.5%; }

.main-content.main-content__additional.text-center .title {
  text-align: center; }

.main-content.main-content__additional p {
  color: #7a7a7a; }
  .main-content.main-content__additional p.title {
    color: #000;
    margin-bottom: 10px; }

.main-content .pagination .screen-reader-text {
  display: none; }

.main-content .pagination .nav-links {
  display: flex;
  justify-content: center; }

.page-block.page-404 .page-404__content {
  justify-content: center; }
  .page-block.page-404 .page-404__content > * {
    flex-basis: 100%;
    text-align: center; }

.page-block .page-block__header {
  padding: 0 0 10px 0;
  text-align: center;
  flex-basis: 100%; }

.page-block p {
  margin-bottom: 0; }
  .page-block p.title {
    margin-bottom: 18px; }

.page-block .page-block__body {
  width: 100%;
  margin: 0 auto; }

.bg-gradient {
  position: relative;
  z-index: 0; }
  .bg-gradient::before {
    content: '';
    background-image: linear-gradient(253deg, #0a0057 0%, #00419f 43%, #3d01a1 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
    z-index: 2;
    opacity: 0.73; }
  .bg-gradient .bg-gradient__content {
    position: relative;
    z-index: 3; }

.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat; }

.breadcrumbs {
  width: 100%; }
  .breadcrumbs.breadcrumbs_block {
    padding: 15px 0; }
    .breadcrumbs.breadcrumbs_block .breadcrumbs__item,
    .breadcrumbs.breadcrumbs_block .breadcrumbs__link {
      font-size: 14px;
      line-height: 17px; }
  .breadcrumbs .breadcrumbs__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: block; }
  .breadcrumbs .breadcrumbs__item {
    font-size: 16px;
    font-weight: 300;
    margin-right: 5px;
    padding: 2px 0;
    display: inline; }
    .breadcrumbs .breadcrumbs__item::after {
      content: '>';
      display: inline;
      color: #bebebe;
      position: relative;
      left: 5px; }
    .breadcrumbs .breadcrumbs__item:last-child {
      margin-right: 0; }
      .breadcrumbs .breadcrumbs__item:last-child::after {
        content: ''; }
  .breadcrumbs .breadcrumbs__link {
    font-size: 16px;
    font-weight: 300;
    color: #bebebe; }
  .breadcrumbs span.breadcrumbs__link {
    color: #4087ec; }

.table-details {
  background-color: #fff;
  border: 1px solid #cfcfcf;
  width: 100%; }
  .table-details tr {
    min-height: 60px; }
    .table-details tr:nth-child(even) {
      background-color: #f2f2f2; }
    .table-details tr th {
      color: #0a0057;
      font-size: 16px;
      font-weight: 500;
      border-right: 1px solid #cfcfcf;
      background-color: #f2f2f2;
      padding: 20px 40px;
      vertical-align: middle; }
      .table-details tr th:first-child {
        white-space: nowrap; }
      .table-details tr th:last-child {
        border-right: none; }
    .table-details tr td {
      vertical-align: middle;
      padding: 5px 40px;
      color: #000000;
      font-size: 16px;
      font-weight: 300;
      border-right: 1px solid #cfcfcf; }
      .table-details tr td:first-child {
        padding: 20px 40px; }
      .table-details tr td:last-child {
        border-right: none; }

.mCSB_scrollTools {
  width: 7px;
  background-color: #dadada; }
  .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #0099ff; }
  .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #0099ff;
    width: 100%;
    border-radius: 0; }
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #0099ff;
    width: 100%;
    border-radius: 0; }
  .mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent; }

.mCSB_inside > .mCSB_container {
  margin-right: 16px; }

.text-center {
  text-align: center; }

.w100 {
  width: 100%; }

.news-tag {
  background-color: #4087ec;
  padding: 7px 15px;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 10px;
  font-weight: 400; }
  .news-tag:hover {
    color: #fff; }

.posts-navigate {
  display: flex;
  flex-wrap: nowrap; }
  .posts-navigate .posts-navigate__item {
    width: 42px;
    height: 42px;
    background-color: #e7e6ee;
    position: relative;
    display: inline-block;
    color: transparent;
    line-height: 42px;
    text-align: center; }
    .posts-navigate .posts-navigate__item::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-size: 50%;
      width: 100%;
      display: inline-block;
      background-image: url("../img/sprite.svg#arrow-navigate");
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.2s; }
    .posts-navigate .posts-navigate__item:hover::before {
      transform: scale(1.2); }
  .posts-navigate .posts-navigate__prev {
    margin-right: 7px; }
    .posts-navigate .posts-navigate__prev::before {
      background-image: url("../img/sprite.svg#arrow-navigate-prev"); }

.lg-visible,
.xl-visible {
  display: none; }

.wrp-br-header__hamburger {
  height: 100%; }

.br-header__hamburger {
  height: 30px;
  margin-right: 0;
  min-width: 25px;
  width: 1.55555556rem;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  position: relative; }

.br-header__hamburger__bars {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.br-header__hamburger.--active .br-header__hamburger__bars {
  background: transparent; }

.br-header__hamburger__bars,
.br-header__hamburger__bars::after,
.br-header__hamburger__bars::before {
  height: 0.11111111rem;
  background-color: #151515;
  position: relative;
  transition: top 0.1s 0.1s, transform 0.1s, background 0s 0.1s; }

.br-header__hamburger.--active .br-header__hamburger__bars::after,
.br-header__hamburger.--active .br-header__hamburger__bars::before {
  width: 1rem;
  top: 0;
  transition: top 0.1s, transform 0.1s 0.1s; }

.br-header__hamburger.--active .br-header__hamburger__bars::before {
  transform: rotate(45deg); }

.br-header__hamburger.--active .br-header__hamburger__bars::after {
  transform: rotate(-45deg); }

.br-header__hamburger__bars::after,
.br-header__hamburger__bars::before {
  content: '';
  display: block;
  position: absolute; }

.br-header__hamburger__bars::before {
  width: 1rem;
  top: -0.33333333rem; }

.br-header__hamburger__bars::after {
  width: 0.66666667rem;
  top: 0.33333333rem; }

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.mg-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999; }
  .mg-loader::before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    top: 50%;
    border: 8px solid rgba(255, 255, 255, 0);
    border-top: 8px solid #003f91;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    position: absolute;
    left: calc(50% - 25px);
    transform: translateX(-50%); }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.nav-links .page-numbers {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  *cursor: hand;
  color: #333;
  border: 1px solid transparent;
  border-radius: 2px; }
  .nav-links .page-numbers:hover {
    color: white;
    border: 1px solid #111;
    background-color: #585858;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
    background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
    background: -moz-linear-gradient(top, #585858 0%, #111 100%);
    background: -ms-linear-gradient(top, #585858 0%, #111 100%);
    background: -o-linear-gradient(top, #585858 0%, #111 100%);
    background: linear-gradient(to bottom, #585858 0%, #111 100%); }

.nav-links .current {
  color: #333;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
  background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  background: linear-gradient(to bottom, white 0%, #dcdcdc 100%); }
  .nav-links .current:hover {
    cursor: default;
    color: #333;
    border: 1px solid #979797;
    background-color: white;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
    background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
    background: linear-gradient(to bottom, white 0%, #dcdcdc 100%); }

.h100 {
  height: 100%; }

.inner-w-100::before {
  content: ''; }

.mm-wrapper__blocker.mm-slideout .mm-sronly {
  height: 50px !important;
  width: 50px !important;
  left: 15px !important;
  top: 10px !important;
  z-index: 9;
  clip-path: none !important;
  clip: initial !important;
  line-height: 1;
  text-align: center; }
  .mm-wrapper__blocker.mm-slideout .mm-sronly::before {
    content: '×';
    font-style: inherit;
    color: #fff;
    display: block;
    line-height: 1;
    font-size: 50px;
    font-weight: 400;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out; }
  .mm-wrapper__blocker.mm-slideout .mm-sronly:hover::before {
    transform: scale(1.2); }

.grecaptcha-badge {
  z-index: 3; }

.vc_toggle_title h4 {
  font-size: 16px;
  font-weight: 400; }

.dataTables_inner .mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 6px; }

.mg-inline-block {
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.22);
  margin: 10px 0;
  background-color: #f3f3f3; }
  .mg-inline-block form .form-group .form-group__inner {
    flex-basis: initial;
    margin: 0 5px; }
    .mg-inline-block form .form-group .form-group__inner .form-control {
      border-bottom: 1px solid #d5d5d5; }
      .mg-inline-block form .form-group .form-group__inner .form-control.wpcf7-checkbox {
        border-bottom: none; }

form {
  width: 100%; }
  form.form-type2 .form-group {
    margin-bottom: 19px; }
    form.form-type2 .form-group .form-group__inner {
      min-height: initial; }
      form.form-type2 .form-group .form-group__inner .form-control {
        border-bottom: 1px solid #d5d5d5;
        color: #000000;
        font-size: 16px;
        background-color: transparent;
        font-weight: 300;
        padding: 6px 0; }
        form.form-type2 .form-group .form-group__inner .form-control.wpcf7-checkbox {
          border-bottom: none; }
      form.form-type2 .form-group .form-group__inner .select2-container--default .select2-selection.select2-selection--single {
        border: none;
        border-bottom: 1px solid #d5d5d5;
        padding: 6px 15px 6px 0; }
        form.form-type2 .form-group .form-group__inner .select2-container--default .select2-selection.select2-selection--single .select2-selection__arrow b {
          right: 2%;
          width: 18px;
          height: 10px; }
  form.form-type2 .select2-container--default .select2-selection.select2-selection--single {
    background-color: transparent; }
  form.form-compact .form-group {
    margin-bottom: 39px; }
    form.form-compact .form-group .form-group__inner .form-control {
      min-height: 38px; }
  form.form-compact .select2-container--default {
    min-height: 38px; }
  form.form-type-filter .form-group {
    margin-bottom: 30px; }
    form.form-type-filter .form-group .form-group__checkboxes {
      max-height: 179px;
      display: block;
      overflow: hidden;
      width: 100%; }
    form.form-type-filter .form-group .form-group__inner {
      margin: 0; }
      form.form-type-filter .form-group .form-group__inner.form-group__search {
        position: relative;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
        margin-bottom: 12px; }
        form.form-type-filter .form-group .form-group__inner.form-group__search .btn-icon {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%); }
      form.form-type-filter .form-group .form-group__inner.form-group__checkbox {
        min-height: 45px;
        border-bottom: 1px solid #dbdbdb;
        position: relative;
        align-items: center;
        align-content: center; }
        form.form-type-filter .form-group .form-group__inner.form-group__checkbox .form-control__count {
          position: absolute;
          right: 0;
          border-radius: 25px;
          background-color: #ffffff;
          z-index: 0;
          display: table-cell;
          padding: 5px 15px;
          top: 50%;
          transform: translateY(-50%);
          min-width: 64px;
          text-align: center; }
        form.form-type-filter .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label {
          margin-bottom: 0; }
          form.form-type-filter .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label::before {
            background-color: #0a0057;
            border-color: #dbdbdb;
            border-width: 2px; }
          form.form-type-filter .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label::after {
            left: 8px;
            top: 9px; }
        form.form-type-filter .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label {
          margin-bottom: 0;
          padding-left: 30px;
          color: #000000;
          font-size: 18px;
          font-weight: 400; }
          form.form-type-filter .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label::before {
            border-radius: 3px;
            border: 1px solid #cbcbcb;
            width: 20px;
            height: 20px;
            top: 4px;
            background-color: #fff; }
  form .form-group {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    box-shadow: none;
    outline: none; }
    form .form-group:last-child {
      margin-bottom: 0; }
    form .form-group.form-group__inline {
      display: flex;
      flex-wrap: nowrap; }
      form .form-group.form-group__inline:last-child {
        margin-bottom: 0; }
      form .form-group.form-group__inline .form-group .form-group__inner {
        margin: 0 5% 0 0; }
      form .form-group.form-group__inline > .btn,
      form .form-group.form-group__inline .mg-btn {
        margin-left: 5px; }
    form .form-group .form-group__tools {
      width: 100%;
      margin-bottom: 5px; }
    form .form-group .title {
      color: #0a0057;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0; }
    form .form-group .form-group__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 25%;
      margin: 0 25px; }
      form .form-group .form-group__inner > .form-group {
        margin-bottom: 5px; }
      form .form-group .form-group__inner .wpcf7-form-control-wrap {
        margin-bottom: 0; }
      form .form-group .form-group__inner .form-control {
        min-height: 60px; }
      form .form-group .form-group__inner label {
        margin-bottom: 0; }
      form .form-group .form-group__inner span.wpcf7-list-item {
        margin-left: 0; }
      form .form-group .form-group__inner:first-child {
        margin-left: 0; }
      form .form-group .form-group__inner:last-child {
        margin-right: 0; }
      form .form-group .form-group__inner.form-group__btn {
        flex-shrink: 0;
        flex-basis: 0; }
        form .form-group .form-group__inner.form-group__btn .btn,
        form .form-group .form-group__inner.form-group__btn .mg-btn {
          max-height: 60px;
          height: 100%; }
      form .form-group .form-group__inner.form-group__inner-search {
        border: 1px solid #212224;
        position: relative; }
        form .form-group .form-group__inner.form-group__inner-search .btn-search {
          position: absolute;
          right: 1px;
          top: 50%;
          transform: translateY(-50%);
          line-height: 1;
          background-color: transparent; }
    form .form-group .form-group__label {
      width: 100%;
      color: #00419f;
      opacity: 0.5;
      margin-bottom: 0.5rem;
      font-size: 18px;
      line-height: inherit;
      font-weight: 400; }
      form .form-group .form-group__label.text-smaller {
        font-size: 14px;
        line-height: inherit;
        font-weight: 400; }
    form .form-group.datapicker-elem:after {
      content: '';
      display: inline-block;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      width: 23px;
      height: 25px;
      right: 22px;
      bottom: 17px;
      pointer-events: none; }
    form .form-group .text-error {
      display: block;
      color: red;
      font-size: 13px;
      margin-top: 5px; }
    form .form-group .form-inner-group {
      position: relative;
      width: 100%; }
    form .form-group .tooltip {
      right: -35px; }
    form .form-group .form-control {
      position: relative;
      width: 100%;
      padding: 0 20px;
      height: 100%;
      border: none;
      background-color: #fff;
      outline: none;
      box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
      box-sizing: border-box; }
    form .form-group .form-control-text {
      margin-bottom: 0;
      display: flex;
      align-content: flex-end;
      align-items: flex-end;
      padding-bottom: 10px;
      font-size: 18px; }
    form .form-group textarea.form-control {
      height: auto; }
    form .form-group input[type='submit'] {
      width: 100%; }
    form .form-group .form-group__inner.form-group__checkbox {
      align-items: flex-start;
      align-content: flex-start;
      min-height: 30px; }
      form .form-group .form-group__inner.form-group__checkbox .wpcf7-form-control.wpcf7-checkbox {
        cursor: pointer;
        background-color: transparent;
        color: #dedede;
        font-size: 12px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: left;
        padding-left: 0; }
      form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] {
        display: none; }
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label,
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + span {
          padding-left: 23px;
          position: relative;
          color: #9a9a9a;
          font-size: 12px;
          font-weight: 300;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 0;
          line-height: 1.5; }
          form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label.text-smaller:before,
          form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + span.text-smaller:before {
            top: -3px; }
          form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label::before,
          form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + span::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            vertical-align: middle;
            height: 15px;
            width: 15px;
            border: 2px solid #bfbfbf;
            box-sizing: border-box;
            margin-right: 10px;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            opacity: 1; }
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label.text-smaller:after,
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + span.text-smaller:after {
          content: '';
          left: 8px;
          top: 6px;
          width: 4px;
          height: 6px; }
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label::before,
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + span::before {
          opacity: 1;
          background-color: #00419f;
          border: solid #00419f; }
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label:after,
        form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + span:after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 6px;
          top: 3px;
          width: 4px;
          height: 8px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg); }
      form .form-group .form-group__inner.form-group__checkbox .wpcf7-list-item-label {
        position: relative;
        margin-bottom: 0; }
        form .form-group .form-group__inner.form-group__checkbox .wpcf7-list-item-label::before {
          content: '';
          display: inline-block;
          position: relative;
          vertical-align: middle;
          height: 15px;
          width: 15px;
          border: 2px solid #bfbfbf;
          box-sizing: border-box;
          margin-right: 10px;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
          opacity: 1;
          top: -2px; }
    form .form-group .form-group__inner.form-group__radio [type='radio'] {
      display: none; }
      form .form-group .form-group__inner.form-group__radio [type='radio']:checked + label::before,
      form .form-group .form-group__inner.form-group__radio [type='radio']:checked + span::before {
        opacity: 1; }
      form .form-group .form-group__inner.form-group__radio [type='radio']:checked + label:after,
      form .form-group .form-group__inner.form-group__radio [type='radio']:checked + span:after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 8px;
        top: 8px;
        width: 4px;
        height: 8px;
        border: solid #000;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg); }
    form .form-group .form-group__inner.form-group__radio .wpcf7-list-item-label {
      position: relative; }
      form .form-group .form-group__inner.form-group__radio .wpcf7-list-item-label::before {
        content: '';
        display: inline-block;
        position: relative;
        vertical-align: middle;
        height: 15px;
        width: 15px;
        border: 2px solid #00419f;
        box-sizing: border-box;
        margin-right: 10px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        border-radius: 50%;
        opacity: 1;
        top: -2px; }
    form .form-group .form-group__inner.form-group__file [type='file'] {
      display: none; }
      form .form-group .form-group__inner.form-group__file [type='file'] + label {
        cursor: pointer;
        width: auto;
        padding: 0 20px;
        border-radius: 30px 30px;
        background-color: #00419f;
        opacity: 1;
        color: #fff; }
    form .form-group .form-group__inner .wpcf7-form-control-wrap {
      width: 100% !important; }
  form .form-group-select {
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 20px;
    box-shadow: none;
    outline: none; }

.clear-all {
  color: #d20b0b;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  padding-left: 25px;
  line-height: 1;
  display: block; }
  .clear-all::before {
    content: '';
    display: block;
    width: 21px;
    height: 22px;
    background-image: url("../img/sprite.svg#clear");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: -4px; }

.form-file {
  position: relative;
  display: block;
  flex-wrap: wrap; }
  .form-file .form-control_file,
  .form-file .form-file__label {
    width: 100%;
    display: block; }

.wrp-form {
  padding: 15px; }

.footnote-lg-right {
  position: absolute;
  right: -50%; }

form[role='search'] .form-group {
  height: 60px; }
  form[role='search'] .form-group input[type='text'] {
    padding-left: 65px;
    color: #00419f;
    border: 1px solid #00419f;
    background-color: transparent; }
    form[role='search'] .form-group input[type='text']::after {
      content: '';
      position: absolute;
      display: inline-block;
      background-image: url("../img/search.svg");
      left: 0;
      margin: auto 0;
      top: 0;
      bottom: 0;
      padding-right: 20px;
      padding-left: 20px;
      width: 25px;
      height: 25px; }
  form[role='search'] .form-group input[type='submit'] {
    height: 100%;
    width: 100%; }

form[role='search'] .search-input {
  position: relative; }
  form[role='search'] .search-input .icon {
    position: absolute;
    left: 20px;
    width: 30px;
    height: 30px; }
    form[role='search'] .search-input .icon:before {
      left: 0; }

.modal__content form {
  width: 100%; }
  .modal__content form.type-2 .form-group {
    padding: 0;
    overflow: hidden; }

.wpcf7 .wpcf7-form .wpcf7-response-output {
  margin: 0; }

.bg-gradient .wpcf7-form .wpcf7-response-output,
.block-widget-single-form .wpcf7-form .wpcf7-response-output {
  color: #fff; }

.select2-container--default {
  min-height: 60px;
  width: 100% !important; }
  .select2-container--default.select2 {
    height: 100%; }
  .select2-container--default.select2-container .select2-selection--multiple .select2-selection__rendered {
    margin-bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    padding-right: 5px;
    position: relative; }
    .select2-container--default.select2-container .select2-selection--multiple .select2-selection__rendered.select2-selection__load-more::after {
      content: '...';
      display: inline-block;
      color: #000;
      padding-left: 5px; }
    .select2-container--default.select2-container .select2-selection--multiple .select2-selection__rendered .select2-selection__choice:nth-child(n + 3) {
      display: none; }
  .select2-container--default.select2-container--open .dropdown-wrapper::before {
    transform: rotate(-180deg); }
  .select2-container--default .dropdown-wrapper {
    width: 13px;
    height: 7px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%); }
    .select2-container--default .dropdown-wrapper::before {
      content: '';
      background-image: url("../img/sprite.svg#drop-arrow");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      width: 100%;
      height: 100%;
      display: block; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);
    right: 15px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: initial;
      border-style: inherit; }
  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: inherit; }
  .select2-container--default .select2-selection--multiple {
    padding: 0;
    border-radius: 0;
    border: 0;
    display: flex;
    align-items: center; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-top: 0;
      display: flex;
      flex-wrap: nowrap; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
      color: #000000;
      font-size: 16px;
      font-weight: 300; }
  .select2-container--default .select2-search--inline .select2-search__field {
    margin: 0; }
  .select2-container--default .select2-selection {
    position: relative;
    height: 100%;
    padding-left: 14px; }
    .select2-container--default .select2-selection.select2-selection--single {
      text-align: left;
      display: flex;
      height: 100%;
      align-content: center;
      align-items: center;
      background-color: #fff;
      color: #000;
      font-size: 16px;
      font-weight: 300;
      padding: 0 15px 0 25px;
      overflow: hidden;
      border-radius: 0; }
      .select2-container--default .select2-selection.select2-selection--single .select2-selection__rendered {
        padding: 0;
        white-space: normal; }
      .select2-container--default .select2-selection.select2-selection--single .select2-selection__arrow b {
        width: 13px;
        height: 7px;
        position: absolute;
        right: 8%;
        top: 50%;
        transform: translateY(-50%); }
        .select2-container--default .select2-selection.select2-selection--single .select2-selection__arrow b::before {
          content: '';
          display: none;
          background-image: url("../img/sprite.svg#drop-arrow");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
          width: 100%;
          height: 100%; }
  .select2-container--default .select2-dropdown {
    border: none;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
    border-radius: 0; }
    .select2-container--default .select2-dropdown .select2-search__field {
      border: 1px solid #424242; }
  .select2-container--default .select2-results__option--selectable {
    padding-left: 20px; }
  .select2-container--default .select2-results__option--selected {
    position: relative; }
    .select2-container--default .select2-results__option--selected:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 8px;
      top: 12px;
      width: 4px;
      height: 8px;
      border: solid #00419f;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }

.select2-container--dataTable .select2-selection--single .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  position: absolute; }
  .select2-container--dataTable .select2-selection--single .select2-selection__arrow b {
    border-color: initial;
    border-style: inherit; }

.select2-container--dataTable.select2-container--focus .select2-selection--multiple {
  border: inherit; }

.select2-container--dataTable .select2-selection--multiple {
  padding: 0;
  border-radius: 0;
  border: 0;
  display: flex;
  align-items: center; }
  .select2-container--dataTable .select2-selection--multiple .select2-selection__choice {
    margin-top: 0;
    display: flex;
    flex-wrap: nowrap; }
  .select2-container--dataTable .select2-selection--multiple .select2-selection__choice__display {
    color: #000000;
    font-size: 16px;
    font-weight: 300; }

.select2-container--dataTable .select2-search--inline .select2-search__field {
  margin: 0; }

.select2-container--dataTable .select2-selection {
  position: relative;
  height: 100%;
  padding-left: 14px; }
  .select2-container--dataTable .select2-selection.select2-selection--single {
    text-align: left;
    display: flex;
    height: 100%;
    align-content: center;
    align-items: center;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    padding: 0 15px 0 10px;
    overflow: hidden;
    border-radius: 0; }
    .select2-container--dataTable .select2-selection.select2-selection--single .select2-selection__rendered {
      padding: 0;
      white-space: normal; }
    .select2-container--dataTable .select2-selection.select2-selection--single .select2-selection__arrow b {
      width: 13px;
      height: 7px;
      position: absolute;
      right: 8%;
      top: 50%;
      transform: translateY(-50%); }
      .select2-container--dataTable .select2-selection.select2-selection--single .select2-selection__arrow b::before {
        content: '';
        background-image: url("../img/sprite.svg#drop-arrow");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 100%;
        height: 100%;
        display: block; }

.select2-container--dataTable .select2-dropdown {
  border: none;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
  border-radius: 0; }
  .select2-container--dataTable .select2-dropdown .select2-search__field {
    border: 1px solid #424242;
    padding: 4px 0; }

.select2-container--dataTable .select2-results__option--selectable {
  padding-left: 20px; }

.select2-container--dataTable .select2-results__option--selected {
  position: relative; }
  .select2-container--dataTable .select2-results__option--selected:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 8px;
    top: 12px;
    width: 4px;
    height: 8px;
    border: solid #00419f;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); }

.ms-tabs-link {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 30px; }

.ms-tabs-link a {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 20px;
  text-decoration: none;
  outline: none; }

.ms-tabs-link a.active {
  position: relative; }
  .ms-tabs-link a.active::before {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -5px;
    height: 5px;
    left: 0;
    background-color: #0099ff;
    width: 100%; }

.ms-tabs-content > div {
  display: none;
  padding: 0; }

.ms-tabs-content > div.active {
  display: block; }

.ms-tabs-content p.ms-tabs-content_text {
  color: #0e0d12;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 35px; }

.ms-tabs-content p.title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px; }

.ms-tabs-content .ms-tabs-block form {
  max-width: 700px;
  margin: 0 auto; }

.ms-visible.spoiler-trigger + div {
  display: block; }

.ms-spoiler-trigger {
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  text-decoration: none;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAMAAABFyS02AAAAAXNSR0IB2cksfwAAADNQTFRFAAAAYGBgZWVlaGhoZWVlZWVlZmZmZmZmZWVlZ2dnZ2dnZmZmZmZmZmZmZmZmZmZmZmZmdm5wIgAAABF0Uk5TABA/IDCw/89gkJ/woO/QUK9g+KnVAAAAT0lEQVR4nFXNSRKAMAhE0cZgG00c7n9aIVYhYdX1Fh/IgnxFgZVbkkoqdvIIUbIJ0H/Tk019hIV4blgSyOVmwjuqbo9JTb/dZvlsFrMenRftQgJMTlIEIQAAAABJRU5ErkJggg==") no-repeat 100% 50%; }
  .ms-spoiler-trigger:hover {
    text-decoration: none; }

.page-block .ms-spoiler-trigger p.title {
  margin-bottom: 0; }

.ms-spoiler-trigger.active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAAkElEQVQoU43SwQ2DMAwF0PwswCrtBozACPTgLOUDHYERGIFRYIH8iopUNBAHX209fVuGM0pEegADyZeqvkujKDUSkPoWdInkQA06IRmweu+7GOPonGs27CrRH5IDJFtVnUXkAWAqQT+kBKRVLOiL1IAahLuAAT0RQlj2Xdd0A+t39uTHGy1bkhFAexfIE5GcPiqYgGooQGNVAAAAAElFTkSuQmCC"); }

.ms-spoiler-trigger > span {
  padding: 0 3px; }

.ms-spoiler-trigger:hover > span {
  border-bottom-style: solid; }

.ms-lots .ms-lot:hover .ms-lot__tool .ms-lot-view {
  display: block; }

.ms-lots .ms-lot .ms-lot__content {
  min-height: initial;
  padding-bottom: 10px; }

.ms-lots .ms-lot .ms-lot__tool {
  width: 100%;
  position: relative; }

.ms-lots .ms-lot .ms-lot__tool .ms-lot-view {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #b7b7b7;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  display: none; }

.ms-lots .ms-lot .ms-lot__tool .ms-lot-view::after {
  content: '';
  display: inline-block;
  height: 14px;
  /*background-image: url("../img/sprite.svg#eye");*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  margin-left: 6px;
  position: relative;
  top: 3px; }

.ms-visible.spoiler-trigger + div {
  display: block; }

.ms-spoiler-trigger {
  color: #999999;
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  text-decoration: none;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAMAAABFyS02AAAAAXNSR0IB2cksfwAAADNQTFRFAAAAYGBgZWVlaGhoZWVlZWVlZmZmZmZmZWVlZ2dnZ2dnZmZmZmZmZmZmZmZmZmZmZmZmdm5wIgAAABF0Uk5TABA/IDCw/89gkJ/woO/QUK9g+KnVAAAAT0lEQVR4nFXNSRKAMAhE0cZgG00c7n9aIVYhYdX1Fh/IgnxFgZVbkkoqdvIIUbIJ0H/Tk019hIV4blgSyOVmwjuqbo9JTb/dZvlsFrMenRftQgJMTlIEIQAAAABJRU5ErkJggg==") no-repeat 100% 50%; }

.ms-spoiler-trigger.active {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAKCAYAAABSfLWiAAAAkElEQVQoU43SwQ2DMAwF0PwswCrtBozACPTgLOUDHYERGIFRYIH8iopUNBAHX209fVuGM0pEegADyZeqvkujKDUSkPoWdInkQA06IRmweu+7GOPonGs27CrRH5IDJFtVnUXkAWAqQT+kBKRVLOiL1IAahLuAAT0RQlj2Xdd0A+t39uTHGy1bkhFAexfIE5GcPiqYgGooQGNVAAAAAElFTkSuQmCC"); }
  .ms-spoiler-trigger.active > span {
    border-bottom-style: solid; }

.ms-spoiler-trigger > span {
  padding: 0 3px; }

.ms-spoiler-trigger:hover > span {
  border-bottom-style: solid; }

.ms-visible {
  display: none; }

.selection_type {
  padding: 10px;
  border: 2px solid #dcdee2;
  color: #1d2945;
  display: table-cell;
  width: 50%;
  margin: 0 15px;
  font-size: 0.9em;
  cursor: pointer; }

.swiper-container {
  width: 100%; }

table.table_style1 thead th {
  background-color: #0a0057;
  color: #fff;
  border-right: 1px solid #cccccc;
  font-size: 16px;
  padding: 13px 10px 13px 25px; }
  table.table_style1 thead th:last-child {
    border-right: none; }

table.table_style1 tfoot tr:first-child {
  display: none; }

table.table_style1 tfoot th {
  background-color: #fff;
  border-right: 1px solid #cccccc;
  border-top: none;
  font-size: 16px;
  padding: 13px 10px 13px 25px; }
  table.table_style1 tfoot th:last-child {
    border-right: none; }

table.table_style1 tfoot input {
  border: none;
  border-bottom: 1px solid #bfbfbf; }
  table.table_style1 tfoot input:focus-visible {
    outline: none; }

table.table_style1 tbody .even td {
  background-color: #f3f3f3; }

table.table_style1 tbody tr td:first-child {
  font-weight: 500;
  color: #000; }

table.table_style1 tbody td {
  border-right: 1px solid #cccccc;
  font-size: 16px;
  padding: 13px 10px 13px 25px; }
  table.table_style1 tbody td:last-child {
    border-right: none; }

table.dataTable thead .sorting_asc {
  background-image: url("../img/sort_asc.png");
  background-position: 95% center; }

table.dataTable thead .sorting {
  background-image: url("../img/sort_both.png");
  background-position: 95% center; }

table.dataTable thead .sorting_desc {
  background-image: url("../img/sort_desc.png");
  background-position: 95% center; }

table.dataTable a {
  color: #000;
  font-weight: 500;
  text-decoration: underline; }

.dataTables_wrapper {
  margin-bottom: 15px; }
  .dataTables_wrapper .dataTable {
    margin-bottom: 10px;
    width: 100% !important; }
  .dataTables_wrapper .dataTables_inner {
    overflow-x: auto;
    width: 100%;
    position: relative; }
  .dataTables_wrapper .dataTables_filter {
    color: #fff;
    display: none; }
    .dataTables_wrapper .dataTables_filter input {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #d5d5d5;
      color: #000000;
      font-size: 16px;
      background-color: transparent;
      font-weight: 300;
      padding: 6px 0; }
      .dataTables_wrapper .dataTables_filter input:focus-visible {
        outline: none; }
  .dataTables_wrapper .dataTables_info {
    color: #fff; }
  .dataTables_wrapper .dataTables_length {
    color: #fff !important; }
    .dataTables_wrapper .dataTables_length .select2-container--default {
      width: inherit; }
    .dataTables_wrapper .dataTables_length .select2-container {
      margin: 0 5px; }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #fff !important;
    padding: 0.1em 1em; }
    .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
      color: #fff !important; }

.wrp-header-block__menu {
  position: relative; }
  .wrp-header-block__menu > [class*='col'] {
    position: static; }

.wrp-main-content {
  padding-top: 116px; }

.wrp-header-block {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%; }
  .wrp-header-block.affix-menu {
    position: fixed; }
    .wrp-header-block.affix-menu .header-block .header-block__row-1 {
      min-height: 42px; }
    .wrp-header-block.affix-menu .header-block .header-block__row-2 {
      min-height: 38px; }

.header-block {
  background-color: #f1f1f1; }
  .header-block .header-block__row-1 {
    min-height: 68px;
    transition: all 0.4s; }
  .header-block .header-block__row-2 {
    min-height: 48px; }
  .header-block .header-block__row-3 {
    display: none; }
  .header-block .header-block__item {
    display: flex;
    align-content: center;
    height: 100%;
    align-items: center; }
  .header-block .header-block__tools {
    display: flex;
    justify-content: flex-end; }
  .header-block .header-block__tools-item {
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: left;
    flex-shrink: 0; }
  .header-block .header-block__menu-m ul {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    justify-content: flex-start;
    height: 100%; }
    .header-block .header-block__menu-m ul li {
      margin: 0 20px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      align-content: center; }
      .header-block .header-block__menu-m ul li:first-of-type {
        margin-left: 0; }
      .header-block .header-block__menu-m ul li:last-of-type {
        margin-right: 0; }
      .header-block .header-block__menu-m ul li a {
        color: #818181;
        font-size: 14px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-align: center; }
        .header-block .header-block__menu-m ul li a:hover {
          color: #00419f; }
  .header-block .header-block__menu {
    max-height: 48px;
    height: 100%; }
    .header-block .header-block__menu .menu {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      justify-content: flex-start;
      height: 100%; }
      .header-block .header-block__menu .menu > .menu-item > .sub-menu > .menu-item.wrp-menu-link_image:hover {
        background-color: initial; }
        .header-block .header-block__menu .menu > .menu-item > .sub-menu > .menu-item.wrp-menu-link_image:hover::before {
          display: none; }
      .header-block .header-block__menu .menu > .menu-item > .sub-menu > .menu-item .menu-link_image {
        height: 0;
        padding-bottom: 49%;
        display: block;
        position: relative;
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 0;
        background-size: cover;
        background-position: center;
        border: none;
        width: 85%;
        margin-left: auto; }
        .header-block .header-block__menu .menu > .menu-item > .sub-menu > .menu-item .menu-link_image::before {
          content: ''; }
      .header-block .header-block__menu .menu > .menu-item > .sub-menu > .menu-item > .wrp-menu-link_body {
        padding: 10px 15px;
        width: 85%;
        margin-left: auto; }
        .header-block .header-block__menu .menu > .menu-item > .sub-menu > .menu-item > .wrp-menu-link_body span {
          color: #636363;
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: left;
          margin-bottom: 15px;
          display: inline-block;
          cursor: default; }
        .header-block .header-block__menu .menu > .menu-item > .sub-menu > .menu-item > .wrp-menu-link_body a {
          color: #00419f;
          font-size: 16px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          text-align: left;
          margin-left: 0;
          display: inline-block;
          width: auto;
          border-bottom: none; }
      .header-block .header-block__menu .menu .menu-item {
        margin: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center; }
        .header-block .header-block__menu .menu .menu-item:hover .sub-menu {
          display: flex; }
        .header-block .header-block__menu .menu .menu-item:first-of-type {
          margin-left: 0; }
        .header-block .header-block__menu .menu .menu-item:last-of-type {
          margin-right: 0; }
        .header-block .header-block__menu .menu .menu-item a {
          color: #1f3042;
          font-size: 18px;
          font-weight: 300;
          font-style: normal;
          letter-spacing: 0.4px;
          text-align: center;
          text-decoration: none;
          position: relative; }
          .header-block .header-block__menu .menu .menu-item a::before {
            content: '';
            width: 100%;
            height: 2px;
            background-color: transparent;
            position: absolute;
            bottom: 2px;
            left: 50%;
            transform: translateX(-50%); }
          .header-block .header-block__menu .menu .menu-item a:hover {
            color: #00419f; }
            .header-block .header-block__menu .menu .menu-item a:hover::before {
              background-image: linear-gradient(to right, #1f79fb 0%, #0a0057 100%); }
        .header-block .header-block__menu .menu .menu-item .sub-menu {
          position: absolute;
          display: none;
          left: 0;
          top: 100%;
          width: auto;
          padding: 23px 30px 10px 15px;
          list-style: none;
          background-color: #fff;
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
          order: 1;
          flex-wrap: nowrap; }
          .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item {
            margin: 0 45px 0 0;
            width: 100%;
            max-width: 185px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start; }
            .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item:last-child {
              margin-right: 0; }
            .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a {
              color: #0a0057;
              font-size: 16px;
              font-weight: 500;
              padding: 0 0 7px 0;
              border-bottom: 1px solid #0d66c2;
              letter-spacing: 0.4px;
              width: 100%;
              text-align: left;
              margin-left: 25px; }
              .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a::before {
                content: '';
                position: static; }
              .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a:hover {
                color: #00419f; }
                .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a:hover::before {
                  background-image: none; }
              .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu {
                display: block;
                position: static;
                box-shadow: none;
                padding: 10px 0 0 0;
                margin-left: 10px; }
                .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item {
                  display: block;
                  margin: 0 0 15px;
                  position: relative;
                  padding: 5px 0 5px 16px; }
                  .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item:hover {
                    background-color: #e8eef6; }
                    .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item:hover::before {
                      content: '';
                      position: absolute;
                      left: -3px;
                      opacity: 1;
                      top: 0;
                      height: 100%;
                      width: 3px;
                      background-color: #00419f; }
                  .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item.wrp-menu-link_image:hover {
                    background-color: initial; }
                    .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item.wrp-menu-link_image:hover::before {
                      display: none; }
                  .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item .menu-link_image {
                    height: 0;
                    padding-bottom: 48%;
                    display: block;
                    position: relative;
                    overflow: hidden;
                    flex-shrink: 1;
                    flex-grow: 0;
                    width: 100%;
                    background-size: cover;
                    background-position: center; }
                    .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item .menu-link_image::before {
                      content: ''; }
                  .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item .wrp-menu-link_body {
                    padding: 10px 15px; }
                    .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item .wrp-menu-link_body span {
                      color: #636363;
                      font-size: 16px;
                      font-weight: 300;
                      font-style: normal;
                      letter-spacing: normal;
                      line-height: normal;
                      text-align: left;
                      margin-bottom: 15px;
                      display: inline-block;
                      cursor: default; }
                    .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item .wrp-menu-link_body a {
                      display: block;
                      color: #00419f;
                      font-size: 16px;
                      font-weight: 300;
                      font-style: normal;
                      letter-spacing: normal;
                      line-height: normal;
                      text-align: left; }
                  .header-block .header-block__menu .menu .menu-item .sub-menu .menu-item a + .sub-menu .menu-item a {
                    color: #1f3042;
                    font-size: 16px;
                    font-weight: 300;
                    border-bottom: none;
                    padding: 0;
                    line-height: 0;
                    display: inline;
                    margin-left: 0; }
      .header-block .header-block__menu .menu .header-block__menu-child {
        position: absolute;
        width: 100%; }

.wrp-main-search .main-search {
  min-height: 470px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 55px 0 5px; }
  .wrp-main-search .main-search p {
    width: 100%;
    margin-bottom: 10px;
    color: #a2bae2;
    font-size: 12px;
    font-weight: 300; }
    .wrp-main-search .main-search p.title {
      color: #ffffff;
      font-size: 46px;
      font-weight: 700;
      letter-spacing: 0.1px; }
    .wrp-main-search .main-search p.sub-title {
      color: #ffffff;
      font-size: 24px;
      letter-spacing: 0.2px; }
  .wrp-main-search .main-search .form {
    width: 1080px;
    padding: 25px 30px;
    position: relative;
    margin: 20px 0 20px; }
    .wrp-main-search .main-search .form:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0.25;
      left: 0;
      top: 0; }
    .wrp-main-search .main-search .form .form-group .form-group__inner {
      margin: 0 5px; }
  .wrp-main-search .main-search .main-search_min-desc p,
  .wrp-main-search .main-search .main-search_min-desc li,
  .wrp-main-search .main-search .main-search_min-desc span,
  .wrp-main-search .main-search .main-search_min-desc ul,
  .wrp-main-search .main-search .main-search_min-desc a {
    font-size: 12px; }
  .wrp-main-search .main-search .btn-danger {
    text-transform: none; }
  .wrp-main-search .main-search .btn,
  .wrp-main-search .main-search .mg-btn {
    white-space: nowrap; }

.bank-list {
  margin-bottom: 25px; }
  .bank-list .bank-list__item {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    border-left: 6px solid #4087ec;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    position: relative; }
    .bank-list .bank-list__item .btn,
    .bank-list .bank-list__item .mg-btn {
      white-space: nowrap; }
  .bank-list .bank-list__item-wrp-img {
    background-color: #fff;
    padding-top: 20%;
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 250px;
    flex-shrink: 1;
    flex-grow: 0;
    width: 100%; }
  .bank-list .bank-list__item-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-height: 183px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 75%;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%; }
    .bank-list .bank-list__item-img a {
      height: 100%;
      width: 100%;
      display: block;
      padding-bottom: 75%;
      position: absolute;
      top: 0; }
    .bank-list .bank-list__item-img img {
      object-fit: fill;
      object-position: center; }
  .bank-list .bank-list__item-content {
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0 40px 0 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .bank-list .bank-list__item-content .bank-list__item-logo {
      display: flex;
      align-items: center;
      align-content: center; }
      .bank-list .bank-list__item-content .bank-list__item-logo p {
        margin-bottom: 0; }
        .bank-list .bank-list__item-content .bank-list__item-logo p.title {
          margin-bottom: 0;
          font-size: 16px; }
    .bank-list .bank-list__item-content .bank-list__item-text {
      max-height: 45px;
      overflow: hidden;
      color: #959595;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.3; }
    .bank-list .bank-list__item-content .bank-list__item-tags {
      margin-bottom: 8px; }
      .bank-list .bank-list__item-content .bank-list__item-tags .item-tag {
        margin: 8px 7px 0 0; }
        .bank-list .bank-list__item-content .bank-list__item-tags .item-tag:last-child {
          margin-right: 0; }
  .bank-list .bank-list__item-rating {
    flex-shrink: 1;
    flex-grow: 0;
    max-width: 177px;
    width: 100%; }
    .bank-list .bank-list__item-rating .wrp-bank-widget__rating {
      display: none; }

.list-tools {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center; }
  .list-tools .list-tools__read-more {
    font-size: 16px;
    font-weight: 300; }
  .list-tools .list-tools__total {
    font-size: 16px;
    font-weight: 300; }
    .list-tools .list-tools__total p {
      color: #7a7a7a; }
    .list-tools .list-tools__total span {
      color: #000; }

.bank-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 13px 9px;
  background-color: #f6f6f6; }
  .bank-widget > * {
    flex-basis: 100%;
    margin-bottom: 0;
    text-align: center; }
  .bank-widget .bank-widget__rating {
    color: #00419f;
    font-size: 40px;
    font-weight: 500;
    line-height: 0.9;
    position: relative;
    padding-bottom: 0; }
    .bank-widget .bank-widget__rating::before {
      content: '';
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 9px;
      height: 20px;
      display: inline-block;
      position: absolute;
      right: 50%;
      transform: translate(45px, 15%); }
    .bank-widget .bank-widget__rating.bank-widget__rating-top::before {
      background-image: url("../img/sprite.svg#rating-top"); }
    .bank-widget .bank-widget__rating.bank-widget__rating-down::before {
      background-image: url("../img/sprite.svg#rating-down");
      transform-origin: 70% 33%;
      background-position: top center; }
  .bank-widget .bank-widget__desc {
    color: #000000;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1.33px;
    text-transform: uppercase; }

.item-tag {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 4px 12px;
  text-align: center;
  color: #414141;
  font-size: 12px;
  font-weight: 300;
  margin: 0 7px; }
  .item-tag::before {
    content: '';
    border-radius: 30px;
    background-color: #00419f;
    opacity: 0.1;
    display: inline-block;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    position: absolute; }

.rating-star__wrp {
  flex-direction: column;
  height: 75px; }

.star-rating__container {
  display: inline-block;
  position: relative; }

.star-rating__wrapper {
  position: relative;
  display: flex;
  justify-content: center; }

.star-rating {
  display: inline-block;
  position: relative;
  user-select: none; }

.star-rating__bg {
  color: #e0e0e0;
  display: flex; }

.star-rating__live {
  display: flex;
  color: #ffb74d;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 0; }

.star-rating_active:hover .star-rating__live {
  overflow: auto;
  width: 100% !important;
  color: #e0e0e0; }

.star-rating__item_active {
  color: #fb8c00;
  cursor: pointer;
  transition: color 0.1s ease-in-out; }

.star-rating__item {
  width: 19px;
  height: 19px;
  flex: 0 0 19px; }

.star-rating__votes {
  display: flex;
  font-size: 0.875em; }

.rating-star__label,
.star-rating__votes_count {
  color: #7b8faa;
  line-height: 1.5; }

.star-rating__votes_count {
  display: inline-block;
  width: 100%;
  text-align: center; }

.footer-block {
  background-color: #212224;
  padding: 60px 0; }
  .footer-block .footer-block__item {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .footer-block .footer-block__item > * {
      flex-basis: 100%;
      width: 100%; }
    .footer-block .footer-block__item .logo {
      text-align: center;
      margin-bottom: 20px; }
    .footer-block .footer-block__item .logo-desc p {
      text-align: center;
      color: #8e8e8e;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 30px;
      line-height: 1.15; }
    .footer-block .footer-block__item .partner-list {
      padding: 0 5%; }
      .footer-block .footer-block__item .partner-list .partner-list__content {
        border-top: 1px solid #3b3b3b;
        padding-top: 30px; }
  .footer-block .footer-block__menu {
    padding-left: 14%;
    margin-top: -9px; }
    .footer-block .footer-block__menu .ms-visible {
      display: block; }
    .footer-block .footer-block__menu p.title {
      color: #ffffff;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 2px;
      letter-spacing: 1.33px;
      padding: 2px 0 2px 10px;
      position: relative; }
      .footer-block .footer-block__menu p.title::before {
        content: '';
        width: 6px;
        height: 100%;
        background-color: #4087ec;
        position: absolute;
        left: -10px;
        top: 0; }
    .footer-block .footer-block__menu ul {
      padding-left: 0;
      margin: 0;
      list-style: none; }
      .footer-block .footer-block__menu ul li {
        border-bottom: 1px solid #343537;
        padding: 14px 0; }
        .footer-block .footer-block__menu ul li:last-child {
          border-bottom: none; }
      .footer-block .footer-block__menu ul a {
        display: block;
        color: #b2b2b2;
        padding: 2px 0;
        font-size: 16px;
        font-weight: 300; }

.footer-block-disclaimer {
  background-color: #343537;
  padding: 20px 0; }
  .footer-block-disclaimer p {
    color: #888888;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-bottom: 0; }

.partner-list img {
  margin: 7px 15px; }

.block-widget {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
  margin-bottom: 25px;
  /*&:last-child {
        margin-bottom: 0;
    }*/ }
  .block-widget .block-widget__title {
    color: #000000;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    padding: 20px 0;
    margin-top: 0; }
    .block-widget .block-widget__title p {
      margin-bottom: 0; }
      .block-widget .block-widget__title p.title {
        flex-basis: 100%;
        text-align: center; }
  .block-widget .block-widget__body {
    padding: 0 8%; }
    .block-widget .block-widget__body button[type='submit'],
    .block-widget .block-widget__body input[type='submit'] {
      width: 100%;
      padding: 12px; }
  .block-widget.block-widget-subscribe {
    position: relative; }
    .block-widget.block-widget-subscribe .block-widget__title {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      background-color: #00204e;
      padding: 68px 10px 10px;
      position: relative;
      margin-bottom: 36px; }
      .block-widget.block-widget-subscribe .block-widget__title::before {
        content: '';
        background-image: url("../img/sprite.svg#man");
        background-position: center;
        height: 45px;
        width: 45px;
        display: block;
        position: absolute;
        top: 9px;
        left: 50%;
        transform: translateX(-50%);
        background-repeat: no-repeat; }
      .block-widget.block-widget-subscribe .block-widget__title::after {
        content: '';
        /*background: linear-gradient(to right top, transparent 50%, #00204e 50%) left / 50% 100% no-repeat,
                    linear-gradient(to left top, transparent 50%, #00204e 50%) right / 50% 100% no-repeat;*/
        clip-path: polygon(100% 0, 50% 100%, 0 0);
        filter: drop-shadow(0 0 5px #000);
        background-color: #00204e;
        display: block;
        position: absolute;
        bottom: -30px;
        height: 30px;
        width: 100%; }
      .block-widget.block-widget-subscribe .block-widget__title p {
        color: #fff;
        text-align: center;
        line-height: 1.15; }
        .block-widget.block-widget-subscribe .block-widget__title p.title {
          color: #a1c8ff;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 3px;
          line-height: 1.4; }
    .block-widget.block-widget-subscribe .select2-container--default {
      min-height: 40px; }
    .block-widget.block-widget-subscribe form.form-type2 .form-group {
      margin-bottom: 18px; }
      .block-widget.block-widget-subscribe form.form-type2 .form-group .form-group__inner .form-control {
        min-height: 38px; }
  .block-widget.block-widget-video {
    padding: 25px 30px 30px; }
    .block-widget.block-widget-video .block-widget__title {
      padding: 0;
      margin-bottom: 15px;
      position: relative; }
      .block-widget.block-widget-video .block-widget__title .mg-icon {
        position: absolute;
        left: -4px;
        top: 50%;
        transform: translateY(-50%); }
        .block-widget.block-widget-video .block-widget__title .mg-icon.mg-icon__youtube::before {
          width: 48px;
          height: 36px; }
      .block-widget.block-widget-video .block-widget__title p.title {
        padding-left: 55px;
        text-align: left;
        font-size: 24px;
        font-weight: 300; }
    .block-widget.block-widget-video .block-widget__body {
      padding: 0; }
    .block-widget.block-widget-video .block-widget-video__youtube-preview {
      padding-bottom: 60%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 15px; }
    .block-widget.block-widget-video .block-widget-video__youtube-iframe iframe {
      max-width: 100%;
      height: auto;
      min-height: 195px; }
    .block-widget.block-widget-video .block-widget__body-item {
      margin-bottom: 30px; }
      .block-widget.block-widget-video .block-widget__body-item:last-child {
        margin-bottom: 0; }
    .block-widget.block-widget-video .block-widget__body-link {
      margin-top: 15px;
      display: block; }
  .block-widget.block-widget-single-form {
    background-image: url("../img/form-slider.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 30px 0 75px; }
    .block-widget.block-widget-single-form::before {
      content: '';
      width: 100%;
      display: block;
      height: 100%;
      background-color: #0a0057;
      opacity: 0.7;
      position: absolute;
      top: 0;
      z-index: 0;
      pointer-events: none; }
    .block-widget.block-widget-single-form .block-widget__title {
      padding-top: 7px;
      z-index: 1;
      position: relative;
      margin-bottom: 15px; }
      .block-widget.block-widget-single-form .block-widget__title p {
        color: #fff; }
    .block-widget.block-widget-single-form .block-widget__body {
      position: relative;
      z-index: 1; }
  .block-widget.block-widget-subscribe_min {
    padding: 20px 30px;
    transform: translateY(-50%); }
    .block-widget.block-widget-subscribe_min .block-widget__youtube-count {
      margin-right: 90px; }
      .block-widget.block-widget-subscribe_min .block-widget__youtube-count p {
        display: flex;
        align-items: center; }
      .block-widget.block-widget-subscribe_min .block-widget__youtube-count .mg-icon {
        margin-right: 15px; }
      .block-widget.block-widget-subscribe_min .block-widget__youtube-count .mg-icon__views {
        height: 40px;
        width: 45px; }
        .block-widget.block-widget-subscribe_min .block-widget__youtube-count .mg-icon__views::before {
          background-size: cover; }
      .block-widget.block-widget-subscribe_min .block-widget__youtube-count .mg-icon__subscribes {
        height: 25px;
        width: 25px; }
        .block-widget.block-widget-subscribe_min .block-widget__youtube-count .mg-icon__subscribes::before {
          background-size: cover; }
    .block-widget.block-widget-subscribe_min .block-widget__youtube p {
      color: #4087ec;
      font-size: 30px;
      font-weight: 300;
      margin-bottom: 0; }
  .block-widget.block-widget-list .block-widget__body {
    padding-bottom: 45px; }
    .block-widget.block-widget-list .block-widget__body ul {
      margin: 0;
      padding-left: 0;
      list-style: none; }
      .block-widget.block-widget-list .block-widget__body ul.block-widget__body-list li {
        padding: 10px 0 10px 25px;
        border-bottom: 1px solid #eaeaea;
        margin: 3px 0; }
      .block-widget.block-widget-list .block-widget__body ul.block-widget__body-list a, .block-widget.block-widget-list .block-widget__body ul.block-widget__body-list .block-widget__link {
        position: relative;
        color: #0a0057;
        font-size: 16px;
        font-weight: 300;
        padding: 5px 0;
        line-height: 1; }
        .block-widget.block-widget-list .block-widget__body ul.block-widget__body-list a::before, .block-widget.block-widget-list .block-widget__body ul.block-widget__body-list .block-widget__link::before {
          content: '';
          background-image: url("../img/sprite.svg#arrow-list");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 70%;
          border: 1px solid #4087ec;
          border-radius: 50%;
          left: -27px;
          top: 5px;
          height: 18px;
          width: 18px;
          display: block;
          position: absolute; }
      .block-widget.block-widget-list .block-widget__body ul.block-widget__body-list_icons li {
        padding: 17px 0 17px 56px;
        border-top: 1px solid #eaeaea;
        border-bottom: none;
        margin: 3px 0; }
      .block-widget.block-widget-list .block-widget__body ul.block-widget__body-list_icons a {
        position: relative;
        color: #0a0057;
        font-size: 16px;
        font-weight: 300;
        padding: 5px 0;
        line-height: 1; }
  .block-widget.block-widget-image {
    padding: 0;
    position: relative;
    max-width: 403px;
    margin: 0 auto; }
    .block-widget.block-widget-image .block-widget__body {
      padding: 0 0 100% 0;
      position: relative;
      background-size: cover;
      width: 100%;
      /*&::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                clip-path: polygon(0 100%, 0 25%, 100% 100%);
                background-color: $blueD;
                height: 100%;
                width: 100%;
                padding: 0;
                z-index: 1;
            }*/ }
      .block-widget.block-widget-image .block-widget__body p {
        color: #4087ec;
        font-size: 28px;
        font-weight: 300;
        position: absolute;
        left: 7%;
        bottom: 8%;
        line-height: 1.2;
        text-transform: uppercase;
        height: 20%;
        width: 100%;
        z-index: 3;
        pointer-events: none; }
        .block-widget.block-widget-image .block-widget__body p.title {
          margin: 0; }
        .block-widget.block-widget-image .block-widget__body p strong {
          display: block;
          font-weight: 700; }
      .block-widget.block-widget-image .block-widget__body a {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 2; }
    .block-widget.block-widget-image .swiper-pagination {
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
      z-index: 2; }
      .block-widget.block-widget-image .swiper-pagination .swiper-pagination-bullet {
        margin: 0 2px; }
  .block-widget.block-widget-frame {
    height: auto; }
    .block-widget.block-widget-frame .block-widget__body {
      height: 0;
      padding-bottom: 100%; }
  .block-widget.block-widget-filter {
    background-color: transparent;
    box-shadow: none; }
  .block-widget .partner-list {
    margin-top: 8px;
    padding: 0 7% 7px; }

.block-widget.h100 {
  height: 100%; }
  .block-widget.h100 .block-widget__body {
    height: 100%; }

.bank-single__body.h100 {
  height: calc(100% - 25px); }

.wrp-swift-check {
  position: relative;
  z-index: 2; }

.wrapper_swiftChecker {
  position: relative;
  display: none;
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .wrapper_swiftChecker.wrapper_swiftChecker-mobile .dataTables_length label {
    color: transparent; }
  .wrapper_swiftChecker.wrapper_swiftChecker-active {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    display: block !important; }
  .wrapper_swiftChecker.wrapper_swiftChecker-hide {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .wrapper_swiftChecker .dataTables_wrapper {
    position: absolute;
    width: 100%;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
    padding-bottom: 10px; }
    .wrapper_swiftChecker .dataTables_wrapper .dataTables_close {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 38px;
      color: #fff;
      line-height: 32px;
      cursor: pointer; }

.active_swift-checker + .page-block .row-page-block::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2; }

.swift-check {
  min-height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
  /*.mg-loader {
        &::before {
            top: 50%;
            transform: translateX(-100%);
        }
    }*/ }
  .swift-check .swift-check__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 100%; }
    .swift-check .swift-check__inner .news-list {
      position: relative;
      margin-bottom: 8.75rem; }
  .swift-check .swift-check__content {
    text-align: center;
    margin-bottom: 0.75rem; }
    .swift-check .swift-check__content p {
      color: #fff;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0; }
      .swift-check .swift-check__content p.title {
        font-size: 42px;
        font-weight: 700;
        margin-bottom: 5px; }
  .swift-check .swift-check__form {
    padding-top: 20px;
    margin-bottom: 15px; }
    .swift-check .swift-check__form .btn,
    .swift-check .swift-check__form .mg-btn {
      padding: 0.375rem 2rem;
      text-transform: none; }
    .swift-check .swift-check__form.form .form-group .form-group__inner::after {
      content: 'Live';
      position: absolute;
      background-image: url("../img/sprite.svg#green-fire");
      background-position: center right;
      background-repeat: no-repeat;
      height: 18px;
      width: 44px;
      line-height: 1.85;
      display: inline-block;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.59;
      color: #000000;
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase; }
    .swift-check .swift-check__form.form .form-group .form-group__inner .form-control {
      padding-left: 35px;
      color: #000000;
      font-size: 18px;
      font-weight: 300; }
    .swift-check .swift-check__form.form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label::before {
      border: 1px solid #bfbfbf;
      background-color: #ffffff; }
    .swift-check .swift-check__form.form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label::before {
      background-color: #00419f; }

.sign-up-block {
  min-height: 640px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 150px; }
  .sign-up-block .sign-up-block__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    .sign-up-block .sign-up-block__inner .news-list {
      position: relative;
      margin-bottom: 8.75rem; }
  .sign-up-block form .form-group .form-group__inner {
    margin: 0 7px; }
  .sign-up-block .sign-up-block__content {
    text-align: center;
    margin-bottom: 2.75rem; }
    .sign-up-block .sign-up-block__content p {
      color: #dedede;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0; }
      .sign-up-block .sign-up-block__content p.title {
        font-size: 42px;
        font-weight: 300;
        margin-bottom: 5px; }
  .sign-up-block .sign-up-block__form .btn,
  .sign-up-block .sign-up-block__form .mg-btn {
    padding: 0.375rem 2rem;
    text-transform: none; }
  .sign-up-block .sign-up-block__form.form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label::before {
    border: 1px solid #bfbfbf;
    background-color: #ffffff; }
  .sign-up-block .sign-up-block__form.form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label::before {
    background-color: #00419f; }

.landing-form {
  min-height: 330px; }
  .landing-form .landing-form__content {
    text-align: center;
    margin-bottom: 2.75rem; }
    .landing-form .landing-form__content p {
      color: #dedede;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0; }
      .landing-form .landing-form__content p.title {
        font-size: 42px;
        font-weight: 300;
        margin-bottom: 5px; }
  .landing-form .landing-form__inner {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 45px 0 0; }
    .landing-form .landing-form__inner form .form-group .form-group__inner {
      margin: 0 7px;
      flex-basis: initial; }
  .landing-form .btn,
  .landing-form .mg-btn {
    padding: 0.375rem 2rem;
    text-transform: none; }
  .landing-form .landing-form__form.form .form-group .form-group__inner {
    margin-left: 7px;
    margin-right: 7px; }
    .landing-form .landing-form__form.form .form-group .form-group__inner.form-group__checkbox [type='checkbox'] + label::before {
      border: 1px solid #bfbfbf;
      background-color: #ffffff; }
    .landing-form .landing-form__form.form .form-group .form-group__inner.form-group__checkbox [type='checkbox']:checked + label::before {
      background-color: #00419f; }

.news-item {
  position: relative;
  width: 100%; }
  .news-item .btn,
  .news-item .mg-btn {
    padding: 0.375rem 2rem; }
  .news-item .news-item__img {
    height: 0;
    width: 100%;
    padding-bottom: 35%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .news-item .news-item__title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative; }
    .news-item .news-item__title p {
      color: #fff;
      font-size: 24px;
      font-weight: 700; }
    .news-item .news-item__title .title {
      display: block;
      margin-bottom: 15px;
      position: absolute;
      text-align: center; }
  .news-item .news-item__content {
    text-align: center;
    padding: 1% 9% 4.5%;
    background-color: #fff; }
    .news-item .news-item__content > div {
      min-height: 75px; }
    .news-item .news-item__content p {
      color: #959595;
      font-size: 15px;
      font-weight: 300;
      margin-bottom: 15px;
      line-height: 1.33; }

.sign-up-block .news-item .news-item__img {
  position: absolute; }

.ms-spoiler-block .news-widget.news-widget__type2 .news-widget__content .title {
  flex-basis: 100%; }

.ms-spoiler-block .news-widget.news-widget__type2 .news-widget__content .date {
  flex-basis: 100%;
  text-align: left; }

.news-widget {
  max-height: 570px; }
  .news-widget.news-widget__type2 {
    max-height: initial; }
    .news-widget.news-widget__type2 .news-widget__list {
      max-height: initial; }
    .news-widget.news-widget__type2 .news-widget__item {
      background-color: inherit;
      border-bottom: 1px solid #cfcfcf;
      margin-bottom: 29px;
      position: relative; }
    .news-widget.news-widget__type2 .news-widget__img {
      width: 182px;
      height: 179px;
      flex-grow: 0;
      flex-shrink: 0; }
    .news-widget.news-widget__type2 .news-widget__tag {
      position: absolute;
      top: 10px;
      background-color: #4087ec;
      padding: 7px 15px;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 10px;
      font-weight: 400; }
    .news-widget.news-widget__type2 .news-widget__content {
      position: relative;
      padding-bottom: 0;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      cursor: default; }
      .news-widget.news-widget__type2 .news-widget__content .title {
        display: block;
        height: 45px;
        overflow: hidden;
        flex-basis: 85%;
        color: #00419f;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        text-decoration: none;
        cursor: pointer; }
      .news-widget.news-widget__type2 .news-widget__content .date {
        position: static;
        font-weight: 400;
        margin-bottom: 7px;
        flex-basis: 10%;
        text-align: right;
        padding-top: 7px; }
      .news-widget.news-widget__type2 .news-widget__content .desc {
        max-height: 65px;
        overflow: hidden;
        flex-basis: 100%;
        flex-grow: 1; }
        .news-widget.news-widget__type2 .news-widget__content .desc p {
          line-height: 1; }
    .news-widget.news-widget__type2 .news-widget__preview {
      position: relative;
      max-height: initial;
      height: 100%;
      width: 100%;
      padding-bottom: 88%;
      background-size: cover;
      background-repeat: no-repeat; }
      .news-widget.news-widget__type2 .news-widget__preview .news-widget__tag {
        right: 0;
        position: absolute;
        top: 20px;
        color: #ffffff;
        font-size: 10px;
        font-weight: 400; }
      .news-widget.news-widget__type2 .news-widget__preview .news-widget__preview-content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 30px;
        background-color: #0a0057;
        opacity: 0.78;
        box-sizing: border-box; }
        .news-widget.news-widget__type2 .news-widget__preview .news-widget__preview-content p {
          text-align: left;
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          line-height: 1; }
        .news-widget.news-widget__type2 .news-widget__preview .news-widget__preview-content .title {
          text-align: left;
          display: block;
          color: #ffffff;
          font-size: 30px;
          font-weight: 500;
          margin-bottom: 30px; }
    .news-widget.news-widget__type2 .news-widget__desc {
      background-color: #0a0057; }
      .news-widget.news-widget__type2 .news-widget__desc p {
        color: #00419f;
        font-size: 20px;
        font-weight: 500; }
  .news-widget.news-widget__video {
    max-height: initial; }
    .news-widget.news-widget__video .grid-group {
      width: 100%; }
    .news-widget.news-widget__video .news-widget__category .title {
      line-height: 1.4; }
    .news-widget.news-widget__video .news-widget__list {
      display: flex;
      flex-wrap: wrap;
      max-height: initial;
      justify-content: flex-start; }
    .news-widget.news-widget__video .news-widget__item {
      max-width: 31%;
      flex-wrap: wrap;
      margin-bottom: 35px;
      margin-right: 32px;
      background-color: transparent;
      align-content: flex-start;
      flex-direction: column; }
      .news-widget.news-widget__video .news-widget__item:nth-child(3n-3) {
        margin-right: 0; }
    .news-widget.news-widget__video .news-widget__content {
      padding: 0;
      align-items: flex-start;
      align-content: flex-start; }
      .news-widget.news-widget__video .news-widget__content .title {
        padding: 12px 5px;
        display: inline-block;
        margin-bottom: 0;
        color: #000000;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
        cursor: default; }
    .news-widget.news-widget__video .news-widget__img {
      width: 100%;
      height: auto;
      background-size: cover;
      position: relative;
      padding-bottom: 0; }
      .news-widget.news-widget__video .news-widget__img p {
        height: 100%; }
      .news-widget.news-widget__video .news-widget__img iframe {
        width: 100%;
        height: auto;
        min-height: 165px; }
      .news-widget.news-widget__video .news-widget__img:before {
        display: none; }
      .news-widget.news-widget__video .news-widget__img a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1; }
  .news-widget .news-widget__list {
    max-height: 570px;
    width: 100%; }
    .news-widget .news-widget__list .mCSB_container {
      padding: 0; }
  .news-widget .news-widget__item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 12px;
    background-color: #f2f2f2; }
    .news-widget .news-widget__item:hover {
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.16);
      cursor: pointer; }
    .news-widget .news-widget__item.active {
      background-color: #fff;
      box-shadow: 0 3px 24px rgba(0, 0, 0, 0.16); }
      .news-widget .news-widget__item.active .title {
        color: #d20b0b; }
  .news-widget .news-widget__img {
    width: 193px;
    height: 133px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .news-widget .news-widget__content {
    padding: 16px 30px 22px 30px;
    position: relative;
    line-height: 1;
    justify-content: space-between; }
    .news-widget .news-widget__content .date {
      position: absolute;
      right: 22px;
      bottom: 20px;
      color: #4d4d4d;
      font-size: 12px;
      font-weight: 400; }
    .news-widget .news-widget__content a {
      text-decoration: underline; }
  .news-widget .news-widget__preview {
    max-height: 425px;
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center; }
    .news-widget .news-widget__preview img {
      object-fit: cover;
      width: 100%;
      height: auto; }
    .news-widget .news-widget__preview iframe {
      width: 100%;
      height: 425px; }
  .news-widget .news-widget__desc {
    background-color: #0a0057;
    width: 100%;
    padding: 30px 30px 22px 30px;
    flex-shrink: 1;
    flex-grow: 0;
    position: relative;
    display: flex;
    flex-basis: 100%;
    min-height: 145px; }
    .news-widget .news-widget__desc p {
      color: #ffffff;
      font-size: 30px;
      font-weight: 500; }
    .news-widget .news-widget__desc .date {
      position: absolute;
      right: 20px;
      bottom: 15px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400; }

.widget-block .news-widget.news-widget__type2 .news-widget__content {
  padding-bottom: 0; }

.widget-block .news-widget .news-widget__content {
  padding: 20px 30px 22px 30px;
  width: 100%; }
  .widget-block .news-widget .news-widget__content .title {
    color: #00419f;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1; }

.page-video-center h1 {
  display: inline;
  position: relative;
  padding-left: 75px; }
  .page-video-center h1::after {
    content: '';
    background-image: url("../img/sprite.svg#youtube-border-white");
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    width: 68px;
    height: 60px;
    position: absolute;
    left: 0;
    top: 0; }

.page-post {
  position: relative; }
  .page-post.page-archive .wrp-page-post__slider {
    height: 220px;
    transform: translateY(-80%);
    padding-bottom: 30px; }
  .page-post.page-archive .page-post__header-bg {
    padding-bottom: 20%; }
    .page-post.page-archive .page-post__header-bg::before {
      background-image: linear-gradient(256deg, #0a0057 0%, #00419f 43%, #3d01a1 100%);
      opacity: 0.41;
      height: 0;
      padding-bottom: 30%;
      top: 0; }
  .page-post.page-archive .page-post__header-bg .page-post__header-sub::before {
    display: none; }
  .page-post.page-post_single .page-post_border {
    padding-right: 30%; }
  .page-post.page-post_single .page-post__header {
    position: relative;
    padding-bottom: 0; }
    .page-post.page-post_single .page-post__header h1 {
      letter-spacing: 0;
      color: #0a0057; }
  .page-post.page-post_single .page-post__info-list {
    flex-wrap: nowrap; }
    .page-post.page-post_single .page-post__info-list .page-post__info-item {
      display: inline-block;
      padding-left: 0;
      margin-right: 5px; }
      .page-post.page-post_single .page-post__info-list .page-post__info-item:first-child {
        padding-left: 0; }
      .page-post.page-post_single .page-post__info-list .page-post__info-item.page-post__info-item_author {
        border-right: 1px solid #cbcbcb;
        padding-right: 10px; }
      .page-post.page-post_single .page-post__info-list .page-post__info-item .page-post__info-title,
      .page-post.page-post_single .page-post__info-list .page-post__info-item a,
      .page-post.page-post_single .page-post__info-list .page-post__info-item p,
      .page-post.page-post_single .page-post__info-list .page-post__info-item span {
        font-size: 14px;
        line-height: 17px;
        color: #4c4c4c; }
      .page-post.page-post_single .page-post__info-list .page-post__info-item a,
      .page-post.page-post_single .page-post__info-list .page-post__info-item span {
        color: #4c4c4c; }
  .page-post.page-post_single .page-post__image-head {
    margin-bottom: 15px; }
    .page-post.page-post_single .page-post__image-head img {
      width: 100%;
      height: auto; }
  .page-post .page-post__slider {
    height: 365px;
    padding: 0;
    position: relative;
    background-color: #fff;
    box-sizing: border-box; }
    .page-post .page-post__slider::before {
      content: '';
      position: absolute;
      top: 0;
      height: 8px;
      background-image: linear-gradient(270deg, #4087ec 0%, #0a0057 100%);
      left: 0;
      width: 100%; }
    .page-post .page-post__slider .page-post__slider-inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%; }
    .page-post .page-post__slider .page-post__slider-tools {
      margin-bottom: 30px; }
      .page-post .page-post__slider .page-post__slider-tools .news-tag {
        margin-right: 10px; }
    .page-post .page-post__slider .page-post__slider-navigate {
      margin-top: auto; }
      .page-post .page-post__slider .page-post__slider-navigate .btn,
      .page-post .page-post__slider .page-post__slider-navigate .mg-btn {
        line-height: 2; }
    .page-post .page-post__slider .page-post__slider-img {
      height: 0;
      padding-bottom: 93%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      .page-post .page-post__slider .page-post__slider-img iframe {
        width: 100%; }
    .page-post .page-post__slider .page-post__slider-content p {
      color: #4d4d4d;
      font-size: 16px;
      font-weight: 400;
      line-height: 1; }
      .page-post .page-post__slider .page-post__slider-content p.title {
        color: #00419f;
        font-size: 40px;
        font-weight: 500; }
    .page-post .page-post__slider .page-post__slider-text {
      min-height: 85px;
      overflow: hidden;
      margin-bottom: 10px; }
  .page-post .page-post__slide {
    width: 100%;
    display: flex;
    padding: 30px; }
    .page-post .page-post__slide.page-post__slide-video .page-post__image {
      flex: 0 0 40%;
      max-width: 40%; }
    .page-post .page-post__slide.page-post__slide-video .page-post__content {
      flex: 0 0 60%;
      max-width: 60%; }
    .page-post .page-post__slide.page-post__slide-video .page-post__slider-img {
      padding-bottom: 55%;
      background-size: contain; }
    .page-post .page-post__slide.page-post__slide-video iframe {
      width: 100%; }
  .page-post .page-post__image {
    flex: 0 0 25%;
    max-width: 25%; }
  .page-post .page-post__content {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 35px;
    box-sizing: border-box; }
  .page-post .page-post__slider-wrapper {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
    background-color: #fff; }
  .page-post .page-post__slider-btn {
    position: absolute;
    right: 30px;
    bottom: 30px;
    z-index: 9; }
  .page-post .page-post__header-sub .page-post__header {
    position: static;
    max-height: 150px;
    overflow: hidden; }
  .page-post .page-post__header {
    position: absolute;
    bottom: 0;
    padding-bottom: 24px; }
    .page-post .page-post__header h1 {
      color: #ffffff;
      font-size: 46px;
      font-weight: 700;
      margin-bottom: 15px;
      line-height: 1.4; }
    .page-post .page-post__header div,
    .page-post .page-post__header p {
      color: #ffffff;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 0; }
    .page-post .page-post__header .page-post__tag-list {
      position: absolute;
      top: -45px; }
    .page-post .page-post__header .page-post__info-title {
      margin-right: 5px; }
    .page-post .page-post__header .page-post__info-list {
      display: flex;
      position: relative; }
      .page-post .page-post__header .page-post__info-list::before {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        left: -5px;
        top: -5px;
        background-color: #000000;
        z-index: 1; }
      .page-post .page-post__header .page-post__info-list .page-post__info-item {
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 16px;
        line-height: 1.2;
        white-space: nowrap;
        z-index: 2; }
        .page-post .page-post__header .page-post__info-list .page-post__info-item span {
          padding-left: 0;
          line-height: 1.2; }
        .page-post .page-post__header .page-post__info-list .page-post__info-item strong {
          font-weight: 700; }
        .page-post .page-post__header .page-post__info-list .page-post__info-item a {
          color: #fff; }
      .page-post .page-post__header .page-post__info-list .page-post__info-link {
        color: #fff;
        margin-left: 0;
        margin-bottom: 5px; }
        .page-post .page-post__header .page-post__info-list .page-post__info-link:first-child {
          margin-left: 0; }
  .page-post .page-post__header-bg {
    padding-bottom: 20%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0;
    min-height: 375px;
    position: relative;
    overflow: hidden; }
    .page-post .page-post__header-bg::before {
      content: '';
      width: 100%;
      display: block;
      background-image: linear-gradient(360deg, black 0%, black 25%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.69;
      z-index: 0;
      position: absolute;
      height: 50%;
      bottom: 0;
      left: 0; }
    .page-post .page-post__header-bg .page-post__header-sub {
      position: relative;
      padding: 11px 0; }
      .page-post .page-post__header-bg .page-post__header-sub::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.12; }
    .page-post .page-post__header-bg .breadcrumbs .breadcrumbs__item {
      padding: 11px 0; }
      .page-post .page-post__header-bg .breadcrumbs .breadcrumbs__item::after {
        color: #fff; }
    .page-post .page-post__header-bg .breadcrumbs .breadcrumbs__link {
      color: #fff; }
  .page-post .main-content .main-content__post {
    border-bottom: 1px solid #cfcfcf;
    word-wrap: anywhere;
    width: 100%; }
    .page-post .main-content .main-content__post p,
    .page-post .main-content .main-content__post li,
    .page-post .main-content .main-content__post span,
    .page-post .main-content .main-content__post a {
      word-wrap: anywhere; }
    .page-post .main-content .main-content__post .share-list {
      position: absolute;
      display: flex;
      flex-direction: column;
      transform: rotate(90deg);
      transform-origin: left top;
      left: -15px;
      top: 40px; }
      .page-post .main-content .main-content__post .share-list .sfsi_wDiv .sfsi_wicons {
        transform: rotate(-90deg);
        margin-left: 0; }
  .page-post .main-content .main-content__author {
    background-color: #f3f3f3;
    border: 1px solid #c6c6c6; }
    .page-post .main-content .main-content__author .main-content__author-logo {
      border-radius: 50%;
      padding: 15px;
      flex-shrink: 0; }
      .page-post .main-content .main-content__author .main-content__author-logo img {
        display: inline-block;
        width: 96px;
        height: 96px;
        border-radius: 50%; }
    .page-post .main-content .main-content__author .main-content__author-desc {
      padding: 15px; }
      .page-post .main-content .main-content__author .main-content__author-desc p.title {
        font-weight: 500;
        margin-bottom: 7px; }
  .page-post .block-widget.block-widget-subscribe.block-widget-absolute {
    padding-top: 36px; }
    .page-post .block-widget.block-widget-subscribe.block-widget-absolute .block-widget__title {
      position: absolute;
      width: 100%;
      bottom: 100%;
      z-index: 2;
      margin-bottom: 0; }

.category-list {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 15px 45px; }
  .category-list.category-list_video {
    background-color: #0a0057;
    padding: 0; }
    .category-list.category-list_video .category-list__menu {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;
      padding-left: 0; }
    .category-list.category-list_video .category-list__item {
      flex-basis: initial;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      width: auto; }
      .category-list.category-list_video .category-list__item:focus {
        outline: none; }
      .category-list.category-list_video .category-list__item::before {
        display: none; }
    .category-list.category-list_video .category-list__link {
      color: #fff;
      background-color: transparent;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      line-height: normal;
      padding: 22px 5px;
      display: inline-block;
      border: none;
      width: 100%;
      transition: all 0.15s ease-in;
      outline: none; }
      .category-list.category-list_video .category-list__link:hover {
        opacity: 0.7; }
  .category-list p.title {
    margin-bottom: 10px; }
  .category-list .category-list__items {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
  .category-list .category-list__item {
    flex-basis: 20%;
    width: 100%;
    padding: 4px 20px 4px 15px;
    position: relative; }
    .category-list .category-list__item::before {
      content: '';
      display: inline-block;
      position: absolute;
      background-image: url("../img/sprite.svg#arrow-navigate");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 11px;
      width: 6px;
      top: 11px;
      left: 0; }
    .category-list .category-list__item.active {
      background-color: #00419f; }
  .category-list .category-list__link {
    color: #00204e;
    font-size: 18px;
    font-weight: 400; }

.top-bank .top-bank__item .top-bank__item-content {
  padding: 0 8% 1px;
  margin-top: -6px; }
  .top-bank .top-bank__item .top-bank__item-content .top-bank__item-tags {
    list-style: none;
    padding-left: 0; }
  .top-bank .top-bank__item .top-bank__item-content .top-bank__tag {
    border-bottom: 1px solid #f0f0f0; }
    .top-bank .top-bank__item .top-bank__item-content .top-bank__tag:last-child {
      border-bottom: none; }
  .top-bank .top-bank__item .top-bank__item-content .top-bank__link {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 6px 0;
    align-items: center;
    color: #000000;
    font-size: 18px;
    font-weight: 400; }
    .top-bank .top-bank__item .top-bank__item-content .top-bank__link:hover {
      text-decoration: none; }
      .top-bank .top-bank__item .top-bank__item-content .top-bank__link:hover span {
        text-decoration: underline; }
      .top-bank .top-bank__item .top-bank__item-content .top-bank__link:hover .top-bank__count {
        text-decoration: none; }
  .top-bank .top-bank__item .top-bank__item-content .top-bank__count {
    display: inline-block;
    padding: 4px 18px;
    border-radius: 20px;
    background-color: #f0f0f0;
    color: #000;
    text-decoration: none; }

.item-title {
  height: 161px;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  background-color: #fff; }
  .item-title::before {
    content: '';
    display: block;
    height: 41%;
    left: 0;
    top: 0;
    background-image: linear-gradient(98deg, #0a0057 0%, #3d01a1 46%, #4087ec 100%); }
  .item-title::after {
    content: '';
    display: block;
    width: 97px;
    height: 97px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 10%;
    padding: 5%; }
  .item-title p,
  .item-title a {
    margin-bottom: 0; }
    .item-title p.title,
    .item-title a.title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      color: #0a0057;
      font-size: 24px;
      font-weight: 700; }

.mega-icon {
  background-size: 0; }
  .mega-icon::after {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 53%; }
  .mega-icon.mega-icon-parent::after {
    background-image: inherit; }
  .mega-icon.mega-icon-industry::after {
    background-image: url("../img/sprite.svg#top-icon1"); }
  .mega-icon.mega-icon-area::after {
    background-image: url("../img/sprite.svg#top-icon2"); }
  .mega-icon.mega-icon-service::after {
    background-image: url("../img/sprite.svg#top-icon3"); }
  .mega-icon.mega-icon-calculate::after {
    background-image: url("../img/sprite.svg#calculator"); }
  .mega-icon.mega-icon-checker::after {
    background-image: url("../img/sprite.svg#checker"); }
  .mega-icon.mega-icon-news::after {
    background-image: url("../img/sprite.svg#news"); }

.bank-single .bank-single__header {
  background-color: #fff;
  padding-top: 45px; }
  .bank-single .bank-single__header h1 {
    color: #0a0057;
    line-height: 1.2;
    margin-bottom: 41px; }
  .bank-single .bank-single__header .bank-single__header-inner p {
    line-height: 1.2; }
  .bank-single .bank-single__header .bank-single__header-inner .title {
    line-height: 1.2;
    color: #000000;
    font-size: 24px;
    font-weight: 300; }

.bank-single .wrp-bank-single__body {
  background-color: #fff; }

.bank-single .bank-single__body {
  background-color: #fff;
  position: relative;
  padding: 0;
  margin-bottom: 0;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
  height: auto;
  /*.sidebar {
            padding-left: 25px;
        }*/ }
  .bank-single .bank-single__body .bank-single__presentation .bank-single__presentation-img {
    position: relative; }
    .bank-single .bank-single__body .bank-single__presentation .bank-single__presentation-img:hover::before, .bank-single .bank-single__body .bank-single__presentation .bank-single__presentation-img:hover::after {
      content: '';
      position: absolute;
      pointer-events: none; }
    .bank-single .bank-single__body .bank-single__presentation .bank-single__presentation-img:hover::before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: #000000;
      opacity: 0.12; }
    .bank-single .bank-single__body .bank-single__presentation .bank-single__presentation-img:hover::after {
      background-color: #fff;
      width: 33px;
      height: 33px;
      bottom: 0;
      right: 0;
      background-image: url("../img/sprite.svg#zoom");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50%; }
  .bank-single .bank-single__body .bank-single__presentation img {
    width: 100%; }
  .bank-single .bank-single__body .bank-single__body-content {
    padding: 35px 45px; }
    .bank-single .bank-single__body .bank-single__body-content p {
      color: #7a7a7a;
      font-size: 16px;
      font-weight: 300; }
      .bank-single .bank-single__body .bank-single__body-content p .title {
        margin-bottom: 25px; }
    .bank-single .bank-single__body .bank-single__body-content ul {
      color: #7a7a7a; }
    .bank-single .bank-single__body .bank-single__body-content p,
    .bank-single .bank-single__body .bank-single__body-content li,
    .bank-single .bank-single__body .bank-single__body-content td {
      color: #7a7a7a; }
      .bank-single .bank-single__body .bank-single__body-content p.date,
      .bank-single .bank-single__body .bank-single__body-content li.date,
      .bank-single .bank-single__body .bank-single__body-content td.date {
        color: #4d4d4d; }
      .bank-single .bank-single__body .bank-single__body-content p span,
      .bank-single .bank-single__body .bank-single__body-content li span,
      .bank-single .bank-single__body .bank-single__body-content td span {
        line-height: 1.6; }
  .bank-single .bank-single__body .main-content,
  .bank-single .bank-single__body .sidebar {
    padding: 15px 0;
    width: 100%; }
  .bank-single .bank-single__body .main-content {
    padding-left: 30px; }
  .bank-single .bank-single__body .sidebar {
    padding-right: 30px; }
  .bank-single .bank-single__body .bank-single__body-inner::before {
    content: '';
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    height: 8px;
    background-image: linear-gradient(253deg, #0a0057 0%, #00419f 43%, #3d01a1 100%); }

.bank-single .bank-single__rating .bank-widget {
  background-color: #fff;
  padding: 0; }
  .bank-single .bank-single__rating .bank-widget > * {
    text-align: right; }
  .bank-single .bank-single__rating .bank-widget .bank-widget__rating {
    color: #00419f;
    font-size: 73px;
    font-weight: 500; }
    .bank-single .bank-single__rating .bank-widget .bank-widget__rating::before {
      right: 150px;
      top: 4%; }

.bank-single .bank-single__share {
  display: flex;
  flex-wrap: nowrap; }
  .bank-single .bank-single__share p {
    line-height: 1.2;
    margin-right: 10px;
    margin-bottom: 0; }
  .bank-single .bank-single__share .soc-icon {
    margin: 0 3px; }

.bank-single .bank-single__tags .bank-single__tags-list {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  align-items: flex-start;
  flex-wrap: wrap; }

.bank-single .bank-single__tags .bank-single__elem {
  margin-right: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 125px;
  align-items: center;
  align-content: center;
  margin-bottom: 15px; }
  .bank-single .bank-single__tags .bank-single__elem:last-child {
    margin-right: 0; }
  .bank-single .bank-single__tags .bank-single__elem:first-child {
    margin-left: 0; }

.bank-single .bank-single__tags .bank-single__tag {
  color: #000000;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .bank-single .bank-single__tags .bank-single__tag img {
    display: inline-block; }
  .bank-single .bank-single__tags .bank-single__tag.tag-icon {
    width: 105px;
    height: 105px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    background-color: #f8f8f8;
    position: relative;
    margin-top: 0; }

.bank-single .block-widget .block-widget__title {
  padding-bottom: 32px; }

.widget-block .widget-block__content {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  flex-basis: 100%; }
  .widget-block .widget-block__content p.title {
    width: 100%;
    color: #000000;
    font-size: 24px;
    line-height: 1;
    font-weight: 300;
    margin-bottom: 0;
    position: relative; }
    .widget-block .widget-block__content p.title .mg-icon {
      left: 10px;
      position: absolute;
      background-size: contain;
      transform: translateX(-50%); }
      .widget-block .widget-block__content p.title .mg-icon.mg-icon__youtube::before {
        width: 68px;
        height: 60px; }
      .widget-block .widget-block__content p.title .mg-icon.mg-icon__medal::before {
        width: 68px;
        height: 60px; }

.widget-block .widget-block__cell {
  display: flex;
  width: 100%;
  margin-right: 10px;
  height: 100%;
  position: relative; }
  .widget-block .widget-block__cell.widget-block__col {
    flex-wrap: nowrap;
    flex-direction: column; }
  .widget-block .widget-block__cell:last-child {
    margin-right: 0; }

.page-post {
  position: relative;
  z-index: 1; }
  .page-post.page-contact .page-post__header-bg {
    padding-bottom: 30%; }
    .page-post.page-contact .page-post__header-bg::before {
      height: 100%;
      background-image: linear-gradient(252deg, #0a0057 0%, #00419f 73%, #3d01a1 100%);
      opacity: 0.41; }
  .page-post.page-contact .page-post__header {
    top: 45%;
    transform: translateY(-50%);
    width: 100%; }
  .page-post.page-contact .page-contact__content {
    position: relative;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
    padding: 66px 0 47px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around; }
    .page-post.page-contact .page-contact__content::before {
      content: '';
      position: absolute;
      top: -8px;
      width: 100%;
      z-index: 2;
      height: 8px;
      background-image: linear-gradient(270deg, #4087ec 0%, #0a0057 100%);
      left: 0; }
    .page-post.page-contact .page-contact__content .page-contact__content-base {
      flex-basis: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      width: 100%;
      padding: 0 25px 0 76px; }
    .page-post.page-contact .page-contact__content .page-contact__content-data {
      padding: 0 35px 35px;
      flex-basis: 56%;
      flex-grow: 1;
      flex-shrink: 1;
      margin-left: 2%;
      border-left: 1px solid #888888; }
      .page-post.page-contact .page-contact__content .page-contact__content-data .title {
        color: #0a0057;
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
        line-height: normal; }
    .page-post.page-contact .page-contact__content .page-contact__content-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center; }
      .page-post.page-contact .page-contact__content .page-contact__content-links .partner-list {
        max-width: 275px; }
      .page-post.page-contact .page-contact__content .page-contact__content-links .social-list {
        text-align: center; }
        .page-post.page-contact .page-contact__content .page-contact__content-links .social-list .soc-icon {
          height: 30px;
          width: 30px; }

.contact-links {
  padding-left: 10px; }
  .contact-links .contact-links__list {
    padding-left: 0;
    list-style: none; }
  .contact-links .contact-links__item {
    margin-bottom: 12px; }
    .contact-links .contact-links__item:last-child {
      margin-bottom: 0; }
  .contact-links .contact-links__link {
    line-height: 1;
    padding: 3px 0;
    display: flex;
    align-content: center;
    align-items: center;
    color: #0a0057;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal; }
    .contact-links .contact-links__link .mg-icon {
      margin-right: 15px; }

.wrp-page-contact__content {
  transform: translateY(-183px);
  background-color: #fff; }

.social-list {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center; }

.container {
  max-width: 1400px; }

.mm-navbar .soc-icon::before {
  max-height: 25px; }

@media screen and (max-width: 1500px) {
  .page-post.page-contact .page-post__header {
    position: static;
    transform: initial;
    padding-top: 5px; }
    .page-post.page-contact .page-post__header h1 {
      font-size: 42px;
      line-height: 1.1; }
    .page-post.page-contact .page-post__header p {
      font-size: 20px; } }

@media screen and (max-width: 1370px) {
  .news-widget.news-widget__video .news-widget__list {
    justify-content: space-between; }
  .news-widget.news-widget__video .news-widget__item {
    margin-right: 0; }
  .sign-up-block {
    margin-top: 115px; }
  .wrp-page-contact__content {
    transform: translateY(-106px);
    background-color: #fff; } }

/**
  Планшет горизонтальный
 */
@media screen and (max-width: 1200px) {
  .xl-visible {
    display: block; }
  .container {
    max-width: 100%; }
  .header-block .header-block__row-1 {
    height: auto; }
  .news-widget {
    max-height: initial; }
    .news-widget.news-widget__type2 .news-widget__preview .news-widget__preview-content {
      padding: 15px 10px; }
      .news-widget.news-widget__type2 .news-widget__preview .news-widget__preview-content .title {
        font-size: 24px;
        margin-bottom: 10px; }
    .news-widget .news-widget__desc {
      padding: 15px; }
  .page-post.page-archive .wrp-page-post__slider {
    height: auto;
    transform: initial; }
  .page-post.page-archive .page-post__header-bg {
    min-height: initial;
    height: auto;
    padding-bottom: 0;
    margin-bottom: 30px; }
    .page-post.page-archive .page-post__header-bg::before {
      display: none; }
    .page-post.page-archive .page-post__header-bg .page-post__header-sub::before {
      content: '';
      width: 100%;
      display: block;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.19;
      z-index: 0;
      position: absolute;
      height: 100%;
      bottom: 0;
      left: 0; }
    .page-post.page-archive .page-post__header-bg .page-post__header-sub .page-post__header {
      max-height: initial;
      overflow: visible; }
  .page-post .page-post__header h1 {
    font-size: 40px;
    line-height: 43px; }
  .page-post.page-contact .page-post__header-bg {
    height: initial;
    padding-bottom: 30px; }
  .page-post .page-post__header div,
  .page-post .page-post__header p {
    font-size: 18px; }
  .page-post .page-post__slider .page-post__slider-content p.title {
    font-size: 24px; }
  .wrp-page-contact__content {
    transform: translateY(-105px); }
  .page-video-center h1 {
    padding-left: 70px; }
    .page-video-center h1::after {
      height: 43px; }
  .sign-up-block {
    margin-top: 100px; }
  .news-item .news-item__content {
    padding-top: 3%; } }

@media screen and (max-width: 991px) {
  .lg-visible {
    display: block; }
  .lg-hidden {
    display: none; }
  p.title {
    font-size: 20px; }
  .header-block .header-block__row-1 {
    height: auto; }
  .header-block .header-block__row-2 {
    display: none; }
  .header-block .header-block__row-3 {
    display: block; }
  .row-page-block .page-block__body {
    flex-wrap: wrap; }
  .row-page-block .sidebar {
    flex-basis: 100%;
    margin: 0 auto; }
  form .form-group.form-group__inline {
    flex-wrap: wrap;
    flex-basis: 100%;
    margin-bottom: 15px; }
    form .form-group.form-group__inline .form-group__inner {
      margin-bottom: 15px; }
      form .form-group.form-group__inline .form-group__inner:last-child {
        margin-bottom: 0; }
    form .form-group.form-group__inline > .btn,
    form .form-group.form-group__inline .mg-btn {
      margin-left: auto; }
  form .form-group .form-group__inner {
    margin: 0 0 15px;
    flex-basis: 100%; }
    form .form-group .form-group__inner:last-child {
      margin-bottom: 0; }
    form .form-group .form-group__inner.form-group__btn {
      width: 100%;
      max-width: initial; }
  .sign-up-block {
    margin-top: 85px; }
  .landing-form .landing-form__inner {
    padding: 15px; }
  .landing-form .landing-form__content p {
    font-size: 18px; }
    .landing-form .landing-form__content p.title {
      font-size: 26px; }
  .wrp-main-search .main-search .form .form-group .form-group__inner {
    margin: 0 0 15px; }
  .news-widget {
    max-height: initial; }
    .news-widget .news-widget__desc {
      min-height: initial; }
  .widget-block .widget-block__content.news-widget {
    flex-wrap: wrap; }
  .widget-block .widget-block__cell {
    margin-bottom: 15px;
    flex-wrap: wrap; }
    .widget-block .widget-block__cell .read-more {
      margin-left: auto;
      margin-top: 15px; }
    .widget-block .widget-block__cell:last-child {
      margin-bottom: 0; }
  .bank-single .bank-single__header h1 {
    line-height: 1;
    margin-bottom: 15px; }
  .bank-single .bank-single__body .main-content {
    padding: 15px; }
  .bank-single .bank-single__body .sidebar {
    padding: 15px; }
  .bank-single .bank-single__body .bank-single__body-content {
    padding: 15px; }
  .row-page-block {
    flex-wrap: wrap; }
    .row-page-block .flex-row-reverse .main-content,
    .row-page-block .flex-row-reverse .sidebar {
      padding: 30px; }
    .row-page-block .flex-row-reverse .main-content {
      order: 2; }
    .row-page-block .flex-row-reverse .sidebar {
      order: 1;
      border-right: 0; }
      .row-page-block .flex-row-reverse .sidebar::after {
        display: none; }
    .row-page-block .main-content,
    .row-page-block .sidebar {
      margin-bottom: 30px; }
  .page-single .page-single__header {
    padding-bottom: 10px; }
    .page-single .page-single__header h1 {
      font-size: 32px; }
    .page-single .page-single__header .news-tag {
      top: -30px; }
  .page-single .block-widget.block-widget-subscribe {
    padding-top: 0; }
    .page-single .block-widget.block-widget-subscribe .block-widget__title {
      position: relative;
      margin-bottom: 36px; }
  .footer-block {
    padding-bottom: 15px; }
    .footer-block .footer-block__menu {
      padding: 0 15%; }
  .ms-tabs-content {
    padding: 0 15px;
    margin-bottom: 30px; }
  .wrp-main-content {
    padding-top: 68px; }
  .page-post .page-post__image,
  .page-post .page-post__content {
    flex: 0 0 50%;
    max-width: 50%; }
  .page-post .page-post__header .page-post__info-list {
    display: flex;
    flex-wrap: nowrap; }
    .page-post .page-post__header .page-post__info-list::before {
      width: calc(96% + 10px); }
  .category-list.category-list_video .category-list__menu {
    flex-wrap: wrap; }
  .ms-tabs-content > div {
    display: block; }
  .ms-visible {
    display: block; }
  .ms-hidden {
    display: none; }
  .ms-visible.spoiler-trigger + .spoiler-block {
    display: none; }
  .ms-visible.spoiler-trigger + .spoiler-block.active {
    display: block; }
  .ms-spoiler-block {
    display: none;
    padding-top: 15px; }
  .ms-spoiler-block.active {
    display: block; }
  .ms-spoiler-block .ms-hidden {
    display: block; }
  .wrp-page-contact__content {
    transform: translateY(-70px); }
  .page-post {
    /*.page-post__header {
            .page-post__info-list {
                flex-wrap: wrap;

                .page-post__info-item {
                    flex-basis: 100%;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }*/ }
    .page-post.page-contact .page-post__header {
      position: static;
      transform: initial;
      padding-top: 5px; }
      .page-post.page-contact .page-post__header h1 {
        margin-bottom: 7px; }
    .page-post.page-contact .page-contact__content {
      padding-top: 35px; }
    .page-post .block-widget.block-widget-subscribe.block-widget-absolute {
      padding-top: 0; }
      .page-post .block-widget.block-widget-subscribe.block-widget-absolute .block-widget__title {
        position: relative; }
  .block-widget {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }
    .block-widget .block-widget__body {
      padding-top: 10%; }
    .block-widget.block-widget-image {
      margin-bottom: 45px; }
    .block-widget.block-widget-subscribe_min {
      transform: initial;
      max-width: initial; }
      .block-widget.block-widget-subscribe_min .block-widget__youtube-count {
        margin-right: 30px; }
        .block-widget.block-widget-subscribe_min .block-widget__youtube-count:last-child {
          margin-right: 0; }
    .block-widget.block-widget-list .block-widget__body {
      padding: 15px; }
    .block-widget.h100 .block-widget__body {
      height: initial; }
  .category-list .category-list__items {
    display: block; }
  .category-list .category-list__item {
    display: inline-block;
    width: auto; }
  .page-post.page-post_single {
    /*.page-post__info-list {
                .page-post__info-item {
                    border-left: none;
                    margin-bottom: 5px;
                    display: block;
                    margin-left: 0;
                    padding: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }*/ }
    .page-post.page-post_single .page-post_border {
      padding-right: 0; }
  .page-post .page-post__slider .page-post__slider-text {
    max-height: initial; } }

@media screen and (max-width: 901px) {
  .page-post.page-contact .page-contact__content .page-contact__content-links {
    flex-wrap: wrap;
    justify-content: center; }
    .page-post.page-contact .page-contact__content .page-contact__content-links > * {
      flex-basis: 100%; }
    .page-post.page-contact .page-contact__content .page-contact__content-links .partner-list {
      margin-bottom: 15px; } }

/**
  Планшет вертикальный
 */
@media screen and (max-width: 767px) {
  body {
    line-height: 1.2; }
  h1 {
    font-size: 32px; }
  p.title {
    font-size: 20px;
    font-weight: 700; }
  .sidebar {
    padding: 0 15%;
    max-width: 100%; }
  .top-bank .top-bank__item {
    margin-bottom: 15px; }
  .row-page-block .flex-row-reverse .sidebar {
    margin-bottom: 0; }
  .row-page-block .flex-row-reverse .block-widget:last-child {
    margin-bottom: 0; }
  .row-page-block .main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
  .bank-list .bank-list__item {
    flex-wrap: wrap;
    max-width: 400px;
    margin: 0 auto 30px;
    border-left: none;
    padding-bottom: 10px; }
  .bank-list .bank-list__item-wrp-img {
    display: none; }
  .bank-list .bank-list__item-img {
    display: none; }
  .bank-list .bank-list__item-content {
    margin: 0 15px;
    padding: 0 15px; }
    .bank-list .bank-list__item-content .bank-list__item-text {
      max-height: initial; }
  .bank-list .bank-list__item-rating {
    max-width: initial;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 0 0 30px; }
    .bank-list .bank-list__item-rating .wrp-bank-widget__rating {
      margin-bottom: 0;
      order: 2;
      background-color: #f6f6f6;
      max-width: 133px;
      align-self: flex-end;
      padding: 10px 0; }
    .bank-list .bank-list__item-rating .bank-widget__read-more {
      order: 1;
      max-width: 160px;
      padding-left: 0;
      padding-right: 0; }
  .bank-list .bank-widget > * {
    margin-bottom: 15px; }
    .bank-list .bank-widget > *:last-child {
      margin-bottom: 0; }
  .bank-list .bank-widget .bank-widget__rating {
    font-size: 25px;
    line-height: 30px; }
  .bank-list .bank-widget .bank-widget__desc {
    font-size: 10px;
    line-height: 13px; }
  .bank-list .bank-list__read-more {
    margin: 0 auto;
    flex-basis: 100%; }
  .item-tag {
    font-size: 10px; }
  .list-tools {
    flex-basis: 100%; }
    .list-tools .list-tools__read-more {
      margin: 0; }
  .page-block p.title {
    margin-bottom: 10px; }
  .page-block .page-block__header {
    padding-left: 15px;
    padding-right: 15px; }
  .sign-up-block .sign-up-block__content p.title {
    font-size: 24px;
    font-weight: 700; }
  .block-widget {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }
    .block-widget:last-child {
      margin-bottom: 45px; }
    .block-widget.block-widget-list {
      padding-top: 10px; }
    .block-widget.block-widget-subscribe_min {
      max-width: 100%; }
  .sign-up-block .news-item .news-item__img {
    position: relative; }
  .bank-single .bank-single__rating .bank-widget {
    margin-top: 0; }
  .bank-single .bank-single__body .bank-single__body-content p {
    margin-bottom: 10px; }
  .table-details tr th {
    padding: 10px 5px; }
  .sign-up-block {
    margin-top: 0; }
    .sign-up-block .sign-up-block__inner {
      padding-top: 30px; }
      .sign-up-block .sign-up-block__inner .news-list {
        margin-bottom: 0; }
      .sign-up-block .sign-up-block__inner .news-item {
        margin-bottom: 30px;
        box-shadow: none; }
    .sign-up-block .sign-up-block__content p {
      font-size: 18px; }
      .sign-up-block .sign-up-block__content p.title {
        font-size: 34px; }
  .footer-block .footer-block__menu {
    padding: 0 15px; }
  .table-details tr td {
    padding: 5px 15px; }
    .table-details tr td:first-child {
      padding: 15px 30px; }
  .page-single .page-single__header-bg {
    padding-bottom: 40%; }
  .bank-list .bank-list__item-content .bank-list__item-logo {
    margin: 7px 0; }
  .bank-single .bank-single__tags .bank-single__tags-list {
    justify-content: space-between; }
    .bank-single .bank-single__tags .bank-single__tags-list .bank-single__elem {
      margin: 0 0 10px 0; }
  .bank-single .bank-single__tags .bank-single__tag {
    margin-top: 5px; }
  .page-post .page-post__image,
  .page-post .page-post__content {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%; }
  .page-post.page-contact .page-contact__content {
    flex-wrap: wrap;
    padding-top: 5px; }
    .page-post.page-contact .page-contact__content .page-contact__content-base {
      padding: 15px; }
    .page-post.page-contact .page-contact__content .page-contact__content-data {
      margin-left: 0;
      border-left: 0; }
  .category-list {
    padding: 15px; }
  .wrp-page-contact__content {
    transform: translateY(-58px); }
  .news-widget .news-widget__img {
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    background-size: cover; }
  .news-widget.news-widget__type2 .news-widget__img {
    width: 100%; }
  .news-widget.news-widget__type2 .news-widget__content {
    padding: 15px 5px 20px 5px; }
    .news-widget.news-widget__type2 .news-widget__content .title {
      height: auto;
      margin-bottom: 10px;
      flex-basis: 100%; }
    .news-widget.news-widget__type2 .news-widget__content .desc {
      max-height: initial;
      margin-bottom: 10px;
      padding-bottom: 3px; }
  .news-widget.news-widget__type2 .news-widget__preview {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 66%; }
  .news-widget.news-widget__type2 .widget-block__cell {
    order: 2;
    margin-right: 0; }
  .news-widget.news-widget__type2 .widget-block__col {
    order: 1;
    margin-bottom: 30px; }
  .news-widget .news-widget__item {
    flex-wrap: wrap;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }
  .news-widget .news-widget__list .mCSB_container {
    padding: 0; }
  .news-widget .news-widget__content .date {
    position: static;
    text-align: right;
    margin-top: 5px; }
  .widget-block .news-widget.news-widget__type2 .news-widget__content {
    padding-bottom: 20px; }
  .news-widget.news-widget__video .news-widget__item {
    max-width: 46%; }
  .news-widget.news-widget__video .news-widget__content {
    height: auto; }
  .wrp-main-search .main-search .form {
    padding: 15px; }
  .wrp-main-search .main-search p.title {
    font-size: 28px; }
  .wrp-main-search .main-search p.sub-title {
    font-size: 18px; }
  .page-post .page-post__slide {
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px; }
    .page-post .page-post__slide.page-post__slide-video .page-post__image,
    .page-post .page-post__slide.page-post__slide-video .page-post__content {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%; }
  .page-post .page-post__content {
    padding: 10px 0 0 10px; }
  .page-post .page-post__slider::before {
    display: none; }
  .page-post .page-post__slider .page-post__slider-content p.title {
    font-size: 22px; }
  .page-post .page-post__slider .page-post__slider-tools {
    margin-bottom: 10px; }
  .page-post .page-post__slider .page-post__slider-text {
    margin-bottom: 15px; }
  .page-post .page-post__slider .page-post__slider-img {
    padding-bottom: 60%; }
  .page-post .page-post__slider-btn {
    bottom: 10px;
    right: 10px; }
  .bank-list .bank-list__read-more {
    max-width: 400px; }
  .list-tools {
    max-width: 400px; }
  .news-item {
    width: 380px;
    margin: 0 auto; }
  .bank-single .bank-single__header .bank-single__header-inner h1 {
    margin-bottom: 7px; }
  .bank-single .bank-single__header .bank-single__header-inner .title {
    font-size: 16px;
    line-height: 1; }
  .breadcrumbs {
    margin-bottom: 10px; }
    .breadcrumbs .breadcrumbs__link {
      font-size: 14px; }
  .page-video-center h1::after {
    height: 36px; } }

/**
  Мобильные устройства
 */
@media screen and (max-width: 576px) {
  .news-widget.news-widget__type2 .news-widget__item {
    flex-wrap: wrap; }
  .news-widget.news-widget__type2 .news-widget__content .desc {
    display: none; }
  .news-widget.news-widget__type2 .news-widget__content .date {
    display: none; }
  .sidebar {
    padding: 0; }
  .news-widget.news-widget__type2 .news-widget__list {
    padding: 0; }
  .block-widget.block-widget-subscribe_min .block-widget__content p {
    text-align: center; }
    .block-widget.block-widget-subscribe_min .block-widget__content p.title {
      font-size: 20px; }
  .block-widget.block-widget-subscribe_min .block-widget__youtube {
    flex-wrap: wrap;
    justify-content: center; }
    .block-widget.block-widget-subscribe_min .block-widget__youtube .block-widget__youtube-count {
      margin-right: 0;
      flex-basis: 100%;
      width: 100%;
      display: flex;
      justify-content: center; }
      .block-widget.block-widget-subscribe_min .block-widget__youtube .block-widget__youtube-count:last-child {
        margin-bottom: 15px; }
  .block-widget.block-widget-video .block-widget__title p.title {
    font-size: 18px; }
  .block-widget .block-widget__title {
    padding: 0 5%; }
  .page-single .page-single__header-bg {
    padding-bottom: 60%; }
  .page-post .page-post__header h1 {
    font-size: 32px;
    line-height: 1; }
  .page-post .page-post__header-bg::before {
    opacity: 0.75;
    height: 75%; }
  .row-page-block .flex-row-reverse .main-content,
  .row-page-block .flex-row-reverse .sidebar {
    padding: 15px 0; }
  .btn-big,
  .bank-single__share {
    width: 100%; }
  h1 {
    font-size: 28px; }
  .bank-single .bank-single__header h1 {
    margin-bottom: 10px; }
  .bank-single .bank-single__tags .bank-single__tags-list {
    justify-content: center; }
  .wpdt-c .wpDataTablesWrapper .dataTables_paginate > span {
    display: none; } }

@media screen and (max-width: 480px) {
  .page-single .page-single__header-bg {
    padding-bottom: 80%; }
  .page-single .page-single__header h1 {
    max-height: 130px;
    overflow: hidden; }
  .table-details tr th {
    padding: 15px; }
    .table-details tr th:first-child {
      white-space: normal; }
  .table-details tr td {
    padding: 5px 15px; }
    .table-details tr td:first-child {
      padding: 15px; }
  .bank-icon::before {
    left: -8px; } }

@media screen and (max-width: 430px) {
  .bank-list .bank-list__item {
    max-width: 100%; }
  .news-widget.news-widget__video .news-widget__item {
    width: 100%;
    flex-basis: 100%;
    margin: 0 auto 35px auto;
    max-width: 100%;
    height: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }
  .news-widget.news-widget__video .news-widget__img {
    height: 100%; }
    .news-widget.news-widget__video .news-widget__img iframe {
      min-height: 225px; }
  .news-widget .news-widget__preview iframe {
    height: 211px; }
  .news-widget .news-widget__desc p {
    font-size: 18px; }
  .news-widget .news-widget__img {
    padding-bottom: 78%; } }

@media screen and (max-width: 390px) {
  .wpdt-c .wpDataTables.wpDataTablesWrapper {
    overflow-y: hidden;
    overflow-x: auto; }
    .wpdt-c .wpDataTables.wpDataTablesWrapper .paging_full_numbers {
      height: initial;
      padding-bottom: 10px; }
  .news-item {
    width: auto; }
  .header-block .header-block__row-1 {
    display: flex;
    flex-wrap: nowrap; } }

@media screen and (min-width: 1200px) {
  .btn.btn-xl-w0,
  .mg-btn.btn-xl-w0 {
    width: auto; } }

@media screen and (min-width: 992px) {
  .footer-block .footer-block__menu .ms-spoiler-trigger {
    background: none; }
  .btn.btn-lg-w0,
  .mg-btn.btn-lg-w0 {
    width: auto; } }

@media screen and (min-width: 768px) {
  .btn.btn-md-w0,
  .mg-btn.btn-md-w0 {
    width: auto; } }

@media screen and (min-width: 576px) {
  .btn.btn-sm-w0,
  .mg-btn.btn-sm-w0 {
    width: auto; } }

.modal.modal-widget .block-widget {
  margin: 0; }
  .modal.modal-widget .block-widget .block-widget__body {
    padding-top: 15px;
    padding-bottom: 15px; }
  .modal.modal-widget .block-widget.block-widget-subscribe .block-widget__title {
    padding: 55px 20% 15px; }
    .modal.modal-widget .block-widget.block-widget-subscribe .block-widget__title::before {
      border-radius: 50%;
      background-color: #4087ec;
      padding: 48px;
      background-size: 45%;
      top: -36%; }

.modal.modal-widget .modal__container {
  max-width: 700px;
  border-radius: 0;
  background-color: transparent;
  padding: 88px 0 0;
  overflow-x: hidden; }
  .modal.modal-widget .modal__container .modal__content {
    margin: 0; }

.modal.modal-widget .modal__footer {
  background-color: #f3f2f2;
  padding: 8px 15%; }

.modal.modal-widget .modal__close {
  position: absolute;
  right: 0;
  top: 55px; }

.modal .modal__close {
  background: transparent;
  border: 0;
  outline: none !important;
  width: 30px;
  height: 30px;
  line-height: 1;
  text-align: center;
  padding: 0;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out; }
  .modal .modal__close:before {
    content: '×';
    font-size: 30px;
    line-height: 1;
    color: #fff; }
  .modal .modal__close:hover {
    transform: scale(1.2); }

/**************************\
  Basic Modal Styles
\**************************/
.mg-modal.modal.top-content-modal .modal__overlay__inner {
  padding-top: 0;
  position: absolute;
  top: 0; }
  .mg-modal.modal.top-content-modal .modal__overlay__inner .modal__content {
    display: flex;
    flex-direction: column; }

.mg-modal.modal.modal-max-width .modal__overlay__inner {
  padding: 0; }
  .mg-modal.modal.modal-max-width .modal__overlay__inner .modal__close {
    color: #000; }

.mg-modal.modal.modal-max-width .modal__container {
  max-width: 100vw;
  width: 100%; }

.mg-modal.modal.header-content-modal .modal__overlay__inner {
  padding-top: 0;
  position: absolute;
  top: 148px; }
  .mg-modal.modal.header-content-modal .modal__overlay__inner .modal__content {
    display: flex;
    flex-direction: column; }
  .mg-modal.modal.header-content-modal .modal__overlay__inner .modal__container {
    padding: 50px 50px;
    max-width: 1400px; }

.mg-modal.modal.modal-filter .modal__overlay {
  background-color: #fff; }

.mg-modal.modal.modal-filter .modal__header {
  justify-content: flex-start;
  padding-right: 30px;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  opacity: 1; }
  .mg-modal.modal.modal-filter .modal__header .mg-btn-icon {
    padding: 0;
    border-radius: 0;
    height: 45px;
    width: 45px; }
    .mg-modal.modal.modal-filter .modal__header .mg-btn-icon::before {
      content: '×';
      display: block;
      color: #fff;
      font-weight: 300;
      font-size: 46px;
      background-color: #0a0057;
      line-height: 0.8;
      text-align: center;
      height: 100%;
      width: 100%; }
    .mg-modal.modal.modal-filter .modal__header .mg-btn-icon:hover::before {
      background-color: #10008a; }
  .mg-modal.modal.modal-filter .modal__header p.title {
    text-align: left;
    margin-left: 15px; }
  .mg-modal.modal.modal-filter .modal__header .clear-all {
    margin-left: auto; }
  .mg-modal.modal.modal-filter .modal__header .modal__close {
    display: block; }

.mg-modal.modal.modal-filter .modal__container {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100); }

.mg-modal.modal.modal-filter .modal__content {
  padding: 30px 55px 20px 55px;
  overflow-y: auto;
  height: 100%; }
  .mg-modal.modal.modal-filter .modal__content .modal__content__inner {
    padding-bottom: 20px; }
  .mg-modal.modal.modal-filter .modal__content .ms-spoiler-block {
    width: 100%;
    padding-top: 0; }
  .mg-modal.modal.modal-filter .modal__content form .form-group {
    margin-bottom: 10px; }
    .mg-modal.modal.modal-filter .modal__content form .form-group .title {
      cursor: pointer; }
    .mg-modal.modal.modal-filter .modal__content form .form-group .form-group__tools {
      border-bottom: 1px solid #afafaf; }
      .mg-modal.modal.modal-filter .modal__content form .form-group .form-group__tools .clear-all {
        display: none; }

.mg-modal.modal.modal-filter .mCSB_scrollTools {
  width: 16px; }

.mg-modal.modal.modal-filter .modal__footer {
  margin-bottom: 0;
  position: relative;
  width: 100%;
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 -3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  height: 100px;
  padding-bottom: 10px;
  padding-top: 10px; }
  .mg-modal.modal.modal-filter .modal__footer .btn-group {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  .mg-modal.modal.modal-filter .modal__footer .mg-btn {
    font-size: 18px;
    line-height: 22px;
    font-weight: 300; }
    .mg-modal.modal.modal-filter .modal__footer .mg-btn.btn-darkness {
      padding: 1rem 1.8rem; }
    .mg-modal.modal.modal-filter .modal__footer .mg-btn.btn-danger {
      padding: 1rem 1.8rem; }

.mg-modal.modal.modal-filter .modal__overlay__inner .modal__close {
  display: none; }

.mg-modal .modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center; }
  .mg-modal .modal__overlay.bg-dark {
    background-color: rgba(0, 0, 0, 0.75); }
    .mg-modal .modal__overlay.bg-dark .modal__content .modal__close:before {
      color: #000; }
    .mg-modal .modal__overlay.bg-dark .modal__close:before {
      color: #fff; }

.mg-modal .modal__overlay__inner {
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 85px 15px;
  margin-bottom: 85px; }
  .mg-modal .modal__overlay__inner .modal__close {
    position: absolute;
    right: 2px;
    top: 0;
    font-size: 24px;
    z-index: 1000;
    transition: opacity ease-in-out 0.3s;
    background-color: initial;
    color: #000; }
    .mg-modal .modal__overlay__inner .modal__close:hover {
      opacity: 0.7;
      text-decoration: none; }

.mg-modal .modal__container {
  background-color: #fff;
  padding: 32px 104px;
  max-width: 640px;
  margin: 0 auto;
  border-radius: 0;
  box-sizing: border-box;
  width: auto;
  height: auto;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16); }

.mg-modal .modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  .mg-modal .modal__header .modal__close {
    position: absolute;
    top: -25px;
    right: -25px; }
  .mg-modal .modal__header .modal__header__inner {
    display: flex;
    flex-wrap: wrap; }
    .mg-modal .modal__header .modal__header__inner > p {
      width: 100%;
      display: block; }

.mg-modal .modal__footer {
  height: auto;
  margin-bottom: 50px; }
  .mg-modal .modal__footer .modal_links {
    margin-top: 50px; }
  .mg-modal .modal__footer .modal_links__item {
    flex-basis: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    margin-bottom: 20px; }
    .mg-modal .modal__footer .modal_links__item:hover {
      text-decoration: underline; }
    .mg-modal .modal__footer .modal_links__item:last-child {
      margin-bottom: 0; }

.mg-modal .modal__title {
  margin-top: 0;
  box-sizing: border-box;
  margin-bottom: 11px; }

.mg-modal .modal__small-label {
  font-size: 14px;
  line-height: 1.55;
  font-weight: 400; }

.mg-modal .modal__content {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8); }
  .mg-modal .modal__content .errors-group {
    margin-bottom: 10px;
    text-align: center; }
    .mg-modal .modal__content .errors-group p {
      margin: 0; }
  .mg-modal .modal__content .modal__content__item {
    margin: 0 0 7px 0;
    font-size: 18px;
    font-weight: 700; }
    .mg-modal .modal__content .modal__content__item strong {
      display: inline-block;
      min-width: 35%; }
    .mg-modal .modal__content .modal__content__item span {
      display: inline-block;
      font-weight: 400; }
  .mg-modal .modal__content .modal__close:before {
    color: #000; }

.mg-modal .modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out; }
  .mg-modal .modal__btn:focus, .mg-modal .modal__btn:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }

.mg-modal .modal__btn-primary {
  background-color: #00449e;
  color: #fff; }

.mg-modal.micromodal-slide {
  display: none; }
  .mg-modal.micromodal-slide.is-open {
    display: block; }
  .mg-modal.micromodal-slide[aria-hidden='false'] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .mg-modal.micromodal-slide[aria-hidden='false'] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .mg-modal.micromodal-slide[aria-hidden='true'] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .mg-modal.micromodal-slide[aria-hidden='true'] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .mg-modal.micromodal-slide .modal__container,
  .mg-modal.micromodal-slide .modal__overlay {
    will-change: transform;
    z-index: 999; }

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes mmfadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mmslideIn {
  from {
    transform: translateY(-15%); }
  to {
    transform: translateY(0); } }

@keyframes mmslideInLeft {
  from {
    transform: translateX(-15%); }
  to {
    transform: translateX(0); } }

@keyframes mmslideOut {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(10%); } }

@keyframes mmslideOutLeft {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(10%); } }

.mg-modal.modal .modal__header, .mg-modal.md-modal .modal__header {
  height: auto; }
  .mg-modal.modal .modal__header .modal__title, .mg-modal.md-modal .modal__header .modal__title {
    width: 100%;
    text-align: center;
    color: #000000;
    font-size: 32px;
    margin-bottom: 0;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    padding-top: 64px; }
    .mg-modal.modal .modal__header .modal__title.modal__title_without-logo, .mg-modal.md-modal .modal__header .modal__title.modal__title_without-logo {
      padding-top: 0; }
      .mg-modal.modal .modal__header .modal__title.modal__title_without-logo::before, .mg-modal.md-modal .modal__header .modal__title.modal__title_without-logo::before {
        display: none; }
    .mg-modal.modal .modal__header .modal__title::before, .mg-modal.md-modal .modal__header .modal__title::before {
      content: '';
      background-image: url("../img/sprite.svg#m-login");
      height: 60px;
      width: 60px;
      top: 0; }

@media screen and (max-width: 991px) {
  .mg-modal.modal.header-content-modal .modal__overlay__inner {
    top: 100px; } }
