.select2-container--default {
    min-height: 60px;
    width: 100% !important;

    &.select2 {
        height: 100%;
    }

    &.select2-container {
        .select2-selection--multiple {
            .select2-selection__rendered {
                margin-bottom: 0;
                display: flex;
                flex-wrap: nowrap;
                padding-right: 5px;
                position: relative;

                &.select2-selection__load-more {
                    &::after {
                        content: '...';
                        display: inline-block;
                        color: #000;
                        padding-left: 5px;
                    }
                }

                .select2-selection__choice {
                    &:nth-child(n + 3) {
                        display: none;
                    }
                }
            }
        }
    }

    &.select2-container--open {
        .dropdown-wrapper {
            &::before {
                transform: rotate(-180deg);
            }
        }
    }

    .dropdown-wrapper {
        width: 13px;
        height: 7px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        &::before {
            content: '';
            background-image: url('../img/sprite.svg#drop-arrow');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    .select2-selection--single {
        .select2-selection__arrow {
            top: 50%;
            transform: translateY(-50%);
            right: 15px;

            b {
                border-color: initial;
                border-style: inherit;
            }
        }
    }

    &.select2-container--focus {
        .select2-selection--multiple {
            border: inherit;
        }
    }

    .select2-selection--multiple {
        padding: 0;
        border-radius: 0;
        border: 0;
        display: flex;
        align-items: center;

        .select2-selection__choice {
            margin-top: 0;
            display: flex;
            flex-wrap: nowrap;
        }

        .select2-selection__choice__display {
            color: #000000;
            font-size: 16px;
            font-weight: 300;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            margin: 0;
        }
    }

    .select2-selection {
        position: relative;
        height: 100%;
        padding-left: 14px;

        &.select2-selection--single {
            text-align: left;
            display: flex;
            height: 100%;
            align-content: center;
            align-items: center;
            background-color: #fff;
            color: #000;
            font-size: 16px;
            font-weight: 300;
            padding: 0 15px 0 25px;
            overflow: hidden;
            border-radius: 0;

            .select2-selection__rendered {
                padding: 0;
                white-space: normal;
            }

            .select2-selection__arrow {
                b {
                    width: 13px;
                    height: 7px;
                    position: absolute;
                    right: 8%;
                    top: 50%;
                    transform: translateY(-50%);

                    &::before {
                        content: '';
                        display: none;
                        background-image: url('../img/sprite.svg#drop-arrow');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100%;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .select2-dropdown {
        border: none;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
        border-radius: 0;

        .select2-search__field {
            border: 1px solid #424242;
        }

        .select2-results__option {
        }
    }

    .select2-results__option--selectable {
        padding-left: 20px;
    }

    .select2-results__option--selected {
        position: relative;
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 8px;
            top: 12px;
            width: 4px;
            height: 8px;
            border: solid #00419f;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}

.select2-container--dataTable {
    &.select2-container {
    }

    .select2-selection--single {
        .select2-selection__arrow {
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            position: absolute;

            b {
                border-color: initial;
                border-style: inherit;
            }
        }
    }

    &.select2-container--focus {
        .select2-selection--multiple {
            border: inherit;
        }
    }

    .select2-selection--multiple {
        padding: 0;
        border-radius: 0;
        border: 0;
        display: flex;
        align-items: center;

        .select2-selection__choice {
            margin-top: 0;
            display: flex;
            flex-wrap: nowrap;
        }

        .select2-selection__choice__display {
            color: #000000;
            font-size: 16px;
            font-weight: 300;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            margin: 0;
        }
    }

    .select2-selection {
        position: relative;
        height: 100%;
        padding-left: 14px;

        &.select2-selection--single {
            text-align: left;
            display: flex;
            height: 100%;
            align-content: center;
            align-items: center;
            background-color: #fff;
            color: #000;
            font-size: 16px;
            font-weight: 300;
            padding: 0 15px 0 10px;
            overflow: hidden;
            border-radius: 0;

            .select2-selection__rendered {
                padding: 0;
                white-space: normal;
            }

            .select2-selection__arrow {
                b {
                    width: 13px;
                    height: 7px;
                    position: absolute;
                    right: 8%;
                    top: 50%;
                    transform: translateY(-50%);

                    &::before {
                        content: '';
                        background-image: url('../img/sprite.svg#drop-arrow');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100%;
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }
        }
    }

    .select2-dropdown {
        border: none;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
        border-radius: 0;

        .select2-search__field {
            border: 1px solid #424242;
            padding: 4px 0;
        }

        .select2-results__option {
        }
    }

    .select2-results__option--selectable {
        padding-left: 20px;
    }

    .select2-results__option--selected {
        position: relative;
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 8px;
            top: 12px;
            width: 4px;
            height: 8px;
            border: solid #00419f;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }
}
