.page-post {
    position: relative;

    &.page-archive {
        .wrp-page-post__slider {
            height: 220px;
            transform: translateY(-80%);
            padding-bottom: 30px;
        }

        .page-post__header-bg {
            padding-bottom: 20%;
            &::before {
                background-image: linear-gradient(256deg, #0a0057 0%, #00419f 43%, #3d01a1 100%);
                opacity: 0.41;
                height: 0;
                padding-bottom: 30%;
                top: 0;
            }
        }

        .page-post__header-bg {
            .page-post__header-sub {
                &::before {
                    display: none;
                }
            }
        }
    }

    &.page-post_single {
        .page-post_border {
            padding-right: 30%;
        }

        .page-post__header {
            position: relative;
            padding-bottom: 0;

            h1 {
                letter-spacing: 0;
                color: #0a0057;
            }
        }

        .page-post__info-list {
            flex-wrap: nowrap;

            .page-post__info-item {
                display: inline-block;
                padding-left: 0;
                margin-right: 5px;

                &:first-child {
                    padding-left: 0;
                }

                &.page-post__info-item_author {
                    border-right: 1px solid #cbcbcb;
                    padding-right: 10px;
                }

                .page-post__info-title,
                a,
                p,
                span {
                    font-size: 14px;
                    line-height: 17px;
                    color: #4c4c4c;
                }

                a,
                span {
                    color: #4c4c4c;
                }
            }
        }

        .page-post__image-head {
            margin-bottom: 15px;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .share-list {
    }

    .page-post__slider {
        height: 365px;
        padding: 0;
        position: relative;
        background-color: #fff;
        //transform: translateY(-45%);
        //box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
        box-sizing: border-box;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            height: 8px;
            background-image: linear-gradient(270deg, #4087ec 0%, #0a0057 100%);
            left: 0;
            width: 100%;
        }

        .page-post__slider-inner {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
        }

        .page-post__slider-tools {
            margin-bottom: 30px;

            .news-tag {
                margin-right: 10px;
            }
        }

        .page-post__slider-navigate {
            margin-top: auto;

            .btn,
            .mg-btn {
                line-height: 2;
            }
        }

        .page-post__slider-img {
            height: 0;
            padding-bottom: 93%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            iframe {
                width: 100%;
            }
        }

        .page-post__slider-content {
            p {
                color: #4d4d4d;
                font-size: 16px;
                font-weight: 400;
                line-height: 1;

                &.title {
                    color: #00419f;
                    font-size: 40px;
                    font-weight: 500;
                }
            }
        }

        .page-post__slider-text {
            //max-height: 85px;
            min-height: 85px;
            overflow: hidden;
            margin-bottom: 10px;
        }
    }

    .page-post__slide {
        width: 100%;
        display: flex;
        padding: 30px;

        &.page-post__slide-video {
            .page-post__image {
                flex: 0 0 40%;
                max-width: 40%;
            }

            .page-post__content {
                flex: 0 0 60%;
                max-width: 60%;
            }

            .page-post__slider-img {
                padding-bottom: 55%;
                background-size: contain;
            }

            iframe {
                width: 100%;
            }
        }
    }

    .page-post__image {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .page-post__content {
        flex: 0 0 75%;
        max-width: 75%;
        padding-left: 35px;
        box-sizing: border-box;
        //min-height: 328px;
    }

    .page-post__slider-wrapper {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.11);
        background-color: #fff;
        //padding-bottom: 30px;
    }

    .page-post__slider-btn {
        position: absolute;
        right: 30px;
        bottom: 30px;
        z-index: 9;
    }

    .page-post__header-sub {
        .page-post__header {
            position: static;
            max-height: 150px;
            overflow: hidden;
        }
    }

    .page-post__header {
        position: absolute;
        bottom: 0;
        padding-bottom: 24px;

        h1 {
            color: #ffffff;
            font-size: 46px;
            font-weight: 700;
            margin-bottom: 15px;
            line-height: 1.4;
        }

        div,
        p {
            color: #ffffff;
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 0;
        }

        .page-post__tag-list {
            position: absolute;
            top: -45px;
        }

        .page-post__info-title {
            margin-right: 5px;
        }

        .page-post__info-list {
            display: flex;
            position: relative;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: calc(100% + 10px);
                height: calc(100% + 10px);
                left: -5px;
                top: -5px;
                background-color: #000000;
                //opacity: 0.4;
                z-index: 1;
            }

            .page-post__info-item {
                //display: flex;
                //flex-wrap: wrap;
                //margin-right: 10px;
                //align-items: center;
                margin-bottom: 0;
                margin-left: 10px;
                font-size: 16px;
                line-height: 1.2;
                white-space: nowrap;
                z-index: 2;

                span {
                    padding-left: 0;
                    line-height: 1.2;
                }

                strong {
                    font-weight: 700;
                }

                a {
                    color: #fff;
                }
            }

            .page-post__info-link {
                color: #fff;
                margin-left: 0;
                margin-bottom: 5px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .news-tag {
            //position: absolute;
            //top: -45px;
        }
    }

    .page-post__header-bg {
        padding-bottom: 20%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 0;
        min-height: 375px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            width: 100%;
            display: block;
            //background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            background-image: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 100%);
            opacity: 0.69;
            z-index: 0;
            position: absolute;
            height: 50%;
            bottom: 0;
            left: 0;
        }

        .page-post__header-sub {
            position: relative;
            padding: 11px 0;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #000000;
                opacity: 0.12;
            }
        }

        .breadcrumbs {
            .breadcrumbs__list {
            }

            .breadcrumbs__item {
                padding: 11px 0;

                &::after {
                    color: #fff;
                }
            }

            .breadcrumbs__link {
                color: #fff;
            }
        }
    }

    .main-content {
        .main-content__post {
            border-bottom: 1px solid #cfcfcf;
            word-wrap: anywhere;
            width: 100%;

            p,
            li,
            span,
            a {
                word-wrap: anywhere;
            }

            .share-list {
                position: absolute;
                display: flex;
                flex-direction: column;
                transform: rotate(90deg);
                transform-origin: left top;
                left: -15px;
                top: 40px;

                .sfsi_wDiv {
                    .sfsi_wicons {
                        transform: rotate(-90deg);
                        margin-left: 0;
                    }
                }
            }
        }

        .main-content__author {
            background-color: #f3f3f3;
            border: 1px solid #c6c6c6;

            .main-content__author-logo {
                border-radius: 50%;
                padding: 15px;
                flex-shrink: 0;

                img {
                    display: inline-block;
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                }
            }

            .main-content__author-desc {
                padding: 15px;

                p {
                    &.title {
                        font-weight: 500;
                        margin-bottom: 7px;
                    }
                }
            }
        }
    }

    .block-widget {
        &.block-widget-subscribe {
            &.block-widget-absolute {
                padding-top: 36px;

                .block-widget__title {
                    position: absolute;
                    width: 100%;
                    bottom: 100%;
                    z-index: 2;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.category-list {
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 15px 45px;

    &.category-list_video {
        background-color: #0a0057;
        padding: 0;

        .category-list__menu {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 0;
            padding-left: 0;
        }

        .category-list__item {
            flex-basis: initial;
            padding: 0;
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            width: auto;

            &:focus {
                outline: none;
            }

            &::before {
                display: none;
            }
        }

        .category-list__link {
            color: #fff;
            background-color: transparent;
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            line-height: normal;
            padding: 22px 5px;
            display: inline-block;
            border: none;
            width: 100%;
            transition: all 0.15s ease-in;
            outline: none;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    p {
        &.title {
            margin-bottom: 10px;
        }
    }

    .category-list__items {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .category-list__item {
        flex-basis: 20%;
        width: 100%;
        padding: 4px 20px 4px 15px;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            background-image: url('../img/sprite.svg#arrow-navigate');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 11px;
            width: 6px;
            top: 11px;
            left: 0;
        }

        &.active {
            background-color: #00419f;
        }
    }

    .category-list__link {
        color: $blueD;
        font-size: 18px;
        font-weight: 400;
    }
}
