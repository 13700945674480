.modal {
    &.modal-widget {
        .block-widget {
            margin: 0;

            .block-widget__body {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            &.block-widget-subscribe {
                .block-widget__title {
                    padding: 55px 20% 15px;

                    &::before {
                        border-radius: 50%;
                        background-color: #4087ec;
                        padding: 48px;
                        background-size: 45%;
                        top: -36%;
                    }
                }
            }
        }

        .modal__container {
            max-width: 700px;
            border-radius: 0;
            background-color: transparent;
            padding: 88px 0 0;
            overflow-x: hidden;

            .modal__content {
                margin: 0;
            }
        }

        .modal__footer {
            background-color: #f3f2f2;
            padding: 8px 15%;
        }

        .modal__close {
            position: absolute;
            right: 0;
            top: 55px;
        }
    }

    .modal__close {
        background: transparent;
        border: 0;
        outline: none !important;
        width: 30px;
        height: 30px;
        line-height: 1;
        text-align: center;
        padding: 0;
        transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;

        &:before {
            content: '×';
            font-size: 30px;
            line-height: 1;
            color: #fff;
        }

        &:hover {
            transform: scale(1.2);
        }
    }
}
/**************************\
  Basic Modal Styles
\**************************/
.mg-modal {
    &.modal {
        &.top-content-modal {
            .modal__overlay__inner {
                padding-top: 0;
                position: absolute;
                top: 0;

                .modal__content {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &.modal-max-width {
            .modal__overlay__inner {
                padding: 0;

                .modal__close {
                    color: #000;
                }
            }
            .modal__container {
                max-width: 100vw;
                width: 100%;
            }
        }

        &.header-content-modal {
            .modal__overlay__inner {
                padding-top: 0;
                position: absolute;
                top: 148px;

                .modal__content {
                    display: flex;
                    flex-direction: column;
                }

                .modal__container {
                    padding: 50px 50px;
                    max-width: 1400px;
                }
            }
        }

        &.modal-filter {
            .modal__overlay {
                background-color: #fff;
            }

            .modal__header {
                justify-content: flex-start;
                padding-right: 30px;
                background: #ffffff 0 0 no-repeat padding-box;
                box-shadow: 0 3px 6px #00000029;
                opacity: 1;

                .mg-btn-icon {
                    padding: 0;
                    border-radius: 0;
                    height: 45px;
                    width: 45px;

                    &::before {
                        content: '×';
                        display: block;
                        color: #fff;
                        font-weight: 300;
                        font-size: 46px;
                        background-color: $blueD2;
                        line-height: 0.8;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                    }

                    &:hover {
                        &::before {
                            background-color: lighten($blueD2, 10%);
                        }
                    }
                }

                p {
                    &.title {
                        text-align: left;
                        margin-left: 15px;
                    }
                }

                .clear-all {
                    margin-left: auto;
                }

                .modal__close {
                    display: block;
                }
            }

            .modal__container {
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                height: 100vh;
                height: calc(var(--vh, 1vh) * 100);
            }

            .modal__content {
                padding: 30px 55px 20px 55px;
                overflow-y: auto;
                height: 100%;

                .modal__content__inner {
                    padding-bottom: 20px;
                }

                .ms-spoiler-block {
                    width: 100%;
                    padding-top: 0;

                    .mCSB_container {
                    }
                }

                form {
                    .form-group {
                        margin-bottom: 10px;

                        .title {
                            cursor: pointer;
                        }

                        .form-group__tools {
                            border-bottom: 1px solid #afafaf;

                            .clear-all {
                                display: none;
                            }
                        }
                    }
                }
            }

            .mCSB_scrollTools {
                width: 16px;
            }

            .modal__footer {
                margin-bottom: 0;
                position: relative;
                width: 100%;
                background: #ffffff 0 0 no-repeat padding-box;
                box-shadow: 0 -3px 6px #00000029;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: auto;
                height: 100px;
                padding-bottom: 10px;
                padding-top: 10px;

                .btn-group {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                }

                .mg-btn {
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 300;

                    &.btn-darkness {
                        padding: 1rem 1.8rem;
                    }

                    &.btn-danger {
                        padding: 1rem 1.8rem;
                    }
                }
            }

            .modal__overlay__inner {
                .modal__close {
                    display: none;
                }
            }
        }
    }

    .modal__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;

        &.bg-dark {
            background-color: rgba(0, 0, 0, 0.75);

            .modal__content {
                .modal__close {
                    &:before {
                        color: #000;
                    }
                }
            }

            .modal__close {
                &:before {
                    color: #fff;
                }
            }
        }
    }

    .modal__overlay__inner {
        max-height: 100%;
        overflow-y: auto;
        width: 100%;
        padding: 85px 15px;
        margin-bottom: 85px;

        .modal__close {
            position: absolute;
            right: 2px;
            top: 0;
            font-size: 24px;
            z-index: 1000;
            transition: opacity ease-in-out 0.3s;
            background-color: initial;
            color: #000;

            &:hover {
                opacity: 0.7;
                text-decoration: none;
            }
        }
    }

    .modal__container {
        background-color: #fff;
        padding: 32px 104px;
        max-width: 640px;
        margin: 0 auto;
        border-radius: 0;
        box-sizing: border-box;

        width: auto;
        height: auto;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
    }

    .modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .modal__close {
            position: absolute;
            top: -25px;
            right: -25px;
        }

        .modal__header__inner {
            display: flex;
            flex-wrap: wrap;

            > p {
                width: 100%;
                display: block;
            }
        }
    }

    .modal__footer {
        height: auto;
        margin-bottom: 50px;

        .modal_links {
            margin-top: 50px;
        }

        .modal_links__item {
            flex-basis: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            text-decoration: none;
            margin-bottom: 20px;

            &:hover {
                text-decoration: underline;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .modal__title {
        margin-top: 0;
        box-sizing: border-box;
        margin-bottom: 11px;
    }

    .modal__small-label {
        @include font-size-block(14px, 1.55, 400);
    }

    .modal__content {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.8);

        .errors-group {
            margin-bottom: 10px;
            text-align: center;

            p {
                margin: 0;
            }
        }

        .modal__content__item {
            margin: 0 0 7px 0;
            font-size: 18px;
            font-weight: 700;
            strong {
                display: inline-block;
                min-width: 35%;
            }
            span {
                display: inline-block;
                font-weight: 400;
            }
        }

        .modal__close {
            &:before {
                color: #000;
            }
        }
    }

    .modal__btn {
        font-size: 0.875rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #e6e6e6;
        color: rgba(0, 0, 0, 0.8);
        border-radius: 0.25rem;
        border-style: none;
        border-width: 0;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: none;
        overflow: visible;
        line-height: 1.15;
        margin: 0;
        will-change: transform;
        -moz-osx-font-smoothing: grayscale;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        transition: -webkit-transform 0.25s ease-out;
        transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;

        &:focus,
        &:hover {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
        }
    }

    .modal__btn-primary {
        background-color: #00449e;
        color: #fff;
    }

    &.micromodal-slide {
        display: none;

        &.is-open {
            display: block;
        }

        &[aria-hidden='false'] {
            .modal__overlay {
                animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
            }

            .modal__container {
                animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
            }
        }

        &[aria-hidden='true'] {
            .modal__overlay {
                animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
            }

            .modal__container {
                animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
            }
        }

        .modal__container,
        .modal__overlay {
            will-change: transform;
            z-index: 999;
        }
    }
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(-15%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes mmslideInLeft {
    from {
        transform: translateX(-15%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(10%);
    }
}

@keyframes mmslideOutLeft {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(10%);
    }
}

.mg-modal {
    &.modal,
    &.md-modal {
        .modal__header {
            height: auto;

            .modal__title {
                width: 100%;
                text-align: center;
                color: #000000;
                font-size: 32px;
                margin-bottom: 0;
                font-weight: 400;
                text-transform: uppercase;
                position: relative;
                padding-top: 64px;

                &.modal__title_without-logo {
                    padding-top: 0;

                    &::before {
                        display: none;
                    }
                }

                &::before {
                    content: '';
                    background-image: url('../img/sprite.svg#m-login');
                    height: 60px;
                    width: 60px;
                    top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .mg-modal {
        &.modal {
            &.header-content-modal {
                .modal__overlay__inner {
                    top: 100px;
                }
            }
        }
    }
}
