form {
    width: 100%;

    &.form-type2 {
        .form-group {
            margin-bottom: 19px;

            .form-group__inner {
                min-height: initial;

                .form-control {
                    border-bottom: 1px solid #d5d5d5;
                    color: #000000;
                    font-size: 16px;
                    background-color: transparent;
                    font-weight: 300;
                    padding: 6px 0;

                    &.wpcf7-checkbox {
                        border-bottom: none;
                    }
                }

                .select2-container--default {
                    .select2-selection {
                        &.select2-selection--single {
                            border: none;
                            border-bottom: 1px solid #d5d5d5;
                            padding: 6px 15px 6px 0;

                            .select2-selection__arrow {
                                b {
                                    right: 2%;
                                    width: 18px;
                                    height: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .select2-container--default {
            .select2-selection {
                &.select2-selection--single {
                    background-color: transparent;
                }
            }
        }
    }

    &.form-compact {
        .form-group {
            margin-bottom: 39px;

            .form-group__inner {
                .form-control {
                    min-height: 38px;
                }
            }
        }

        .select2-container--default {
            min-height: 38px;
        }
    }

    &.form-type-filter {
        .form-group {
            margin-bottom: 30px;

            .form-group__checkboxes {
                max-height: 179px;
                display: block;
                overflow: hidden;
                width: 100%;
            }

            .form-group__inner {
                margin: 0;

                &.form-group__search {
                    position: relative;
                    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
                    margin-bottom: 12px;

                    .btn-icon {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                &.form-group__checkbox {
                    min-height: 45px;
                    border-bottom: 1px solid #dbdbdb;
                    position: relative;
                    align-items: center;
                    align-content: center;

                    .form-control__count {
                        position: absolute;
                        right: 0;
                        border-radius: 25px;
                        background-color: #ffffff;
                        z-index: 0;
                        display: table-cell;
                        padding: 5px 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        min-width: 64px;
                        text-align: center;
                    }

                    [type='checkbox'] {
                        &:checked {
                            + label {
                                margin-bottom: 0;
                                &::before {
                                    background-color: $purpleTheme;
                                    border-color: #dbdbdb;
                                    border-width: 2px;
                                }

                                &::after {
                                    left: 8px;
                                    top: 9px;
                                }
                            }
                        }

                        + label {
                            margin-bottom: 0;
                            padding-left: 30px;
                            color: #000000;
                            font-size: 18px;
                            font-weight: 400;

                            &::before {
                                border-radius: 3px;
                                border: 1px solid #cbcbcb;
                                width: 20px;
                                height: 20px;
                                top: 4px;
                                background-color: #fff;
                            }
                        }
                    }
                }

                .form-control {
                }
            }
        }
    }

    .form-group {
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 20px;
        box-shadow: none;
        outline: none;

        &:last-child {
            margin-bottom: 0;
        }

        &.form-group__inline {
            display: flex;
            flex-wrap: nowrap;

            &:last-child {
                margin-bottom: 0;
            }

            .form-group__inner {
                //flex-basis: 100%;
            }

            .form-group {
                .form-group__inner {
                    margin: 0 5% 0 0;
                }
            }

            > .btn,
            .mg-btn {
                margin-left: 5px;
            }
        }

        .form-group__tools {
            width: 100%;
            margin-bottom: 5px;
        }

        .title {
            color: #0a0057;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0;
        }

        .form-group__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            flex-shrink: 1;
            flex-grow: 1;
            //flex-basis: 0;
            flex-basis: 25%;
            margin: 0 25px;

            > .form-group {
                margin-bottom: 5px;
            }

            .wpcf7-form-control-wrap {
                //margin-bottom: 0.5rem;
                margin-bottom: 0;
            }

            .form-control {
                min-height: 60px;
            }

            label {
                margin-bottom: 0;
            }

            span {
                &.wpcf7-list-item {
                    margin-left: 0;
                }
            }

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            &.form-group__btn {
                flex-shrink: 0;
                flex-basis: 0;
                //max-width: 130px;

                .btn,
                .mg-btn {
                    max-height: 60px;
                    height: 100%;
                }
            }

            &.form-group__inner-search {
                border: 1px solid $grey;
                position: relative;

                .btn-search {
                    position: absolute;
                    right: 1px;
                    top: 50%;
                    transform: translateY(-50%);
                    line-height: 1;
                    background-color: transparent;
                }
            }
        }

        .form-group__label {
            width: 100%;
            color: $blue;
            opacity: 0.5;
            margin-bottom: 0.5rem;
            @include font-size-block(18px, inherit, 400);

            &.text-smaller {
                @include font-size-block(14px, inherit, 400);
            }
        }

        &.datapicker-elem {
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                //background-image: url('../img/datapick.svg');
                background-position: center;
                background-repeat: no-repeat;
                width: 23px;
                height: 25px;
                right: 22px;
                bottom: 17px;
                pointer-events: none;
            }
        }

        .text-error {
            display: block;
            color: red;
            font-size: 13px;
            margin-top: 5px;
        }

        .form-inner-group {
            position: relative;
            width: 100%;
        }

        .tooltip {
            right: -35px;
        }

        label {
        }

        .form-control {
            position: relative;
            width: 100%;
            padding: 0 20px;
            height: 100%;
            border: none;
            background-color: #fff;
            outline: none;
            box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
            box-sizing: border-box;
        }

        .form-control-text {
            margin-bottom: 0;
            display: flex;
            align-content: flex-end;
            align-items: flex-end;
            padding-bottom: 10px;
            font-size: 18px;
        }

        textarea {
            &.form-control {
                height: auto;
            }
        }

        input {
            &[type='submit'] {
                width: 100%;
            }
        }

        .form-group__inner {
            &.form-group__checkbox {
                align-items: flex-start;
                align-content: flex-start;
                min-height: 30px;

                .wpcf7-form-control {
                    &.wpcf7-checkbox {
                        cursor: pointer;
                        background-color: transparent;
                        color: #dedede;
                        font-size: 12px;
                        font-weight: 300;
                        font-style: normal;
                        letter-spacing: normal;
                        line-height: normal;
                        text-align: left;
                        padding-left: 0;
                    }
                }

                [type='checkbox'] {
                    display: none;

                    + label,
                    + span {
                        padding-left: 23px;
                        position: relative;
                        color: #9a9a9a;
                        font-size: 12px;
                        font-weight: 300;
                        cursor: pointer;
                        display: inline-block;
                        margin-bottom: 0;
                        line-height: 1.5;

                        &.text-smaller {
                            &:before {
                                top: -3px;
                            }

                            &:after {
                            }
                        }

                        &::before {
                            content: '';
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            vertical-align: middle;
                            height: 15px;
                            width: 15px;
                            border: 2px solid #bfbfbf;
                            box-sizing: border-box;
                            margin-right: 10px;
                            -webkit-transition: all 0.2s;
                            transition: all 0.2s;
                            opacity: 1;
                        }
                    }

                    &:checked {
                        + label,
                        + span {
                            &.text-smaller {
                                &:after {
                                    content: '';
                                    left: 8px;
                                    top: 6px;
                                    width: 4px;
                                    height: 6px;
                                }
                            }

                            &::before {
                                opacity: 1;
                                background-color: $blue;
                                border: solid $blue;
                            }

                            &:after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                left: 6px;
                                top: 3px;
                                width: 4px;
                                height: 8px;
                                border: solid #fff;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }

                .wpcf7-list-item-label {
                    position: relative;
                    margin-bottom: 0;

                    &::before {
                        content: '';
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        height: 15px;
                        width: 15px;
                        border: 2px solid #bfbfbf;
                        box-sizing: border-box;
                        margin-right: 10px;
                        -webkit-transition: all 0.2s;
                        transition: all 0.2s;
                        opacity: 1;
                        top: -2px;
                    }
                }
            }

            &.form-group__radio {
                [type='radio'] {
                    display: none;

                    &:checked {
                        + label,
                        + span {
                            &::before {
                                opacity: 1;
                            }

                            &:after {
                                content: '';
                                display: inline-block;
                                position: absolute;
                                left: 8px;
                                top: 8px;
                                width: 4px;
                                height: 8px;
                                border: solid #000;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }
                        }
                    }
                }

                .wpcf7-list-item-label {
                    position: relative;

                    &::before {
                        content: '';
                        display: inline-block;
                        position: relative;
                        vertical-align: middle;
                        height: 15px;
                        width: 15px;
                        border: 2px solid $blue;
                        box-sizing: border-box;
                        margin-right: 10px;
                        -webkit-transition: all 0.2s;
                        transition: all 0.2s;
                        border-radius: 50%;
                        opacity: 1;
                        top: -2px;
                    }
                }
            }

            &.form-group__file {
                [type='file'] {
                    display: none;

                    + label {
                        cursor: pointer;
                        width: auto;
                        padding: 0 20px;
                        border-radius: 30px 30px;
                        background-color: $blue;
                        opacity: 1;
                        color: #fff;
                    }
                }
            }

            .wpcf7-form-control-wrap {
                width: 100% !important;
            }
        }
    }

    .form-group-select {
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 20px;
        box-shadow: none;
        outline: none;
    }
}

.clear-all {
    color: #d20b0b;
    font-size: 14px;
    font-weight: 300;
    position: relative;
    padding-left: 25px;
    line-height: 1;
    display: block;

    &::before {
        content: '';
        display: block;
        width: 21px;
        height: 22px;
        background-image: url('../img/sprite.svg#clear');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        left: 0;
        top: -4px;
    }
}

.form-file {
    position: relative;
    display: block;
    flex-wrap: wrap;

    .form-control_file,
    .form-file__label {
        width: 100%;
        display: block;
    }
}

.wrp-form {
    padding: 15px;
}

.footnote-lg-right {
    position: absolute;
    right: -50%;
}

form[role='login'] {
    .form-group {
    }
}

form[role='search'] {
    .form-group {
        height: 60px;

        input {
            &[type='text'] {
                padding-left: 65px;
                color: $blue;
                border: 1px solid $blue;
                background-color: transparent;

                &::after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    background-image: url('../img/search.svg');
                    left: 0;
                    margin: auto 0;
                    top: 0;
                    bottom: 0;
                    padding-right: 20px;
                    padding-left: 20px;
                    width: 25px;
                    height: 25px;
                }
            }

            &[type='submit'] {
                height: 100%;
                width: 100%;
            }
        }
    }

    .search-input {
        position: relative;

        .icon {
            position: absolute;
            left: 20px;
            width: 30px;
            height: 30px;

            &:before {
                left: 0;
            }
        }
    }
}

.modal__content {
    form {
        width: 100%;

        &.type-2 {
            .form-group {
                padding: 0;
                overflow: hidden;
            }
        }
    }
}

.wpcf7 {
    .wpcf7-form {
        .wpcf7-response-output {
            margin: 0;
        }
    }
}

.bg-gradient,
.block-widget-single-form {
    .wpcf7-form {
        .wpcf7-response-output {
            color: #fff;
        }
    }
}
