.news-list {
    .news-list__item {
    }
}

.news-item {
    position: relative;
    width: 100%;

    .btn,
    .mg-btn {
        padding: 0.375rem 2rem;
    }

    .news-item__img {
        height: 0;
        width: 100%;
        padding-bottom: 35%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .news-item__title {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;

        p {
            color: #fff;
            font-size: 24px;
            font-weight: 700;
        }

        .title {
            display: block;
            margin-bottom: 15px;
            position: absolute;
            text-align: center;
        }
    }

    .news-item__content {
        text-align: center;
        padding: 1% 9% 4.5%;
        background-color: #fff;

        > div {
            min-height: 75px;
        }

        p {
            color: #959595;
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 15px;
            line-height: 1.33;
        }
    }
}

.sign-up-block {
    .news-item {
        .news-item__img {
            position: absolute;
        }
    }
}

.ms-spoiler-block {
    .news-widget {
        &.news-widget__type2 {
            .news-widget__content {
                .title {
                    flex-basis: 100%;
                }

                .date {
                    flex-basis: 100%;
                    text-align: left;
                }
            }
        }
    }
}

.news-widget {
    max-height: 570px;

    &.news-widget__type2 {
        max-height: initial;

        .news-widget__list {
            max-height: initial;
        }

        .news-widget__item {
            background-color: inherit;
            border-bottom: 1px solid #cfcfcf;
            margin-bottom: 29px;
            position: relative;

            &.active {
                //background-color: inherit;
                //box-shadow: none;
            }
        }

        .news-widget__img {
            width: 182px;
            height: 179px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        .news-widget__tag {
            position: absolute;
            top: 10px;
            background-color: $blueL;
            padding: 7px 15px;
            text-transform: uppercase;
            color: #ffffff;
            font-size: 10px;
            font-weight: 400;
        }

        .news-widget__content {
            position: relative;
            padding-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            cursor: default;

            .title {
                display: block;
                height: 45px;
                overflow: hidden;
                flex-basis: 85%;
                color: $blue;
                font-size: 20px;
                font-weight: 500;
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                text-decoration: none;
                cursor: pointer;
            }

            .date {
                position: static;
                font-weight: 400;
                margin-bottom: 7px;
                flex-basis: 10%;
                text-align: right;
                padding-top: 7px;
            }

            .desc {
                max-height: 65px;
                overflow: hidden;
                flex-basis: 100%;
                flex-grow: 1;

                p {
                    line-height: 1;
                }
            }
        }

        .news-widget__preview {
            position: relative;
            max-height: initial;
            height: 100%;
            width: 100%;
            padding-bottom: 88%;
            background-size: cover;
            background-repeat: no-repeat;

            .news-widget__tag {
                right: 0;
                position: absolute;
                top: 20px;
                color: #ffffff;
                font-size: 10px;
                font-weight: 400;
            }

            .news-widget__preview-content {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                padding: 30px;
                background-color: $purpleTheme;
                opacity: 0.78;
                box-sizing: border-box;

                p {
                    text-align: left;
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1;
                }
                .title {
                    text-align: left;
                    display: block;
                    color: #ffffff;
                    font-size: 30px;
                    font-weight: 500;
                    margin-bottom: 30px;
                }
            }
        }

        .news-widget__desc {
            background-color: $purpleTheme;

            p {
                color: #00419f;
                font-size: 20px;
                font-weight: 500;
            }

            .date {
            }
        }
    }

    &.news-widget__video {
        max-height: initial;

        .grid-group {
            //padding-bottom: 35px;
            width: 100%;
        }

        .news-widget__category {
            .title {
                line-height: 1.4;
            }
        }

        .news-widget__list {
            display: flex;
            flex-wrap: wrap;
            max-height: initial;
            justify-content: flex-start;
        }

        .news-widget__item {
            max-width: 31%;
            flex-wrap: wrap;
            margin-bottom: 35px;
            margin-right: 32px;
            background-color: transparent;
            align-content: flex-start;
            flex-direction: column;

            &:nth-child(3n-3) {
                margin-right: 0;
            }

            &:last-child {
                //margin-bottom: 0;
            }
        }

        .news-widget__content {
            padding: 0;
            align-items: flex-start;
            align-content: flex-start;

            .title {
                padding: 12px 5px;
                display: inline-block;
                margin-bottom: 0;
                color: #000000;
                font-size: 16px;
                font-weight: 300;
                font-style: normal;
                letter-spacing: normal;
                line-height: normal;
                cursor: default;
            }
        }

        .news-widget__img {
            width: 100%;
            height: auto;
            background-size: cover;
            position: relative;
            padding-bottom: 0;

            p {
                height: 100%;
            }

            iframe {
                width: 100%;
                height: auto;
                min-height: 165px;
            }

            &:before {
                //content: '';
                display: none;
            }

            a {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
    }

    .news-widget__list {
        max-height: 570px;
        width: 100%;

        .mCSB_container {
            padding: 0;
        }
    }

    .news-widget__item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        margin-bottom: 12px;
        background-color: #f2f2f2;

        &:hover {
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.16);
            cursor: pointer;
        }

        &:last-child {
            //margin-bottom: 0;
        }

        &.active {
            background-color: #fff;
            box-shadow: 0 3px 24px rgba(0, 0, 0, 0.16);

            .title {
                color: #d20b0b;
            }
        }
    }

    .news-widget__img {
        width: 193px;
        height: 133px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .news-widget__content {
        padding: 16px 30px 22px 30px;
        position: relative;
        line-height: 1;
        justify-content: space-between;

        .date {
            position: absolute;
            right: 22px;
            bottom: 20px;

            color: #4d4d4d;
            font-size: 12px;
            font-weight: 400;
        }

        a {
            text-decoration: underline;
        }
    }

    .news-widget__preview {
        max-height: 425px;
        flex-grow: 1;
        flex-shrink: 0;
        text-align: center;

        img {
            object-fit: cover;
            width: 100%;
            height: auto;
        }

        iframe {
            width: 100%;
            height: 425px;
        }
    }

    .news-widget__desc {
        background-color: #0a0057;
        width: 100%;
        padding: 30px 30px 22px 30px;
        flex-shrink: 1;
        flex-grow: 0;
        position: relative;
        display: flex;
        flex-basis: 100%;
        min-height: 145px;

        p {
            color: #ffffff;
            font-size: 30px;
            font-weight: 500;
        }

        .date {
            position: absolute;
            right: 20px;
            bottom: 15px;

            color: #ffffff;
            font-size: 12px;
            font-weight: 400;
        }
    }
}

.widget-block {
    .news-widget {
        &.news-widget__type2 {
            .news-widget__content {
                padding-bottom: 0;
            }
        }

        .news-widget__content {
            padding: 20px 30px 22px 30px;
            width: 100%;

            .title {
                color: #00419f;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 0;
                line-height: 1;
            }
        }
    }
}
